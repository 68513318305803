import { useContext, createContext } from "react";

export type RangeComplement = {
  complement: Date | null;
};

export const RangeComplementContext = createContext<RangeComplement>({
  complement: null
});

export function useRangeComplement(): RangeComplement {
  return useContext(RangeComplementContext);
}
