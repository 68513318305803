import { useState } from "react";
import styles from "./QuestionTimeline.module.scss";
import { g, gt } from "language";
import { useNiceDate, useNiceDateFromString } from "timeUtils";
import FormSection from "FormSection";
import SingleSelect from "commonComponents/SingleSelect";
import { QuestionVersion, QuestionEditContext } from "questionContext";
import { loadAllQuestionVersions, success } from "network";
import { useUser } from "user";
import { Question } from "questionTypes";

type TimelineProps = {
  currentVersion: number;
  allVersions: QuestionVersion[];
  onSelectVersion: (_: number) => void;
  label?: string;
};

export default function Timeline(props: TimelineProps) {
  const latestVersion = props.allVersions.reduce((v, acc) => {
    if (v.version > acc.version) {
      return v;
    } else {
      return acc;
    }
  }, props.allVersions[0]);
  const entries = props.allVersions.sort((a, b) => b.version - a.version).map(version => {
    return {
      id: version.version,
      element: <VersionRow version={version}/>
    };
  });
  const header = { elementId: props.currentVersion };
  return (
    <FormSection title={g('versionTimelineLabel')} additionalClasses={styles['timeline-box']}>
      <div className={styles['timeline']}>
        <div className={styles['latest-version-label-wrapper']}>
          <LatestVersionLabel version={latestVersion.version} writtenDate={latestVersion.writtenDate}/>
        </div>
        {props.allVersions.length > 1 ? (
          <SingleSelect
            onSelect={(version: number) => props.onSelectVersion(version)}
            entries={entries}
            header={header}
            label={props.label}
          />
        ) : null}
      </div>
    </FormSection>
  )
}

type LatestVersionLabelProps = {
  version: number;
  writtenDate: string;
  additionalClasses?: string;
}

export function LatestVersionLabel(props: LatestVersionLabelProps) {
  const editLabel = props.version <= 1 ? g("createdLabel") : g("editedLabel");
  const classes = `${styles['timeline-latest']} ${props.additionalClasses || ""}`
  return (
    <div className={classes}>
      <div className={styles['version-label']}>{g("latestVersionLabel")} {props.version}</div>
      <div>{editLabel} {useNiceDateFromString(props.writtenDate)}</div>
    </div>
  );
}

type VersionRowProps = {
  version: QuestionVersion;
}

function VersionRow(props: VersionRowProps) {
  return (
    <div className={styles['version-row']}>
      <span>{gt("questionVersion", [props.version.version.toString()])}</span>
      <span>{useNiceDateFromString(props.version.writtenDate)}</span>
    </div>
  );
}

// type StateAlteringQuestionTimelineProps = {
//   questionContext: QuestionEditContext;
//   setQuestionContext: (_: QuestionEditContext) => void;
//   baseVersion: number;
//   setBaseVersion: (_: number) => void;
//   label?: string;
//   shareVersionMap?: (_: Map<number, Question>) => void;
//   forceLoad?: boolean;
// }
//
// export function StateAlteringQuestionTimeline(props: StateAlteringQuestionTimelineProps) {
//
//   return (
//     <Timeline
//       currentVersion={props.baseVersion}
//       allVersions={props.questionContext.versions}
//       onSelectVersion={resetVersion}
//       label={props.label}
//     />
//   );
// }
