import DateSelector, { DateStateProps } from "../DateSelector";
import styles from "./DateRangeSelector.module.scss";
import { useUser } from "user";
import { RangeComplementContext } from "./rangeContext";

export type DateRangeSelectorProps = {
  startDateProps: DateStateProps;
  endDateProps: DateStateProps;
  minDate?: Date;
  maxDate?: Date;
};

export default function DateRangeSelector(props: DateRangeSelectorProps) {
  const minDate = props.minDate || useUser().user.accountCreationDate();
  const currentDate = new Date();
  const maxDate = props.maxDate || new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const { date: startDate } = props.startDateProps;
  const { date: endDate } = props.endDateProps;
  const invalidState = startDate !== null && endDate !== null && startDate.getTime() > endDate.getTime();
  const lowComplementValue = invalidState ? null : endDate || maxDate;
  const highComplementValue = invalidState ? null : startDate || minDate;
  return (
    <div className={styles['date-range-selector']}>
      <RangeComplementContext.Provider value={{ complement: lowComplementValue }}>
        <DateSelector
          defaultDescriptorKey={'accountCreationDateLabel'}
          labelKey={'startDateLabel'}
          date={props.startDateProps.date}
          setDate={props.startDateProps.setDate}
          minDate={minDate}
          maxDate={maxDate}
          fallbackDate={minDate}
          errorSignifier={invalidState ? 'largerThanEndDate' : undefined}
          additionalClasses={styles['first-date-selector']}
        />
      </RangeComplementContext.Provider>
      <RangeComplementContext.Provider value={{ complement: highComplementValue }}>
        <DateSelector
          defaultDescriptorKey={'today'}
          labelKey={'endDateLabel'}
          date={props.endDateProps.date}
          setDate={props.endDateProps.setDate}
          minDate={minDate}
          maxDate={maxDate}
          fallbackDate={maxDate}
          errorSignifier={invalidState ? 'smallerThanStartDate' : undefined}
        />
      </RangeComplementContext.Provider>
    </div>
  )
}
