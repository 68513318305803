import { CompletedQuiz, QuizSpecific, fullyCorrect as fc, fullyIncorrect as fi } from "QuizResultsPage";
import { Question } from "questionTypes";

export type QuestionContext = {
  questionContext: QuestionEditContext;
  statistics: QuestionStatistics;
};

export type QuestionEditContext = {
  question: Question;
  versions: Question[];
}

export type QuestionVersion = {
  version: number;
  writtenDate: string;
};

export type QuestionStatistics = {
  appearances: QuizAppearance[];
  aggregate: AggregateQuizQuestionStatistics | null;
};

export type QuizAppearance = {
  quiz: CompletedQuiz;
  questionResult: QuizSpecific;
};

export function getByVersion(context: QuestionEditContext, version: number): Question | null {
  return context.versions.find(v => v.version === version) || null;
}

export type AggregateQuizQuestionStatistics = {
  firstAppearance: string;
  lastAppearance: string;
  totalAppearances: number;
  totalPoints: number;
  fullyCorrect: number;
  fullyIncorrect: number;
};

export function calculateAggregateQuizQuestionStatistics(
  appearances: QuizAppearance[]
): AggregateQuizQuestionStatistics {
  const epsilon = 0.00001;
  const firstAppearance = appearances.reduce((acc, current) => {
    const currentDate = new Date(current.quiz.startTime);
    return currentDate < acc ? currentDate : acc;
  }, new Date()).toString();
  const lastAppearance = appearances.reduce((acc, current) => {
    const currentDate = new Date(current.quiz.startTime);
    return currentDate > acc ? currentDate : acc;
  }, new Date("2020-01-01")).toString();
  const totalPoints = appearances.reduce((acc, current) => {
    const score = current.questionResult.score;
    return acc + score.points / score.pointsPossible;
  }, 0.0);
  const fullyCorrect = appearances.filter(current => fc(current.questionResult.score)).length;
  const fullyIncorrect = appearances.filter(current => fi(current.questionResult.score)).length;
  const totalAppearances = appearances.length;
  return {
    firstAppearance, lastAppearance, totalAppearances, totalPoints, fullyCorrect, fullyIncorrect
  }
}

export function averagePoints(stats: AggregateQuizQuestionStatistics): number {
  return stats.totalPoints / stats.totalAppearances;
}

export function partiallyCorrect(stats: AggregateQuizQuestionStatistics): number {
  return stats.totalAppearances - stats.fullyCorrect - stats.fullyIncorrect;
}
