import React from 'react';
import {
  useGlossary as g,
  useGlossaryTemplate as gt,
  useGlossaryDynamic as gd
} from "../language";
import { QuestionType } from "../questionTypes";
import { applicableSettingsFields } from "./settingsOperations";
import { Setting } from "./questionSettingsModel";

type OverallInfoProps = {
  questionType: QuestionType;
};

export function OverallInfo(props: OverallInfoProps) {
  const applicable = applicableSettingsFields(props.questionType);
  return (
    <>
      <p>{gt("questionSettingsHeader", [g(props.questionType)])}</p>
      {Array.from(applicable.keys()).map(key => (
        <SettingsParagraph setting={key} key={key}/>
      ))}
    </>
  )
}

type SettingsParagraphProps = {
  setting: Setting;
};

function SettingsParagraph(props: SettingsParagraphProps) {
  const headerKey = `${props.setting}Header`;
  const explanationKey = `${props.setting}Explanation`;
  return (
    <p><b>{gd(headerKey)}</b>{gd(explanationKey)}</p>
  );
}
