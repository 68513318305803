import React, { useState, useEffect, useRef } from 'react';
import * as T from "tags";
import styles from "./NewQuizPage.module.scss";
import { retrieveLatestQuizSettings, newQuizRequest, constructSocket, send, quizSettings, success } from "network";
import { useUser } from "user";
import { QuizSettings, QuizFeasibility, QuizIdentifier } from "quiz";
import { Link, useNavigate } from "react-router-dom";
import { g, gt } from "language";
import Toggle from "commonComponents/Toggle";
import useFocus from "focus";
import { useQuizContext } from "quizContext";
import FormSection from "FormSection";
import { LogicalPattern } from "commonComponents/LogicalPatternSelector";

const DEFAULT_QUESTIONS = 10;
type NumberInput = number | "";

export default function NewQuizPage() {
  const [questionCount, setQuestionCount] = useState<NumberInput>(DEFAULT_QUESTIONS);
  const [totalQuestions, setTotalQuestions] = useState<number | null>(null);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [excludedTagIds, setExcludedTagIds] = useState<string[]>([]);
  const [pattern, setPattern] = useState<LogicalPattern>("any");
  const [allowRepeats, setAllowRepeats] = useState(false);
  // const [socket] = useState(constructSocket((event: MessageEvent) => {
  //   const newFeasibility: QuizFeasibility = JSON.parse(event.data);
  //   setFeasibility(newFeasibility);
  //   return false;
  // }));
  const [feasibility, setFeasibility] = useState<QuizFeasibility>({
    matchingQuestions: 0,
    feasible: true,
    repeatsWillFix: false
  });
  const userContext = useUser();
  // const socketMessage = () => {
  //   if (userContext.user && questionCount !== "") {
  //     send(socket, 'new-quiz-settings', userContext.user.userId(), );
  //   }
  // }
  useEffect(() => {
    if (userContext.user && questionCount !== "") {
      (async () => {
        const newFeasibility = await quizSettings({
          questionNumber: questionCount,
          tagIds: selectedTagIds,
          tagPattern: pattern,
          allowRepeats: allowRepeats,
          excludedTagIds: excludedTagIds
        })
        if (success(newFeasibility)) {
          setFeasibility(newFeasibility);
        }
      })();
    }
  }, [questionCount, selectedTagIds.length, pattern, allowRepeats]);
  useEffect(() => {
    (async () => {
      if (userContext.user) {
        const quizContext = await retrieveLatestQuizSettings();
        setTotalQuestions(quizContext.totalQuestions);
        const settings = quizContext.settings;
        if (settings !== null) {
          setQuestionCount(settings.questionNumber);
          setSelectedTagIds(settings.tagIds);
          setPattern(settings.tagPattern);
          setExcludedTagIds(settings.excludedTagIds);
        } else {
          if (quizContext.totalQuestions < DEFAULT_QUESTIONS) {
            setQuestionCount(quizContext.totalQuestions);
          }
        }
      }
    })();
  }, [userContext.user.userId()]);
  if (totalQuestions === 0) {
    return <NoQuestionsMessage/>;
  }
  return (
    <div className={styles['new-quiz-page']}>
      <h1>{g('newQuizHeader')}</h1>
      <NewQuizForm
        questionCount={questionCount}
        setQuestionCount={setQuestionCount}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        excludedTagIds={excludedTagIds}
        setExcludedTagIds={setExcludedTagIds}
        pattern={pattern}
        setPattern={setPattern}
        totalQuestions={totalQuestions || DEFAULT_QUESTIONS}
        allowRepeats={allowRepeats}
        setAllowRepeats={setAllowRepeats}
        feasibility={feasibility}
      />
      <StartQuizButton
        questionNumber={questionCount}
        tagIds={selectedTagIds}
        excludedTagIds={excludedTagIds}
        tagPattern={pattern}
        allowRepeats={allowRepeats}
        userId={userContext.user.userId()}
        feasible={feasibility.feasible}
      />
    </div>
  );
}

type StartQuizButtonProps = {
  userId: string;
  questionNumber: NumberInput;
  tagIds: string[];
  excludedTagIds: string[]
  allowRepeats: boolean;
  tagPattern: LogicalPattern;
  feasible: boolean;
};

function StartQuizButton(props: StartQuizButtonProps) {
  const navigate = useNavigate();
  const quiz = useQuizContext();
  const submit = async () => {
    if (props.questionNumber) {
      const payload = {
        questionNumber: props.questionNumber,
        tagIds: props.tagIds,
        tagPattern: props.tagPattern,
        allowRepeats: props.allowRepeats,
        excludedTagIds: props.excludedTagIds
      };
      const quizIndicator: QuizIdentifier = await newQuizRequest(payload);
      quiz.setQuizId(quizIndicator.identifier);
      navigate(`/quiz/${quizIndicator.identifier}`)
    }
  };
  const disabled = !props.feasible || props.questionNumber === 0 || props.questionNumber === "";
  const className = disabled ? styles['disabled-start-button'] : styles['start-button'];
  return (
    <div className={styles['start-button-row']}>
      <button
        className={className}
        onClick={submit}
        disabled={disabled}
      >{g('startQuizButtonText')}</button>
    </div>
  )
}

function NoQuestionsMessage() {
  return (
    <>
      <h3 className={styles['no-questions']}>{g('noQuestionsCreated')}</h3>
      <Link to="/new-question">{g('createNewQuestion')}</Link>
    </>
  );
}

type NewQuizFormProps = {
  questionCount: NumberInput;
  setQuestionCount: (_: NumberInput) => void;
  selectedTagIds: string[];
  setSelectedTagIds: (_: string[]) => void;
  excludedTagIds: string[];
  setExcludedTagIds: (_: string[]) => void;
  pattern: LogicalPattern;
  setPattern: (_: LogicalPattern) => void;
  totalQuestions: number;
  allowRepeats: boolean;
  setAllowRepeats: (_: boolean) => void;
  feasibility: QuizFeasibility
}

function NewQuizForm(props: NewQuizFormProps) {
  const patternSelector = (
    <T.TagPatternSelector pattern={props.pattern} setPattern={props.setPattern}/>
  );
  const matching = props.feasibility.matchingQuestions;
  const labelText = g('newQuizQuestionsLabel');
  const questionSectionLabel = g('newQuizQuestionSectionLabel');
  const tagSectionLabel = g('newQuizTagSectionLabel');
  const allMatches = () => {
    if (matching !== 0) {
      props.setQuestionCount(matching);
    }
  };
  const ref = useRef<HTMLInputElement>(null);
  useFocus(ref);
  const allMatchesButtonClass = styles[matching !== 0 ? 'all-matches-button' : 'all-matches-button-disabled'];
  const sectionClass = styles['form-section'];
  return (
    <div>
      <FormSection title={questionSectionLabel} additionalClasses={sectionClass}>
        <div className={styles['question-number-row']}>
          <label className={styles['question-label']}>{labelText}</label>
          <input
            ref={ref}
            className={styles['question-number-input']}
            type="number"
            min="1"
            max={`${props.totalQuestions}`}
            step="1"
            value={props.questionCount}
            onChange={event => {
              if (!event.target.value.length) {
                props.setQuestionCount("")
              } else {
                props.setQuestionCount(parseInt(event.target.value, 10));
              }
            }}
          />
          <button
            className={allMatchesButtonClass}
            onClick={event => allMatches()}
          >{g('allMatches')}</button>
        </div>
        <FeasibilityWarning desiredQuestions={props.questionCount} feasibility={props.feasibility}/>
        <div className={styles['allow-repeats-row']}>
          <Toggle on={props.allowRepeats} onClick={() => {
            if (matching > 0) {
              props.setAllowRepeats(!props.allowRepeats);
            }}}/>
          <label className={styles['repeat-label']}>{g("allowRepeats")}</label>
        </div>
      </FormSection>
      <FormSection title={tagSectionLabel} additionalClasses={sectionClass}>
        <T.TagSelector
          selectedTagIds={props.selectedTagIds}
          setSelectedTagIds={props.setSelectedTagIds}
          addNewTag={() => {}}
          expandable={false}
          patternSelectorProps={{
            pattern: props.pattern,
            setPattern: props.setPattern
          }}
          excludedTagProps={{
            excludedTagIds: props.excludedTagIds,
            setExcludedTagIds: props.setExcludedTagIds
          }}
        />
      </FormSection>
    </div>
  );
}

type FeasibilityWarningProps = {
  desiredQuestions: NumberInput;
  feasibility: QuizFeasibility;
}

function FeasibilityWarning(props: FeasibilityWarningProps) {
  const className = styles['feasibility-warning'];
  if (props.feasibility.feasible) {
    return null;
  } else if (props.feasibility.repeatsWillFix) {
    const inputs = [
      `${props.feasibility.matchingQuestions || 0}`,
      `${props.desiredQuestions}`
    ];

    const glossaryKey = props.feasibility.matchingQuestions === 1 ? (
      'quizFeasibilityWarningRepeatsNotAllowedSingular'
    ) : (
      'quizFeasibilityWarningRepeatsNotAllowed'
    );
    return (
      <div className={className}>
        {gt(glossaryKey, inputs)}
      </div>
    );
  } else {
    return (
      <div className={className}>
        {g('quizFeasibilityWarningRepeatsAllowed')}
      </div>
    );
  }
}
