export type NumberPredicate = {
  base: number;
  comparator: Comparator;
};

export const LTE = "\u2264";
export const GTE = "\u2265";
export const E = "=";

export type InexactComparator = typeof LTE | typeof GTE;

export type Comparator = InexactComparator | typeof E;

export const INEXACT_COMPARATORS = [LTE, GTE] as const;

export const COMPARATORS = [LTE, E, GTE] as const;
