import React, { useState, useRef, ReactNode } from 'react';
import { Tag, newTagFromName } from "../tags";
import styles from "./TagSelector.module.scss";
import { AiOutlinePlus } from "react-icons/ai";
import useClosable from "closable";
import useFocus from "focus";
import Info from "Info";
import { TagInfo } from "./TagInfo";
import TagChiclet from "./TagChiclet";
import { g } from "language";
import { useUser } from "user";
import { TagPatternSelector, TagPatternSelectorProps } from "tags/TagPatternSelector";
import isMobile from 'responsive';

type ExcludedTagProps = {
  excludedTagIds: string[];
  setExcludedTagIds: (_: string[]) => void;
}

type BasicTagSelectorProps = {
  selectedTagIds: string[];
  setSelectedTagIds: (_: string[]) => void;
}

export type TagSelectorProps = BasicTagSelectorProps & {
  addNewTag: (_: Tag) => void;
  expandable?: boolean;
  patternSelectorProps?: TagPatternSelectorProps;
  excludedTagProps?: ExcludedTagProps;
  additionalClasses?: string;
}

export default function TagSelector(props: TagSelectorProps) {
  const userContext = useUser();
  const [visible, setVisible] = useState(false);
  const [excludedVisible, setExcludedVisible] = useState(false);
  const possibleTags = userContext.user.allTagIds();
  const expandable = !!(props.expandable || props.expandable === undefined);
  const selectedSet = new Set(props.selectedTagIds);
  const excludedSet = new Set(props.excludedTagProps?.excludedTagIds || []);
  const possibleSet = new Set(possibleTags);
  const existingNamesSet = new Set(userContext.user.allTags().map(tag => tag.body.text));
  const availableToChoose = (tagId: string) => !selectedSet.has(tagId) && !excludedSet.has(tagId);
  const dropdownTags = possibleTags.filter(availableToChoose);
  const addTag = (tag: Tag) => {
    const text = tag.body.text;
    if (!existingNamesSet.has(text) && expandable) {
      userContext.user.addTag(tag);
      props.addNewTag(tag);
    }
    if (!selectedSet.has(text) && (expandable || possibleSet.has(tag.tagId))) {
      props.setSelectedTagIds(props.selectedTagIds.concat([tag.tagId]));
    }
  };
  const excludeTag = (tag: Tag) => {
    if (props.excludedTagProps && availableToChoose(tag.tagId)) {
      const { excludedTagIds, setExcludedTagIds } = props.excludedTagProps;
      setExcludedTagIds(excludedTagIds.concat([tag.tagId]));
    }
  };
  const layoutClass = styles[isMobile() ? 'vertical-selector-layout' : 'horizontal-selector-layout'];
  const firstSelectorClass = props.excludedTagProps ? (
    styles[isMobile() ? 'vertical-first' : 'horizontal-first']
  ) : '';
  const secondSelectorClass = styles[isMobile() ? 'vertical-second' : 'horizontal-second'];
  const topClass = `${layoutClass} ${props.additionalClasses || ''}`;
  return (
    <div className={topClass}>
      <div className={firstSelectorClass}>
        <UnivalentTagSelector
          selectedTagIds={props.selectedTagIds}
          setSelectedTagIds={props.setSelectedTagIds}
          visible={visible}
          setVisible={setVisible}
          dropdownTags={dropdownTags}
          addTag={addTag}
          inclusive={true}
        />
        {props.patternSelectorProps ? (
          <div className={styles['pattern-selector']}>
            <TagPatternSelector {...props.patternSelectorProps}/>
          </div>
        ) : null}
      </div>
      {props.excludedTagProps ? (
        <div className={secondSelectorClass}>
          <UnivalentTagSelector
            selectedTagIds={props.excludedTagProps.excludedTagIds}
            setSelectedTagIds={props.excludedTagProps.setExcludedTagIds}
            visible={excludedVisible}
            setVisible={setExcludedVisible}
            dropdownTags={dropdownTags}
            addTag={excludeTag}
            inclusive={false}
          />
        </div>
      ) : null}
    </div>
  );
}

type UnivalentTagSelectorProps = BasicTagSelectorProps & {
  visible: boolean;
  setVisible: (_: boolean) => void;
  dropdownTags: string[];
  addTag: (_: Tag) => void;
  inclusive: boolean;
}

function UnivalentTagSelector(props: UnivalentTagSelectorProps) {
  const header = g(props.inclusive ? 'tagHeader' : 'excludedTagHeader');
  const explanation = g(props.inclusive ? 'tagExplanation' : 'tagExclusionExplanation');
  const buttonText = g(props.inclusive ? 'addTag' : 'excludeTag');
  return (
    <>
      <SelectedTagsList
        selectedTagIds={props.selectedTagIds}
        setSelectedTagIds={props.setSelectedTagIds}
        inclusive={props.inclusive}
      />
      <div className={styles['dropdown-wrapper']}>
        <span>
          <button className={styles['add-tag']} onClick={() => props.setVisible(!props.visible)}>{buttonText}</button>
          <Info title={header}><TagInfo text={explanation}/></Info>
        </span>
        {props.visible && (
          <TagDropdown
            tagIds={props.dropdownTags}
            addTag={props.addTag}
            setVisible={v => props.setVisible(v)}
          />
        )}
      </div>
    </>
  );
}

type SelectedTagsListProps = {
  selectedTagIds: string[];
  setSelectedTagIds: (tags: string[]) => void;
  inclusive: boolean;
};

function SelectedTagsList(props: SelectedTagsListProps) {
  const noTagsText = g('noTags');
  const absentClass = `${styles['tag-chiclet']} ${styles['chiclet-absent']}`;
  const userContext = useUser();
  const label = g(props.inclusive ? 'tagLabel' : 'excludedTagLabel');
  const listDisplay = (props.selectedTagIds.length === 0) ? (
    <span className={absentClass}>{noTagsText}</span>
  ) : (
    props.selectedTagIds.map((tagId, index) => {
      const tag = userContext.user?.getTag(tagId);
      return tag ? (
        <TagChiclet key={tagId} tag={tag} index={index} onClick={() => {
          props.setSelectedTagIds(props.selectedTagIds.filter(t => t !== tagId));
        }}/>
      ) : null;
    })
  );
  return (
    <div className={styles['chiclet-list']}>
      <span className={styles['tag-indicator']}>{label}: </span>
      {listDisplay}
    </div>
  );
}

type TagDropdownProps = {
  tagIds: string[];
  addTag: (tag: Tag) => void;
  setVisible: (visible: boolean) => void;
}

function TagDropdown(props: TagDropdownProps) {
  const [text, setText] = useState("");
  const userContext = useUser();
  const searchText = text.toLowerCase();
  const filterFunction = (tagId: string) => text === "" || userContext.user?.getTag(tagId)?.body.text.toLowerCase().startsWith(searchText);
  const rowClass = `${styles['tag-row']} ${styles['tag-selection-row']}`;
  const ref = useRef(null);
  useClosable(ref, () => props.setVisible(false));
  if (userContext.user) {
    return (
      <div className={styles['dropdown']} ref={ref}>
        <TagSearch addTag={props.addTag} text={text} setText={setText}/>
        {props.tagIds.filter(filterFunction).map(tagId => {
          const tag = userContext.user?.getTag(tagId);
          return tag ? (
            <div className={rowClass} key={tagId} onClick={() => props.addTag(tag)}>
              <span className={styles['tag-selector-name']}>{tag.body.text}</span>
              <AiOutlinePlus className={styles['tag-selector-plus']}/>
            </div>
          ) : null;
        })}
      </div>
    );
  } else {
    return null;
  }
}

type TagSearchProps = {
  addTag: (tag: Tag) => void;
  text: string;
  setText: (text: string) => void;
}

function TagSearch(props: TagSearchProps) {
  const ref = useRef<HTMLInputElement>(null);
  useFocus(ref);
  const userContext = useUser();
  const action = () => {
    if (userContext.user && props.text.length > 0) {
      props.addTag(userContext.user.tagByName(props.text) || newTagFromName(props.text));
    }
  }
  return (
    <div className={styles['tag-row']}>
      <div className={styles['input-wrapper']}>
        <input
          type="text"
          placeholder="Search or create"
          className={styles['tag-search']}
          onChange={event => props.setText(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              action();
            }
          }}
          ref={ref}
        />
      </div>
      <button className={styles['inner-add-tag']} onClick={() => action()}>{g('addTagButtonText')}</button>
    </div>
  );
}
