import { UndoHistory } from './undoHistory';

type FieldSetter<T extends object, K extends keyof T> = (_: T[K]) => void;

export function setStateAndRegister<T extends object, K extends keyof T>(
  history: UndoHistory<T>, k: K, setter: FieldSetter<T, K>
): FieldSetter<T, K> {
  return (v: T[K]) => {
    setter(v);
    let entry: Partial<T> = {};
    entry[k] = v;
    history.registerChange(entry);
  };
}