import { NumericScore } from "QuizResultsPage";
import styles from "./PointsBar.module.scss";
import { gp, GlossaryKey } from "language";
import { scoreDecimalDisplay } from "questionCorrectness";

type PointsBarProps = {
  score: NumericScore;
  additionalClasses?: string;
  pointsEarnedKey: GlossaryKey;
  pointsPossibleKey: GlossaryKey;
}

export default function PointsBar(props: PointsBarProps) {
  const { points, pointsPossible } = props.score;
  const pointsSpan = points / pointsPossible;
  const remainderSpan = 1.0 - pointsSpan;
  const dynamicColorBarStyle = {
    gridTemplateColumns: `${pointsSpan}fr ${remainderSpan}fr`
  }
  const className = `${styles['quiz-points-bar']} ${props.additionalClasses || ""}`;
  const pointsIndicator = `${scoreDecimalDisplay(points)} ${gp(props.pointsEarnedKey, points)}`;
  const possibleIndicator = `${scoreDecimalDisplay(pointsPossible)} ${gp(props.pointsPossibleKey, pointsPossible)}`;
  return (
    <div className={className}>
      <div className={styles['points']}>{pointsIndicator}</div>
      <div className={styles['color-bar']} style={dynamicColorBarStyle}>
        <div className={styles['points-span']}></div>
        <div className={styles['remainder-span']}></div>
      </div>
      <div className={styles['points-possible']}>{possibleIndicator}</div>
    </div>
  );
}
