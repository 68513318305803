import { QuestionType, functionalityByType } from "../questionTypes";
import * as M from "./questionSettingsModel";
import { QuestionSettingsContext } from "./useSettingsState";
import { AnswerLabel } from "labels";
import { GTE } from "commonComponents/NumberPredicateSelector";
import { MIN_ERROR_MARGIN, MAX_ERROR_MARGIN } from "./settingsConstants";

export function defaultMaximalSettingsState(): M.MaximalQuestionSettingsModel {
  return {
    imageAfter: false,
    labelType: null,
    ignoreWhitespace: true,
    ignoreCapitalization: true,
    ignorePunctuation: true,
    errorMargin: 0,
    randomize: false,
    ordered: false,
    requiredCount: null
  };
}

export function blankMaximalSettingsState(): Partial<M.MaximalQuestionSettingsModel> {
  return {
    imageAfter: undefined,
    labelType: undefined,
    ignoreWhitespace: undefined,
    ignoreCapitalization: undefined,
    ignorePunctuation: undefined,
    errorMargin: undefined,
    randomize: undefined,
    ordered: undefined,
    requiredCount: undefined
  };
}

export function defaultSettingsSearchState(): M.SettingsSearchModel {
  const d = defaultMaximalSettingsState();
  return {
    imageAfter: d.imageAfter,
    labelType: 'all',
    ignoreWhitespace: d.ignoreWhitespace,
    ignoreCapitalization: d.ignoreCapitalization,
    ignorePunctuation: d.ignorePunctuation,
    errorMargin: {
      base: 0,
      comparator: GTE
    },
    randomize: d.randomize,
    ordered: d.ordered,
    requiredCount: true
  };
};

export function maximalSettingsModelFromSearch(s: M.SettingsSearchModel): M.MaximalQuestionSettingsModel {
  return {
    imageAfter: s.imageAfter,
    labelType: null,
    ignoreWhitespace: s.ignoreWhitespace,
    ignoreCapitalization: s.ignoreCapitalization,
    ignorePunctuation: s.ignorePunctuation,
    errorMargin: 0,
    randomize: s.randomize,
    ordered: s.ordered,
    requiredCount: null
  };
}

export function mergeMaximalSettingsToSearch(
  m: M.MaximalQuestionSettingsModel, s: M.SettingsSearchModel
): M.SettingsSearchModel {
  return {
    ...s,
    imageAfter: m.imageAfter,
    ignoreWhitespace: m.ignoreWhitespace,
    ignoreCapitalization: m.ignoreCapitalization,
    ignorePunctuation: m.ignorePunctuation,
    randomize: m.randomize,
    ordered: m.ordered
  };
}

export function expandSettingsState(model: Partial<M.MaximalQuestionSettingsModel>): M.MaximalQuestionSettingsModel {
  return {
    ...defaultMaximalSettingsState(),
    ...model,
    requiredCount: null
  };
}

function baseSettingsModel(): M.BaseModel {
  return {
    imageAfter: false
  };
}

function labelSettingsModel(): M.LabelModel {
  return {
    labelType: null
  };
}

function choiceSettingsModel(): M.ChoiceModel {
  return {
    ...baseSettingsModel(),
    ...labelSettingsModel()
  };
}

function writtenSettingsModel(): M.TextEntryModel & M.BaseModel {
  return {
    ...baseSettingsModel(),
    ignoreWhitespace: true,
    ignoreCapitalization: true,
    ignorePunctuation: true,
    errorMargin: 0
  };
}

function requiredCountSettingsModel(): M.RequiredCountModel {
  return {
    requiredCount: null
  }
}

export function writeInSettingsModel(): M.WriteInModel {
  return {
    ...writtenSettingsModel(),
    ...labelSettingsModel(),
    ...requiredCountSettingsModel(),
    ordered: false,
  };
}

export function fillInTheBlankSettingsModel(): M.FillInTheBlankModel {
  return {
    ...writtenSettingsModel(),
    randomize: false
  };
}

export function trueFalseSettingsModel(): M.TrueFalseModel {
  return baseSettingsModel();
}

export function multipleChoiceSettingsModel(): M.MultipleChoiceModel {
  return choiceSettingsModel();
}

export function multiSelectSettingsModel(): M.MultiSelectModel {
  return choiceSettingsModel();
}

export function orderingSettingsModel(): M.OrderingModel {
  return {
    ...requiredCountSettingsModel(),
    ...labelSettingsModel(),
    ...baseSettingsModel()
  };
}

export function applicableSettingsFields(questionType: QuestionType): Set<M.Setting> {
  let model = functionalityByType(questionType).defaultSettings();
  return new Set(Object.keys(model)) as Set<keyof M.MaximalQuestionSettingsModel>;
}

export function applicableSettingsFieldsMultiple(questionTypes: QuestionType[]): Set<M.Setting> {
  return questionTypes.reduce((acc, qt) => {
    return new Set(Array.from(applicableSettingsFields(qt).values()).concat(Array.from(acc.values())));
  }, new Set<M.Setting>());
}

export function clampedSettingsModel(questionType: QuestionType, max: M.MaximalQuestionSettingsModel): M.QuestionSettingsModel {
  let base = functionalityByType(questionType).defaultSettings();
  (Object.keys(base) as (keyof typeof base)[]).forEach(key => base[key] = max[key]);
  return base;
}

export function settingsStateToModel(state: QuestionSettingsContext): M.MaximalQuestionSettingsModel {
  return {
    ...state
  } as M.MaximalQuestionSettingsModel;
}

export function bloatedSettingsState(settings: M.QuestionSettingsModel): M.MaximalQuestionSettingsModel {
  return {
    ...defaultMaximalSettingsState(),
    ...settings
  };
}

export function effectiveErrorMargin(errorMargin: number, answerLength: number): number {
  return Math.floor(answerLength * errorMargin / 100);
}

export function potentialErrorMargin(settings: M.QuestionSettingsModel, answerLength: number) {
  if (M.isTextualSettings(settings)) {
    return effectiveErrorMargin(settings.errorMargin, answerLength);
  } else {
    return 0;
  }
}

export function errorMargin(settings: M.QuestionSettingsModel): number {
  if (M.isTextualSettings(settings)) {
    return settings.errorMargin;
  } else {
    return 0;
  }
}

export function labelType(settings: M.QuestionSettingsModel): AnswerLabel {
  return (settings as M.LabelModel).labelType || 'noLabel';
}

export function ignoreCapitalization(settings: M.QuestionSettingsModel): boolean {
  if (M.isTextualSettings(settings)) {
    return settings.ignoreCapitalization;
  } else {
    return false;
  }
}

export function castMaximal(settings: M.QuestionSettingsModel): M.MaximalQuestionSettingsModel {
  return {
    ...defaultMaximalSettingsState(),
    ...settings
  };
}

export function castBlankMaximal(settings: M.QuestionSettingsModel): Partial<M.MaximalQuestionSettingsModel> {
  return {
    ...blankMaximalSettingsState(),
    ...settings
  };
}

function allSettings(): M.Setting[] {
  return Object.keys(defaultMaximalSettingsState()) as M.Setting[];
}

export function settingsEqual(a: M.QuestionSettingsModel, b: M.QuestionSettingsModel): boolean {
  const a_expanded = castBlankMaximal(a);
  const b_expanded = castBlankMaximal(b);
  return allSettings().reduce((equal, key) => {
    const a_value = a_expanded[key];
    const b_value = b_expanded[key];
    return equal && settingsValuesEqual(a_value, b_value);
  }, true as boolean);
}

export function unequalSettings(a: M.QuestionSettingsModel, b: M.QuestionSettingsModel): M.Setting[] {
  const a_expanded = castMaximal(a);
  const b_expanded = castMaximal(b);
  return allSettings().filter(key => {
    return !settingsValuesEqual(a_expanded[key], b_expanded[key])
  });
}

function settingsValueAbsent(s: M.SettingsValue | undefined): boolean {
  return s === null || s === undefined
}

export function settingsValuesEqual(a: M.SettingsValue | undefined, b: M.SettingsValue | undefined): boolean {
  const a_absent = settingsValueAbsent(a);
  const b_absent = settingsValueAbsent(b);
  return (a_absent && b_absent) || a === b;
}

export function settingsUndoSufficientDifference(
  last: M.MaximalQuestionSettingsModel, current: M.MaximalQuestionSettingsModel
): boolean {
  return !settingsEqual(last, current);
}

export function validErrorMargin(value: number): boolean {
  return value >= MIN_ERROR_MARGIN && value <= MAX_ERROR_MARGIN;
}
