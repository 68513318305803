import React from 'react';
import styles from "./widgets.module.scss";
import { BsDash } from "react-icons/bs";

type MinusButtonProps = {
  enabled?: boolean;
  onClick: () => void;
  classes?: string;
}

export function MinusButton(props: MinusButtonProps) {
  const enabled = props.enabled === undefined ? true : props.enabled;
  const mainClass = styles[enabled ? 'minus-button' : 'blank-minus-button-inactive'];
  const className = `${mainClass} ${props.classes || ""}`;
  const onClick = enabled ? props.onClick : () => {};
  return (
    <button className={className} onClick={onClick}>
      <BsDash/>
    </button>
  );
}
