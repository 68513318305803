export const QUIZ_ROUTE = "/quiz/:quiz_id";
export const HOME_PAGE_ROUTE = "/user-home-page";
export const NEW_QUIZ_ROUTE = "/new-quiz";
export const QUESTION_ROUTE = "/question/:question_id";
export const NEW_QUESTION_ROUTE = "/new-question";
export const QUESTIONS_ROUTE = "/questions";
export const SIGN_UP_ROUTE = "/sign-up";
export const QUIZ_RESULTS_ROUTE = "/quiz-results/:quiz_id";

export function quizRoute(quizId: string): string {
  return QUIZ_ROUTE.replace(":quiz_id", quizId);
}

export function questionRoute(questionId: string): string {
  return QUESTION_ROUTE.replace(":question_id", questionId);
}

export function questionRouteDefaultEditable(questionId: string): string {
  return `${questionRoute(questionId)}?editing=true`;
}
