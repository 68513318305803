import React from 'react';
import {
  QuestionType,
  QuestionBody,
  TrueFalse, WriteIn, MultipleChoice, MultiSelect, FillInTheBlank, Ordering
} from "./questionTypes";
import { TextDisplay } from "TextEditor";
import { SelectableAnswer } from "./selectableAnswer";
import {
  VariadicQuestion,
  VariadicQuestionState,
  indistinguishableFromDerived,
  questionSetterConsideringSwitchbacks
} from "./variadicQuestionState";
import {
  InvalidVariant,
  writeInValidity,
  multipleChoiceValidity,
  trueFalseValidity,
  multiSelectValidity,
  fillInTheBlankValidity,
  orderingValidity,
  allMultipleChoiceAnswers,
  allMultiSelectAnswers,
  writeInUndoSufficientDifference,
  multipleChoiceUndoSufficientDifference,
  multiSelectUndoSufficientDifference,
  trueFalseUndoSufficientDifference,
  fillInTheBlankUndoSufficientDifference,
  orderingUndoSufficientDifference
} from "./questionOperations";
import {
  QuestionFormProps,
  WriteInQuestionForm,
  MultipleChoiceQuestionForm,
  TrueFalseQuestionForm,
  FillInTheBlankQuestionForm,
  MultiSelectQuestionForm,
  OrderingQuestionForm
} from "./questionForms";
import { FinalResponse, serializableMultiSelectResponse } from "quiz/progress";
import {
  Response,
  isWriteInResponse,
  isMultiSelectResponse,
  isMultipleChoiceResponse,
  isTrueFalseResponse,
  isFillInTheBlankResponse,
  isOrderingResponse
} from "quiz/response";
import {
  LabeledFileHandle, multipleChoiceAnswerImageHandles, multiSelectAnswerImageHandles, orderingAnswerImageHandles
} from "pictures";
import {
  QuizQuestionFormProps,
  WriteInQuizQuestion,
  TrueFalseQuizQuestion,
  FillInTheBlankQuizQuestion,
  MultiSelectQuizQuestion,
  MultipleChoiceQuizQuestion,
  OrderingQuizQuestion
} from "quizQuestionForms";
import {
  QuestionResultDetailProps,
  WriteInComparison,
  MultipleChoiceComparison,
  MultiSelectComparison,
  TrueFalseComparison,
  FillInTheBlankComparison,
  OrderingComparison
} from "QuizResultsPage";
import {
  QuestionBodyDisplayProps,
  QuestionPromptDisplayProps,
  WriteInDisplay,
  MultipleChoiceDisplay,
  MultiSelectDisplay,
  TrueFalseDisplay,
  FillInTheBlankDisplay,
  OrderingDisplay
} from "questionBodyDisplay";
import {
  QuestionSettingsModel,
  writeInSettingsModel,
  multipleChoiceSettingsModel,
  multiSelectSettingsModel,
  trueFalseSettingsModel,
  fillInTheBlankSettingsModel,
  orderingSettingsModel
} from "QuestionSettings";
import {
  QuizQuestion,
  writeInInitialResponse,
  initialMultipleChoiceResponse,
  initialMultiSelectResponse,
  fillInTheBlankInitialResponse,
  initialTrueFalseResponse,
  orderingInitialResponse,
  writeInNotAttempted,
  trueFalseNotAttempted,
  fillInTheBlankNotAttempted,
  multiSelectNotAttempted,
  multipleChoiceNotAttempted
} from "quiz";
import * as C from "./questionTypeConversions";

const NOT_IMPLEMENTED = "method not implemented";

export function functionalityByType(questionType: QuestionType): QuestionFunctionality {
  switch (questionType) {
    case 'write-in': return new WriteInFunctionality();
    case 'multiple-choice': return new MultipleChoiceFunctionality();
    case 'multi-select': return new MultiSelectFunctionality();
    case 'fill-in-the-blank': return new FillInTheBlankFunctionality();
    case 'true-false': return new TrueFalseFunctionality();
    case 'ordering': return new OrderingFunctionality();
  }
}

export function functionalityByBody(body: QuestionBody): QuestionFunctionality {
  if (C.isWriteIn(body)) {
    return new WriteInFunctionality();
  }
  if (C.isMultipleChoice(body)) {
    return new MultipleChoiceFunctionality();
  }
  if (C.isMultiSelect(body)) {
    return new MultiSelectFunctionality();
  }
  if (C.isFillInTheBlank(body)) {
    return new FillInTheBlankFunctionality();
  }
  if (C.isTrueFalse(body)) {
    return new TrueFalseFunctionality();
  }
  if (C.isOrdering(body)) {
    return new OrderingFunctionality();
  }
  throw new Error("invalid question body");
}

export function functionalityByResponse(response: Response): QuestionFunctionality {
  if (isWriteInResponse(response)) {
    return new WriteInFunctionality();
  }
  if (isMultipleChoiceResponse(response)) {
    return new MultipleChoiceFunctionality();
  }
  if (isMultiSelectResponse(response)) {
    return new MultiSelectFunctionality();
  }
  if (isTrueFalseResponse(response)) {
    return new TrueFalseFunctionality()
  }
  if (isFillInTheBlankResponse(response)) {
    return new FillInTheBlankFunctionality();
  }
  if (isOrderingResponse(response)) {
    return new OrderingFunctionality();
  }
  throw new Error("invalid question response");
}

export abstract class QuestionFunctionality {
  abstract questionType(): QuestionType;
  abstract resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody;
  abstract invalidVariant(body: QuestionBody): InvalidVariant;
  abstract form(props: QuestionFormProps): JSX.Element;
  abstract serializableResponse(response: Response): FinalResponse;
  abstract answerImageHandles(body: QuestionBody): LabeledFileHandle[];
  abstract quizForm(props: QuizQuestionFormProps): JSX.Element;
  abstract resultDetailQuestion(question: QuestionPromptDisplayProps): JSX.Element;
  abstract resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element;
  abstract bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element;
  abstract promptDisplay(body: QuestionPromptDisplayProps): JSX.Element;
  abstract defaultSettings(): QuestionSettingsModel;
  abstract initialResponse(question: QuizQuestion): Response;
  abstract updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody): void;
  abstract answerCount(body: VariadicQuestionState): number | null;
  abstract responseNotAttempted(response: Response): boolean;
  abstract allSelectableAnswers(body: QuestionBody): SelectableAnswer[];
  abstract minRequiredCount(): number;
  abstract indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean;
  abstract undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean;
};

class NotImplementedFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    throw new Error(NOT_IMPLEMENTED);
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    throw new Error(NOT_IMPLEMENTED);
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    throw new Error(NOT_IMPLEMENTED);
  }

  form(props: QuestionFormProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  serializableResponse(response: Response): FinalResponse {
    throw new Error(NOT_IMPLEMENTED);
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    throw new Error(NOT_IMPLEMENTED);
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  resultDetailQuestion(question: QuestionPromptDisplayProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  promptDisplay(props: QuestionPromptDisplayProps): JSX.Element {
    throw new Error(NOT_IMPLEMENTED);
  }

  defaultSettings(): QuestionSettingsModel {
    throw new Error(NOT_IMPLEMENTED);
  }

  initialResponse(question: QuizQuestion): Response {
    throw new Error(NOT_IMPLEMENTED);
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    throw new Error(NOT_IMPLEMENTED);
  }

  answerCount(body: VariadicQuestionState): number | null {
    throw new Error(NOT_IMPLEMENTED);
  }

  responseNotAttempted(response: Response): boolean {
    throw new Error(NOT_IMPLEMENTED);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    throw new Error(NOT_IMPLEMENTED);
  }

  minRequiredCount(): number {
    throw new Error(NOT_IMPLEMENTED);
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    throw new Error(NOT_IMPLEMENTED);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    throw new Error(NOT_IMPLEMENTED);
  }
}

class WriteInFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'write-in';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.writeIn;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return writeInValidity(body);
  }

  form(props: QuestionFormProps): JSX.Element {
    return (
      <WriteInQuestionForm
        question={props.state.state.writeIn}
        setQuestion={questionSetterConsideringSwitchbacks(props.state.setters.setWriteIn, props.state.setSwitchbacks)}
        labelType={props.labelType}
      />
    );
  }

  serializableResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return [];
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <WriteInQuizQuestion {...props}/>;
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return (
      <TextDisplay
        value={(props.question as WriteIn).question}
        additionalClasses={props.additionalClasses}
      />
    );
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <WriteInComparison {...props}/>;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <WriteInDisplay {...props}/>;
  }

  promptDisplay(props: QuestionPromptDisplayProps): JSX.Element {
    return this.resultDetailQuestion(props);
  }

  defaultSettings(): QuestionSettingsModel {
    return writeInSettingsModel();
  }

  initialResponse(question: QuizQuestion): Response {
    return writeInInitialResponse(question);
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setWriteIn(C.toWriteIn(body))
  }

  answerCount(state: VariadicQuestionState): number | null {
    return state.state.writeIn.answers.length;
  }

  responseNotAttempted(response: Response): boolean {
    return writeInNotAttempted(response);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return [];
  }

  minRequiredCount(): number {
    return 1;
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toWriteIn);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return writeInUndoSufficientDifference(last as WriteIn, current as WriteIn);
  }
}

class MultipleChoiceFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'multiple-choice';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.multipleChoice;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return multipleChoiceValidity(body);
  }

  form (props: QuestionFormProps): JSX.Element {
    return (
      <MultipleChoiceQuestionForm
        question={props.state.state.multipleChoice}
        setQuestion={props.state.setters.setMultipleChoice}
        answerFiles={props.answerFiles}
        setAnswerFiles={props.setAnswerFiles}
      />
    );
  }

  serializableResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return multipleChoiceAnswerImageHandles(body);
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <MultipleChoiceQuizQuestion {...props}/>
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return (
      <TextDisplay
        value={(props.question as MultipleChoice).question}
        additionalClasses={props.additionalClasses}
      />
    );
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <MultipleChoiceComparison {...props}/>;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <MultipleChoiceDisplay {...props}/>;
  }

  promptDisplay(props: QuestionPromptDisplayProps) {
    return this.resultDetailQuestion(props);
  }

  defaultSettings(): QuestionSettingsModel {
    return multipleChoiceSettingsModel();
  }

  initialResponse(question: QuizQuestion): Response {
    return initialMultipleChoiceResponse();
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setMultipleChoice(C.toMultipleChoice(body))
  }

  answerCount(state: VariadicQuestionState): number | null {
    return null;
  }

  responseNotAttempted(response: Response): boolean {
    return multipleChoiceNotAttempted(response);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return allMultipleChoiceAnswers(body);
  }

  minRequiredCount(): number {
    return 2;
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toMultipleChoice);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return multipleChoiceUndoSufficientDifference(last as MultipleChoice, current as MultipleChoice);
  }
}

class MultiSelectFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'multi-select';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.multiSelect;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return multiSelectValidity(body);
  }

  form(props: QuestionFormProps): JSX.Element {
    return (
      <MultiSelectQuestionForm
        question={props.state.state.multiSelect}
        setQuestion={questionSetterConsideringSwitchbacks(props.state.setters.setMultiSelect, props.state.setSwitchbacks)}
        answerFiles={props.answerFiles}
        setAnswerFiles={props.setAnswerFiles}
      />
    );
  }

  serializableResponse(response: Response): FinalResponse {
    return serializableMultiSelectResponse(response);
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return multiSelectAnswerImageHandles(body);
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <MultiSelectQuizQuestion {...props}/>
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return (
      <TextDisplay
        value={(props.question as MultiSelect).question}
        additionalClasses={props.additionalClasses}
      />
    );
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <MultiSelectComparison {...props}/>;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <MultiSelectDisplay {...props}/>;
  }

  promptDisplay(props: QuestionPromptDisplayProps) {
    return this.resultDetailQuestion(props);
  }

  defaultSettings(): QuestionSettingsModel {
    return multiSelectSettingsModel();
  }

  initialResponse(question: QuizQuestion): Response {
    return initialMultiSelectResponse();
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setMultiSelect(C.toMultiSelect(body));
  }

  answerCount(state: VariadicQuestionState): number | null {
    return null;
  }

  responseNotAttempted(response: Response): boolean {
    return multiSelectNotAttempted(response);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return allMultiSelectAnswers(body);
  }

  minRequiredCount(): number {
    return 1;
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toMultiSelect);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return multiSelectUndoSufficientDifference(last as MultiSelect, current as MultiSelect);
  }
}

class FillInTheBlankFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'fill-in-the-blank';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.fillInTheBlank;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return fillInTheBlankValidity(body);
  }

  form(props: QuestionFormProps): JSX.Element {
    return (
      <FillInTheBlankQuestionForm
        question={props.state.state.fillInTheBlank}
        setQuestion={questionSetterConsideringSwitchbacks(props.state.setters.setFillInTheBlank, props.state.setSwitchbacks)}
      />
    );
  }

  serializableResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return [];
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <FillInTheBlankQuizQuestion {...props}/>
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return <></>;
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <FillInTheBlankComparison {...props}/>;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <FillInTheBlankDisplay {...props}/>;
  }

  promptDisplay(props: QuestionPromptDisplayProps): JSX.Element {
    return this.bodyDisplay({
      question: props.question,
      labelType: 'noLabel',
      singleLine: props.singleLine,
      additionalClasses: props.additionalClasses
    });
  }

  defaultSettings(): QuestionSettingsModel {
    return fillInTheBlankSettingsModel();
  }

  initialResponse(question: QuizQuestion): Response {
    return fillInTheBlankInitialResponse(question);
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setFillInTheBlank(C.toFillInTheBlank(body));
  }

  answerCount(state: VariadicQuestionState): number | null {
    return null;
  }

  responseNotAttempted(response: Response): boolean {
    return fillInTheBlankNotAttempted(response);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return [];
  }

  minRequiredCount(): number {
    return 1;
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toFillInTheBlank);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return fillInTheBlankUndoSufficientDifference(last as FillInTheBlank, current as FillInTheBlank);
  }
}

class TrueFalseFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'true-false';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.trueFalse;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return trueFalseValidity(body);
  }

  form(props: QuestionFormProps): JSX.Element {
    return (
      <TrueFalseQuestionForm
        question={props.state.state.trueFalse}
        setQuestion={questionSetterConsideringSwitchbacks(props.state.setters.setTrueFalse, props.state.setSwitchbacks)}
      />
    );
  }

  serializableResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return [];
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <TrueFalseQuizQuestion {...props}/>
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return (
      <TextDisplay
        value={(props.question as TrueFalse).question}
        additionalClasses={props.additionalClasses}
      />
    );
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <TrueFalseComparison {...props}/>;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <TrueFalseDisplay {...props}/>;
  }

  promptDisplay(props: QuestionPromptDisplayProps): JSX.Element {
    return this.resultDetailQuestion(props);
  }

  defaultSettings(): QuestionSettingsModel {
    return trueFalseSettingsModel();
  }

  initialResponse(question: QuizQuestion): Response {
    return initialTrueFalseResponse();
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setTrueFalse(C.toTrueFalse(body))
  }

  answerCount(state: VariadicQuestionState): number | null {
    return null;
  }

  responseNotAttempted(response: Response): boolean {
    return trueFalseNotAttempted(response);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return [];
  }

  minRequiredCount(): number {
    return 0;
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toTrueFalse);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return trueFalseUndoSufficientDifference(last as TrueFalse, current as TrueFalse);
  }
}

class OrderingFunctionality extends QuestionFunctionality {
  questionType(): QuestionType {
    return 'ordering';
  }

  resolveVariadicQuestionBody(state: VariadicQuestion): QuestionBody {
    return state.ordering;
  }

  invalidVariant(body: QuestionBody): InvalidVariant {
    return orderingValidity(body);
  }

  form(props: QuestionFormProps): JSX.Element {
    return (
      <OrderingQuestionForm
        question={props.state.state.ordering}
        setQuestion={questionSetterConsideringSwitchbacks(props.state.setters.setOrdering, props.state.setSwitchbacks)}
        answerFiles={props.answerFiles}
        setAnswerFiles={props.setAnswerFiles}
        labelType={props.labelType}
      />
    )
  }

  defaultSettings(): QuestionSettingsModel {
    return orderingSettingsModel();
  }

  updateVariadicQuestionBody(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setOrdering(C.toOrdering(body))
  }

  answerCount(state: VariadicQuestionState): number | null {
    return state.state.ordering.order.length;
  }

  answerImageHandles(body: QuestionBody): LabeledFileHandle[] {
    return orderingAnswerImageHandles(body);
  }

  initialResponse(question: QuizQuestion): Response {
    return orderingInitialResponse(question);
  }

  quizForm(props: QuizQuestionFormProps): JSX.Element {
    return <OrderingQuizQuestion {...props}/>;
  }

  responseNotAttempted(response: Response): boolean {
    return false;
  }

  finalResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  serializableResponse(response: Response): FinalResponse {
    return response as FinalResponse;
  }

  resultDetailQuestion(props: QuestionPromptDisplayProps): JSX.Element {
    return (
      <TextDisplay
        value={(props.question as Ordering).question}
        additionalClasses={props.additionalClasses}
      />
    );
  }

  resultDetailAnswer(props: QuestionResultDetailProps): JSX.Element {
    return <OrderingComparison {...props}/>;
  }

  minRequiredCount(): number {
    return 2;
  }

  bodyDisplay(props: QuestionBodyDisplayProps): JSX.Element {
    return <OrderingDisplay {...props}/>
  }

  promptDisplay(body: QuestionPromptDisplayProps): JSX.Element {
    return this.resultDetailQuestion(body);
  }

  allSelectableAnswers(body: QuestionBody): SelectableAnswer[] {
    return [];
  }

  updateVariadicQuestionState(state: VariadicQuestionState, body: QuestionBody) {
    state.setters.setOrdering(C.toOrdering(body));
  }

  indistinguishableFromDerived(currentBody: QuestionBody, newBody: QuestionBody): boolean {
    return indistinguishableFromDerived(currentBody, newBody, C.toOrdering);
  }

  undoSufficientDifference(last: QuestionBody, current: QuestionBody): boolean {
    return orderingUndoSufficientDifference(last as Ordering, current as Ordering);
  }
}
