import React, { ReactNode, useState, useRef } from 'react';
import { BsInfoCircle } from "react-icons/bs";
import styles from "./Info.module.scss";
import useClosable from "../closable";
import Modal from "../Modal";
import { g } from "language";

export type InfoProps = {
  title: string;
  children: ReactNode;
}

export default function Info(props: InfoProps) {
  const [visible, setVisible] = useState(false);
  return (
    <span>
      <BsInfoCircle className={styles['info-icon']} onClick={() => setVisible(true)}/>
      <Modal visible={visible} setVisible={setVisible}>
        <div className={styles['header']}>
          <BsInfoCircle/><span>{props.title}</span>
        </div>
        <div className={styles['content-wrapper']}>{props.children}</div>
        <div className={styles['close-button-wrapper']}>
          <button className={styles['close-button']} onClick={() => setVisible(false)}>{g('closeButton')}</button>
        </div>
      </Modal>
    </span>
  )
}
