import { NumericScore } from "QuizResultsPage";
import { questionPoints } from "./correctnessDisplay";

export function correctnessDynamicStyling(score: NumericScore): Record<string, string> {
  const points = questionPoints(score);
  const [r, g, b] = correctnessColorIndicator(points);
  return {
    borderLeftColor: `rgb(${r}, ${g}, ${b})`,
    background: `rgba(${r}, ${g}, ${b}, 0.2)`
  };
}

type RGB = [number, number, number];

function correctnessColorIndicator(fraction: number): RGB {
  const colors: RGB[] = [
    [255, 140, 105],
    [255, 255, 0],
    [0, 187, 0]
  ];
  const breakpoints = [0.0, 0.5, 1.0];
  let i = 1;
  while (i < breakpoints.length && fraction > breakpoints[i]) {
    i++;
  }
  const upper = i;
  const lower = i - 1;
  const frac = (fraction - breakpoints[lower]) / (breakpoints[upper] - breakpoints[lower]);
  return interpolateColorLinear(colors[lower], colors[upper], frac);
}

function interpolateColorLinear(worse: RGB, better: RGB, betterWeight: number): RGB {
  const answer: RGB = [0, 0, 0];
  for (let i = 0; i < answer.length; i++) {
    answer[i] = Math.floor(betterWeight * better[i] + (1.0 - betterWeight) * worse[i]);
  }
  return answer;
}
