export default class LocalPersistence<T extends object> {
  key: string;
  constructor(key: string) {
    this.key = key;
  }

  set(data: T) {
    localStorage.setItem(this.key, JSON.stringify(data));
  }

  get(): T | null {
    const item = localStorage.getItem(this.key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }
}