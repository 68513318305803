import React, { ReactNode } from 'react';
import SignUpPage from 'authentication/SignUpPage';
import { Routes, Route } from 'react-router-dom';
import HomePage from 'HomePage';
import NewQuizPage from "NewQuizPage";
import NewQuestionPage from "NewQuestionPage";
import UserHomePage from 'UserHomePage';
import styles from "./MainContent.module.scss";
import QuizPage from "QuizPage";
import { useUserOptional } from "user";
import SignInMessage from "SignInMessage";
import { useQuizContext } from "quizContext";
import ActiveQuizMessage from "ActiveQuizMessage";
import QuizResultsPage from "QuizResultsPage";
import QuestionsPage from "QuestionsPage";
import QuestionPage from "QuestionPage";
import { ErrorBoundary } from "react-error-boundary";
import * as R from "./routes";
import { useParams } from 'react-router-dom';

export default function MainContent() {
  return (
    <main className={styles['main-content']}>
      <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route path={R.SIGN_UP_ROUTE} element={<SignUpPage/>}/>
        <Route
          path={R.HOME_PAGE_ROUTE}
          element={<InterceptibleDestination element={<UserHomePage/>}/>}
        />
        <Route
          path={R.NEW_QUESTION_ROUTE}
          element={<InterceptibleDestination element={<NewQuestionPage/>}/>}
        />
        <Route
          path={R.NEW_QUIZ_ROUTE}
          element={<InterceptibleDestination element={<NewQuizPage/>}/>}
        />
        <Route
          path={R.QUESTIONS_ROUTE}
          element={<InterceptibleDestination element={<QuestionsPage/>}/>}
        />
        <Route
          path={R.QUESTION_ROUTE}
          element={<InterceptibleDestination element={<QuestionPage/>}/>}
        />
        <Route
          path={R.QUIZ_ROUTE}
          element={<QuizInterceptibleDestination element={<QuizPage/>}/>}
        />
        <Route
          path={R.QUIZ_RESULTS_ROUTE}
          element={<InterceptibleDestination element={<QuizResultsPage/>}/>}
        />
        {/*
        <InterceptibleRoute path="">
          <QuizResultsPage/>
        </InterceptibleRoute>
      */}
      </Routes>
    </main>
  )
}

function ErrorDisplay() {
  const userContext = useUserOptional();
  if (!userContext.user) {
    return <SignInMessage/>;
  } else {
    return <div>WHAT HAPPENED</div>;
  }
}

type InterceptibleRouteProps = {
  path: string;
  children: ReactNode;
}

function InterceptibleRoute(props: InterceptibleRouteProps) {
  return (
    <Route path={props.path} element={<InterceptibleDestination element={props.children}/>}/>
  );
}

type InterceptibleDestinationProps = {
  element: ReactNode;
}

function InterceptibleDestination(props: InterceptibleDestinationProps) {
  return (
    <AuthenticationRouteInterceptor>
      <MatchDenyingQuizRouteInterceptor>
        {props.element}
      </MatchDenyingQuizRouteInterceptor>
    </AuthenticationRouteInterceptor>
  );
}

function QuizInterceptibleDestination(props: InterceptibleDestinationProps) {
  return (
    <AuthenticationRouteInterceptor>
      <MatchAcceptingQuizRouteInterceptor>
        {props.element}
      </MatchAcceptingQuizRouteInterceptor>
    </AuthenticationRouteInterceptor>
  );
}

type RouteInterceptorProps = {
  children: ReactNode;
}

function AuthenticationRouteInterceptor(props: RouteInterceptorProps) {
  const userContext = useUserOptional();
  if (!userContext.user) {
    return <SignInMessage/>;
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorDisplay} onReset={() => {}}>
      {props.children}
    </ErrorBoundary>
  );
}

function QuizInterceptibleRoute(props: InterceptibleRouteProps) {
  return (
    <Route path={props.path}>
      <AuthenticationRouteInterceptor>
        <MatchAcceptingQuizRouteInterceptor>
          {props.children}
        </MatchAcceptingQuizRouteInterceptor>
      </AuthenticationRouteInterceptor>
    </Route>
  );
}

type QuizRouteInterceptorProps = {
  children: ReactNode;
}

function MatchAcceptingQuizRouteInterceptor(props: RouteInterceptorProps) {
  const quiz = useQuizContext();
  const quiz_id = useParams().quiz_id;
  if (quiz.quizId !== quiz_id) {
    return <ActiveQuizMessage/>
  } else {
    return <>{props.children}</>;
  }
}

function MatchDenyingQuizRouteInterceptor(props: RouteInterceptorProps) {
  const quiz = useQuizContext();
  if (quiz.quizId) {
    return <ActiveQuizMessage/>;
  } else {
    return <>{props.children}</>;
  }
}
