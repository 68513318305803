import { Tag } from "tags";
import { LogicalPattern } from "commonComponents/LogicalPatternSelector";
import {
  SelectableAnswer, WriteInAnswer, TextElement, SuppliedElement, Question, OrderingAnswer
} from "questionTypes";
import { Content } from "TextEditor";
import { QuestionPrompt } from "quiz";
import { QuestionEditContext } from "questionContext";

export type QuizResultEnvelope = {
  quiz: CompletedQuiz;
  questions: CompletedQuizQuestion[];
  latestQuestionVersions: Question[];
  allTags: Tag[];
}

export type CompletedQuiz = {
  quizId: string;
  startTime: string;
  completionTime: string;
  tagIds: string[];
  tagPattern: LogicalPattern;
  allowRepeats: boolean;
  totalScore: NumericScore;
  excludedTagIds: string[];
  ordinal: number;
  questionNumber: number;
};

export type CompletedQuizQuestion = {
  question: QuestionEditContext;
  quizSpecific: QuizSpecific;
}

export type NumericScore = {
  points: number;
  pointsPossible: number;
};

export type QuizSpecific = {
  version: number;
  index: number;
  adaptedBody: QuestionPrompt;
  score: NumericScore;
  scoreExplanation: QuestionScoreExplanation;
  flagged: boolean;
  hintsRevealed: number;
};

export type QuestionScoreExplanation = TrueFalseExplanation |
                                       MultiSelectExplanation |
                                       MultipleChoiceExplanation |
                                       WriteInExplanation |
                                       FillInTheBlankExplanation |
                                       OrderingExplanation;

export type TrueFalseExplanation = {
  answerAttempted: boolean;
};

export type MultipleChoiceExplanation = {
  answer: SelectableAnswer | null;
};

export type MultiSelectExplanation = {
  correctSelected: SelectableAnswer[];
  otherSelected: SelectableAnswer[];
};

export type WriteInExplanation = {
  answers: WriteInMatch[];
};

type WriteInMatch = {
  response: WriteInAnswer;
  match: MatchedAnswer | null;
}

export type FillInTheBlankExplanationElement = TextElement | SuppliedElement | BlankElementExplanation;

export type FillInTheBlankExplanation = {
  structure: FillInTheBlankExplanationElement[];
};

type BlankElementExplanation = {
  blankResponse: Content;
  blankAnswer: Content;
  matchDescription: MatchDescription;
};

export type OrderingExplanation = {
  order: OrderingMatch[];
};

export type OrderingMatch = {
  answerPosition: number;
  response: OrderingAnswer;
  inOrder: boolean;
  partitionAfter: boolean;
};

export function isTextElement(element: FillInTheBlankExplanationElement): element is TextElement {
  return (element as TextElement).text !== undefined;
}

export function isSuppliedElement(element: FillInTheBlankExplanationElement): element is SuppliedElement {
  return (element as SuppliedElement).suppliedAnswer !== undefined;
}

export function isBlankElementExplanation(element: FillInTheBlankExplanationElement): element is BlankElementExplanation {
  return (element as BlankElementExplanation).matchDescription !== undefined;
}

type MatchedAnswer = {
  matchedAnswer: WriteInAnswer;
  description: MatchDescription;
  inOrder: boolean | null;
}

export type MatchDescription = {
  correct: boolean;
  comparison: EditDistance;
};

type EditDistance = {
  difference: number;
  explanation: Edits;
};

type Edits = {
  edits: EditOperation[];
};

export type Substitution = {
  responseStart: number;
  responseEnd: number;
  answerStart: number;
  answerEnd: number;
};

export type Insertion = {
  answerStart: number;
  answerEnd: number;
};

export type Deletion = {
  responseStart: number;
  responseEnd: number;
};

export type EditOperation = Substitution | Insertion | Deletion;

export function isSubstitution(operation: EditOperation): operation is Substitution {
  return (operation as Substitution).responseStart !== undefined &&
         (operation as Substitution).answerStart !== undefined;
}

export function isInsertion(operation: EditOperation): operation is Insertion {
  return (operation as Deletion).responseStart === undefined &&
         (operation as Insertion).answerStart !== undefined;
}

export function isDeletion(operation: EditOperation): operation is Deletion {
  return (operation as Deletion).responseStart !== undefined &&
         (operation as Insertion).answerStart === undefined;
}

export type RetakeVersion = 'all' | 'missed';

export function fullyCorrect(score: NumericScore): boolean {
  return score.points === score.pointsPossible;
}

export function fullyIncorrect(score: NumericScore): boolean {
  return score.points === 0;
}
