import React, { ReactNode, useState } from 'react';
import styles from "./quizQuestionForms.module.scss";
import * as Q from "../questionTypes";
import * as QS from "../QuestionSettings";
import {
  useGlossary as g,
  useGlossaryTemplate as gt,
  useGlossaryTemplateDynamic as gtd
} from "../language";
import * as Z from "../quiz";
import { Hints, Hint } from "../QuestionHints";
import * as E from "../TextEditor";
import { ImageHandleDisplay } from "pictures";
import { QuestionLocation } from "./quizQuestionForms";
import Flag from "Flag";
import ExpansionIndicator from "ExpansionIndicator";

type QuizQuestionBoxProps = {
  question: Z.QuizQuestion;
  questionSlot: ReactNode;
  answerSlot: ReactNode;
  flagged: boolean;
  toggleFlagged: () => void;
  hintsRevealed: number;
  revealNextHint: () => void;
  highlight: boolean;
  location: QuestionLocation;
}

export default function QuizQuestionBox(props: QuizQuestionBoxProps) {
  const displayNumber = `${props.question.index + 1}`;
  const settings = props.question.settings;
  const handles = props.question.images;
  const imageDisplay = <ImageHandleDisplay handles={handles} below={settings.imageAfter}/>;
  const boxClass = styles[props.highlight ? 'highlight-box' : 'quiz-question-box'];
  const widthClass = props.location === 'modal' ? styles['quiz-question-box-modal'] : '';
  const finalClass = `${boxClass} ${widthClass}`;
  const questionSlotClass = props.questionSlot ? styles['question-slot'] : '';
  const questionType = props.question.questionType;
  return (
    <div className={finalClass}>
      <div className={styles['question-descriptor']}>
        <div className={styles['question-number']}>
          {gt('questionNumber', [displayNumber])}
        </div>
        <div className={styles['question-type']}>
          {g(questionType)}
        </div>
      </div>
      <div className={styles['flag-wrapper']}>
        <Flag flagged={props.flagged} onClick={props.toggleFlagged}/>
      </div>
      {!settings.imageAfter && imageDisplay}
      <div className={questionSlotClass}>
        {props.questionSlot}
      </div>
      {settings.imageAfter && imageDisplay}
      <SettingsReminders questionType={props.question.questionType} settings={settings}/>
      <HintReveal
        hints={props.question.hints}
        hintsRevealed={props.hintsRevealed}
        revealNextHint={props.revealNextHint}
      />
      {props.answerSlot}
    </div>
  );
}

type SettingsRemindersProps = {
  questionType: Q.QuestionType;
  settings: QS.QuestionSettingsModel;
}

function SettingsReminders(props: SettingsRemindersProps) {
  const [open, setOpen] = useState(false);
  const applicable = QS.applicableSettingsFields(props.questionType);
  const bloated = QS.bloatedSettingsState(props.settings);
  const applicableCount = QS.ANSWER_INFLUENCING_SETTINGS.filter(setting => applicable.has(setting)).length;
  return applicableCount > 0 ? (
    <div className={styles['settings-reminders']}>
      <div className={styles['settings-reminder-label']} onClick={() => setOpen(!open)}>
        <label className={styles['settings-reminder-label-text']}>{g('settingsReminder')}</label>
        <ExpansionIndicator open={open}/>
      </div>
        {open ? (
          <div>
            {QS.ANSWER_INFLUENCING_SETTINGS.map(setting => (
              <SettingsIndicator
                field={setting}
                applicable={applicable}
                settings={bloated}
              />
            ))}
          </div>
        ) : null}
    </div>
  ) : null;
}

type SettingsIndicatorProps = {
  field: QS.AnswerInfluencingSetting;
  settings: QS.MaximalQuestionSettingsModel;
  applicable: Set<QS.Setting>;
};

function SettingsIndicator(props: SettingsIndicatorProps) {
  const value = props.settings[props.field];
  const activeKey = `${props.field}Active`;
  const passiveKey = `${props.field}Passive`;
  let key = activeKey;
  let parameters: string[] = [`${value}`];
  if (value) {
    key = activeKey;
  } else {
    key = passiveKey;
  }
  return props.applicable.has(props.field) ? (
    <ul className={styles['settings-indicator']}>
      <li>{gtd(key, parameters)}</li>
    </ul>
  ) : null;
}

type HintRevealProps = {
  hints: Hints;
  hintsRevealed: number;
  revealNextHint: () => void;
};

function HintReveal(props: HintRevealProps) {
  return props.hints.hints.length > 0 ? (
    <>
      {props.hints.hints.sort((a, b) => a.position - b.position).map(hint => (
        <HintDisplay
          hint={hint}
          hintsRevealed={props.hintsRevealed}
          revealNextHint={props.revealNextHint}
          totalHints={props.hints.hints.length}
          key={hint.position}
        />
      ))}
    </>
  ) : null;
}

type HintDisplayProps = {
  hint: Hint;
  hintsRevealed: number;
  totalHints: number;
  revealNextHint: () => void;
};

function HintDisplay(props: HintDisplayProps) {
  const hintIndex = props.hint.position + 1;
  const firstHint = hintIndex === 1;
  const revealed = hintIndex <= props.hintsRevealed;
  const moreHints = hintIndex < props.totalHints;
  const lastHintRevealed = hintIndex === props.hintsRevealed;
  const hintDisplayLabelSingle = g('hintDisplayLabelSingle');
  const hintDisplayLabel = gt('hintDisplayLabel', [`${hintIndex}`])
  const hintEnumeration = props.totalHints === 1 ? hintDisplayLabelSingle : hintDisplayLabel;
  const showHint = g('showHint');
  const showNextHint = g('showNextHint');
  const text = (
    <div className={styles['hint-display']}>
      <span className={styles['hint-label']}>
        {hintEnumeration}
      </span>
      <E.TextDisplay value={props.hint.text}/>
    </div>
  );
  if (firstHint && !revealed) {
    return (
      <div className={styles['reveal-hint']} onClick={props.revealNextHint}>
        {showHint}
      </div>
    );
  } else if (revealed && moreHints && lastHintRevealed) {
    return (
      <div className={styles['latest-revealed-hint-row']} onClick={props.revealNextHint}>
        {text}
        <div className={styles['reveal-hint']}>{showNextHint}</div>
      </div>
    );
  } else if (revealed) {
    return (
      <div className={styles['end-hint-row']}>
        {text}
      </div>
    );
  } else {
    return null;
  }
}
