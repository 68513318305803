import { PartialLoadTableVariant } from "./PartialLoadTable";

export type IndexLimits = {
  startIndex: number;
  endIndex: number;
};

export type PaginatedContext = {
  pageSize: number;
  currentPage: number;
};

export type ScrollContext = {
  loadedSoFar: number;
  loadPerScroll: number;
};

export type VariantContext = PaginatedContext | ScrollContext;

function isPaginated(context: VariantContext): context is PaginatedContext {
  return (context as PaginatedContext).pageSize !== undefined;
}

export function displayRangeByVariant(
  variant: PartialLoadTableVariant,
  pageSize: number,
  currentPage: number,
  loadedSoFar: number
): IndexLimits {
  if (variant === 'paginated') {
    return calculateLoadRange({ pageSize, currentPage });
  } else {
    return {
      startIndex: 0,
      endIndex: loadedSoFar
    };
  }
}

export function calculateLoadRangeByVariant(
  variant: PartialLoadTableVariant,
  pageSize: number,
  currentPage: number,
  loadedSoFar: number,
  loadPerScroll: number
): IndexLimits {
  if (variant === 'paginated') {
    return calculateLoadRange({ pageSize, currentPage });
  } else {
    return calculateLoadRange({ loadedSoFar, loadPerScroll });
  }
}

export function calculateLoadRange(context: VariantContext): IndexLimits {
  if (isPaginated(context)) {
    return calculatePaginatedLoadRange(context);
  } else {
    return calculateScrollLoadRange(context);
  }
}

function calculatePaginatedLoadRange(context: PaginatedContext): IndexLimits {
  const { currentPage, pageSize } = context;
  return {
    startIndex: currentPage * pageSize,
    endIndex: (currentPage + 1) * pageSize
  };
}

function calculateScrollLoadRange(context: ScrollContext): IndexLimits {
  const { loadedSoFar, loadPerScroll } = context;
  return {
    startIndex: loadedSoFar,
    endIndex: loadedSoFar + loadPerScroll
  };
}
