import { stringUndoSufficientDifference } from "undo";

export type SimpleEditorState = {
  text: string;
  formula?: boolean;
};

export function constructSimpleEditorState(): SimpleEditorState {
  return {
    text: "",
    formula: false
  };
}

export function textLength(state: SimpleEditorState): number {
  return state.text.length;
}

export function stringRepresentation(state: SimpleEditorState): string {
  return state.text;
}

export function fromString(raw: string): SimpleEditorState {
  return {
    text: raw
  };
}

export function nonEmpty(state: SimpleEditorState): boolean {
  return stringRepresentation(state).length > 0;
}

export function equals(s: SimpleEditorState, t: SimpleEditorState): boolean {
  return s.text === t.text;
}


export function undoSufficientDifference(last: SimpleEditorState, current: SimpleEditorState): boolean {
  return stringUndoSufficientDifference(last.text, current.text);
}