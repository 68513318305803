import React, { ReactNode } from 'react';
import * as Q from "questionTypes";
import { TextDisplay, Content } from "TextEditor";
import { ImageHandleDisplay } from "pictures";
import styles from "./questionBodyDisplay.module.scss";
import { g } from "language";
import { AnswerLabel, AnswerLabelDisplay, OptionalAnswerLabelDisplay } from "labels";

type SelectableAnswerDisplayProps = {
  answer: Q.SelectableAnswer;
  extraClasses?: string;
}

export function SelectableAnswerDisplay(props: SelectableAnswerDisplayProps) {
  const boxClassName = `${props.extraClasses || ""} ${styles['option']}`;
  return (
    <div className={boxClassName}>
      <TextDisplay value={props.answer.content}/>
      <ImageHandleDisplay handles={props.answer.images} below={true}/>
    </div>
  );
}

export type ElementProps = {
  content: Content;
  singleLine: boolean;
};

export function TextElementForm(props: ElementProps) {
  const className = styles[props.singleLine ? 'single-line-text-element' : 'text-element'];
  return (
    <span className={className}>
      <TextDisplay value={props.content}/>
    </span>
  );
}

export function SuppliedElementForm(props: ElementProps) {
  const className = styles[props.singleLine ? 'single-line-supplied-element' : 'supplied-element'];
  return (
    <span className={className}>
      <TextDisplay value={props.content}/>
    </span>
  );
}

type FillInTheBlankOutlineDisplayProps = {
  structure: Q.FillInTheBlank;
  blankResolver: (_: BlankSpecification) => ReactNode;
  additionalClasses?: string;
  singleLine: boolean;
}

export function FillInTheBlankOutlineDisplay(props: FillInTheBlankOutlineDisplayProps) {
  const baseClass = styles[props.singleLine ? 'single-line-fill-in-the-blank-body' : 'fill-in-the-blank-body'];
  const classes = `${baseClass} ${props.additionalClasses || ''}`;
  return (
    <div className={classes}>
      {props.structure.elements.map((element, index) => {
        if (Q.isTextElement(element)) {
          return <TextElementForm content={element.text} key={index} singleLine={props.singleLine}/>
        } else if (Q.isSuppliedElement(element)) {
          return <SuppliedElementForm content={element.suppliedAnswer} key={index} singleLine={props.singleLine}/>
        } else if (Q.isBlankElement(element)) {
          return props.blankResolver({
            blank: element,
            key: index,
            index
          });
        } else {
          return null;
        }
      })}
    </div>
  )
}

export type BlankSpecification = {
  blank: Q.BlankElement,
  index: number,
  key: number
};

type MultiSelectOutlineDisplayProps = {
  correctSlot: ReactNode;
  otherSlot: ReactNode;
};

export function MultiSelectOutlineDisplay(props: MultiSelectOutlineDisplayProps) {
  return (
    <div>
      <AnswerHeader>{g('correctAnswers')}</AnswerHeader>
      {props.correctSlot}
      <AnswerHeader>{g('incorrectAnswers')}</AnswerHeader>
      {props.otherSlot}
    </div>
  )
}

type AnswerHeaderProps = {
  children: ReactNode;
}

export function AnswerHeader(props: AnswerHeaderProps) {
  return <div className={styles['answer-header']}>{props.children}</div>;
}

type MultipleChoiceOutlineDisplayProps = {
  correctSlot: ReactNode;
  otherSlot: ReactNode;
}

export function MultipleChoiceOutlineDisplay(props: MultipleChoiceOutlineDisplayProps) {
  return (
    <div>
      <AnswerHeader>{g('correctAnswer')}</AnswerHeader>
      {props.correctSlot}
      <AnswerHeader>{g('incorrectAnswers')}</AnswerHeader>
      {props.otherSlot}
    </div>
  )
}

type TrueFalseKey = 'true' | 'false';

type TrueFalseOptionVariant = 'correct' | 'incorrect' | 'indefinite';

type TrueFalseOptionProps = {
  textKey: TrueFalseKey;
  selected: boolean;
  variant: TrueFalseOptionVariant;
  onClick: () => void;
};

export function TrueFalseOption(props: TrueFalseOptionProps) {
  const selectionClass = `${props.variant}-selected-true-false-option`;
  const className = styles[props.selected ? selectionClass : 'true-false-option'];
  return (
    <div onClick={props.onClick} className={className}>
      {g(props.textKey)}
    </div>
  );
}

type TrueFalseRowProps = {
  onClick: (_: boolean) => void;
  variant: TrueFalseOptionVariant;
  falseSelected: boolean;
  trueSelected: boolean;
}

export function TrueFalseRow(props: TrueFalseRowProps) {
  return (
    <div className={styles['true-false-options']}>
      <TrueFalseOption
        textKey={'false'}
        onClick={() => props.onClick(false)}
        selected={props.falseSelected}
        variant={props.variant}
      />
      <TrueFalseOption
        textKey={'true'}
        onClick={() => props.onClick(true)}
        selected={props.trueSelected}
        variant={props.variant}
      />
    </div>
  )
}

type QuestionAnswerOutlineProps = {
  question: Content;
  answerSlot: ReactNode;
  additionalClasses?: string;
}

export function QuestionAnswerOutline(props: QuestionAnswerOutlineProps) {
  return (
    <div className={props.additionalClasses || ''}>
      <div className={styles['outline-slot']}>
        <TextDisplay value={props.question}/>
      </div>
      <div className={styles['outline-slot']}>
        {props.answerSlot}
      </div>
    </div>
  )
}

type WriteInAnswerDisplayProps = {
  answer: Q.WriteInAnswer;
  labelType: AnswerLabel;
}

export function WriteInAnswerDisplay(props: WriteInAnswerDisplayProps) {
  return (
    <div className={styles['write-in-answer']}>
      <AnswerLabelDisplay position={props.answer.position} labelType={props.labelType}/>
      <TextDisplay value={props.answer.content}/>
    </div>
  )
}

type OrderingDisplayBodyProps = {
  order: Q.OrderingAnswer[];
  inactivePartitions: number[];
  labelType: AnswerLabel;
};

export function OrderingDisplayBody(props: OrderingDisplayBodyProps) {
  const inactivePartitions = new Set(props.inactivePartitions);
  inactivePartitions.add(props.order.length - 1);
  return (
    <div>
      {props.order.sort((a, b) => a.position - b.position).map(answer => (
        <>
          <OrderingAnswerDisplay
            answer={answer}
            labelType={props.labelType}
          />
          <OrderingPartition active={!inactivePartitions.has(answer.position)}/>
        </>
      ))}
    </div>
  );
}

type OrderingAnswerDisplayProps = {
  answer: Q.OrderingAnswer;
  labelType: AnswerLabel | null;
  positionOverride?: number;
  extraClasses?: string;
};

export function OrderingAnswerDisplay(props: OrderingAnswerDisplayProps) {
  const position = props.positionOverride === undefined ? props.answer.position : props.positionOverride;
  const className = `${props.extraClasses || ""} ${styles['ordering-answer']}`;
  return (
    <div className={className}>
      <OptionalAnswerLabelDisplay position={position} labelType={props.labelType}/>
      <TextDisplay value={props.answer.content}/>
    </div>
  )
}

type OrderingPartitionProps = {
  active: boolean;
};

export function OrderingPartition(props: OrderingPartitionProps) {
  return props.active ? <div className={styles['ordering-partition']}></div> : null;
}
