import { Ref, useEffect, ReactNode } from 'react';

export default function useFocus(ref: Ref<HTMLElement>, conditional: boolean = true) {
  useEffect(() => {
    // @ts-ignore
    if (ref && ref.current && conditional) {
      //@ts-ignore
      ref.current.focus();
    }
  }, [ref]);
}
