import { ReactNode, useState, useRef } from "react";
import styles from "./SingleSelect.module.scss";
import useClosable from "closable";

type IdentifiedElement<T> = {
  id: T;
  element: JSX.Element;
};

type HeaderFromEntries<T> = {
  elementId: T
};

type DynamicHeader = {
  display: JSX.Element;
};

type SelectHeader<T> = HeaderFromEntries<T> | DynamicHeader;

function isFromEntries<T>(header: SelectHeader<T>): header is HeaderFromEntries<T> {
  return (header as HeaderFromEntries<T>).elementId !== undefined;
}

function isDynamic<T>(header: SelectHeader<T>): header is DynamicHeader {
  return (header as DynamicHeader).display !== undefined;
}

type SingleSelectProps<T> = {
  entries: IdentifiedElement<T>[];
  onSelect: (_: T) => void;
  header: SelectHeader<T>;
  additionalClasses?: string;
  label?: string;
};

export default function SingleSelect<T>(props: SingleSelectProps<T>) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClosable(ref, () => setOpen(false));
  const classes = `${styles['wrapper']} ${props.additionalClasses || ""}`;
  return (
    <div className={classes} ref={ref}>
      <label>{props.label}</label>
      <SingleSelectHeader
        header={props.header}
        entries={props.entries}
        onClick={() => setOpen(!open)}
        open={open}
      />
      {open ? (
        <div className={styles['entries']}>
          {props.entries.map((entry, index) => (
            <SingleSelectRow
              onClick={id => {
                props.onSelect(id);
                setOpen(false);
              }}
              entry={entry}
              key={index}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

type SingleSelectHeaderProps<T> = {
  header: SelectHeader<T>;
  entries: IdentifiedElement<T>[];
  onClick: () => void;
  open: boolean;
};

function SingleSelectHeader<T>(props: SingleSelectHeaderProps<T>): JSX.Element {
  var contents = <></>;
  if (isFromEntries(props.header)) {
    const fromEntries = props.header as HeaderFromEntries<T>;
    const correspondingEntry = props.entries.find(entry => entry.id === fromEntries.elementId);
    contents = correspondingEntry?.element || <></>;
  } else if (isDynamic(props.header)) {
    const dynamic = props.header as DynamicHeader;
    contents = dynamic.display;
  }
  const className = styles[props.open ? 'open-header' : 'header'];
  return (
    <div className={className} onClick={props.onClick}>
      {contents}
    </div>
  );
}

type SingleSelectRowProps<T> = {
  entry: IdentifiedElement<T>;
  onClick: (_: T) => void;
}

function SingleSelectRow<T>(props: SingleSelectRowProps<T>) {
  return (
    <div className={styles['single-select-row']} onClick={() => props.onClick(props.entry.id)}>
      {props.entry.element}
    </div>
  );
}
