import { ReactElement, cloneElement } from 'react';
import { BsPlusSquare, BsFileText, BsSearch, BsPatchQuestion } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';

type IconProps = {
  className?: string;
}

export function NewQuestionIcon(props: IconProps) {
  return GenericIcon(<BsPlusSquare/>, props);
}

export function NewQuizIcon(props: IconProps) {
  return GenericIcon(<BsFileText/>, props);
}

export function QuestionSearchIcon(props: IconProps) {
  return GenericIcon(<BsSearch/>, props);
}

export function UserIcon(props: IconProps) {
  return GenericIcon(<FaUserCircle/>, props);
}

export function QuestionsIcon(props: IconProps) {
  return GenericIcon(<BsPatchQuestion/>, props);
}

function GenericIcon(icon: ReactElement, props: IconProps) {
  return cloneElement(icon, {
    className: props.className || "",
    ...props
  });
}
