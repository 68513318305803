export function zip<A, B>(as: A[], bs: B[]): Array<[A, B]> {
  let ziplist: Array<[A, B]> = [];
  const length = Math.min(as.length, bs.length);
  for (let i = 0; i < length; i++) {
    ziplist.push([as[i], bs[i]]);
  }
  return ziplist;
}

export function zip3<A, B, C>(as: A[], bs: B[], cs: C[]): Array<[A, B, C]> {
  let ziplist: Array<[A, B, C]> = [];
  const length = Math.min(as.length, Math.min(bs.length, cs.length));
  for (let i = 0; i < length; i++) {
    ziplist.push([as[i], bs[i], cs[i]]);
  }
  return ziplist;
}

export function indexOfOrLength(s: string, ss: string): number {
  const ssindex = s.indexOf(ss);
  return ssindex === -1 ? s.length : ssindex;
}

export function indexDefault<T, D>(ts: T[], index: number, def: D): T | D {
  if (index < 0 || index >= ts.length) {
    return def;
  } else {
    return ts[index];
  }
}

export function indexDefaultNull<T>(ts: T[], index: number): T | null {
  return indexDefault(ts, index, null);
}

export function parseIntNullable(s: string | null): number | null {
  if (s === null) {
    return null
  }
  return parseInt(s, 10);
}

export function range(low: number, high: number): number[] {
  let arrayOfNumbersFromLowToHigh = [];
  for (let i = low; i < high; i++) {
    arrayOfNumbersFromLowToHigh.push(i);
  }
  return arrayOfNumbersFromLowToHigh;
}

export function mod(base: number, modulo: number): number {
  return ((base % modulo) + modulo) % modulo;
}

export function setToList<T>(s: Set<T>): T[] {
  return Array.from(s.values());
}
