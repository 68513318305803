import styles from "./AbsenceIndicator.module.scss";
import { g, GlossaryKey } from "language";

type AbsenceIndicatorProps = {
  glossaryKey: GlossaryKey;
  additionalClasses?: string;
}

export default function AbsenceIndicator(props: AbsenceIndicatorProps) {
  const className = `${styles['absence-indicator']} ${props.additionalClasses || ""}`
  return <div className={className}>{g(props.glossaryKey)}</div>
}
