import { listUndoSufficientDifference } from "undo";
import { v4 } from "uuid";

export type TagColor = {
  red: number;
  green: number;
  blue: number;
};

export type TagDisplay = {
  textColor: TagColor;
  backgroundColor: TagColor;
};

export type TagBody = {
  text: string;
  display?: TagDisplay;
};

export type Tag = {
  tagId: string;
  body: TagBody;
};

export type TagEnvelope = {
  tags: Tag[];
};

export function tagsEqual(a: Tag, b: Tag): boolean {
  return a === b;
}

export function tagListEqual(a: string[], b: string[]): boolean {
  if (a.length === b.length) {
    const asorted = a.sort();
    const bsorted = b.sort();
    return asorted.reduce((equal, atag, index) => {
      const btag = bsorted[index];
      return equal && atag === btag;
    }, true as boolean);
  } else {
    return false;
  }
}

export function tagListUndoSufficientDifference(a: string[], b: string[]): boolean {
  return listUndoSufficientDifference(a, b, (a_tag, b_tag) => {
    return a_tag !== b_tag;
  });
}

export function newTagFromName(name: string): Tag {
  return {
    tagId: v4(),
    body: {
      text: name
    }
  };
}
