import { useState, useEffect } from 'react';
import Toggle from "commonComponents/Toggle";
import { GlossaryKey, g } from "language";
import styles from "./AllOrSomeSelect.module.scss";
import CheckBox from "commonComponents/CheckBox";
import { defaultBoolean } from "logicUtils";

type AllOrSomeSelectProps<K> = {
  options: readonly K[];
  selectedOptions: Set<K>;
  setSelectedOptions: (_: Set<K>) => void;
  localGlossary: (_: K) => GlossaryKey;
  initialAll?: boolean;
  disabled?: boolean;
};

export default function AllOrSomeSelect<K>(props: AllOrSomeSelectProps<K>) {
  const disabled = defaultBoolean(props.disabled, false);
  const [all, setAll] = useState(defaultBoolean(props.initialAll, true));
  const [disregardAllSelected, setDisregardAllSelected] = useState<Set<K>>(props.selectedOptions);
  useEffect(() => {
    if (all) {
      props.setSelectedOptions(new Set(props.options));
    } else {
      props.setSelectedOptions(new Set(disregardAllSelected));
    }
  }, []);
  const clickAll = () => {
    if (all) {
      props.setSelectedOptions(new Set(disregardAllSelected));
    } else {
      props.setSelectedOptions(new Set(props.options));
    }
    setAll(!all);
  };
  return (
    <div className={styles['options-list']}>
      <div className={styles['all-toggle-wrapper']}>
        <label className={disabled ? styles['disabled-label'] : ''}>{g('selectAll')}</label>
        <Toggle on={all} onClick={clickAll} color="blue" disabled={props.disabled}/>
      </div>
      {props.options.map(option => (
        <SelectChiclet
          visiblySelected={disregardAllSelected.has(option)}
          allSelected={all}
          onClick={() => {
            if (!all) {
              const selectedCopy = new Set(disregardAllSelected);
              if (selectedCopy.has(option)) {
                selectedCopy.delete(option);
              } else {
                selectedCopy.add(option);
              }
              props.setSelectedOptions(selectedCopy);
              setDisregardAllSelected(selectedCopy);
            }
          }}
          textKey={props.localGlossary(option)}
          disabled={disabled}
          key={props.localGlossary(option)}
        />
      ))}
    </div>
  );
}

type SelectChicletProps = {
  visiblySelected: boolean;
  allSelected: boolean;
  onClick: () => void;
  textKey: GlossaryKey;
  disabled: boolean;
}

function SelectChiclet(props: SelectChicletProps) {
  const selectedClass = styles[props.visiblySelected ? 'selected-chiclet' : 'unselected-chiclet'];
  const overridden = props.allSelected || props.disabled;
  const overriddenClass = overridden ? styles['overridden'] : "";
  const className = `${selectedClass} ${overriddenClass}`;
  const checkboxClasses = props.visiblySelected && overridden ? styles['overridden-selected-checkbox'] : '';
  return (
    <div className={className} onClick={props.onClick}>
      <CheckBox selected={props.visiblySelected} additionalClasses={checkboxClasses}/>
      <div className={styles['option-text']}>{g(props.textKey)}</div>
    </div>
  )
}
