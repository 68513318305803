import React from 'react';
import { useGlossary as g } from "../language";
import Info from "Info";

export default function HintInfo() {
  return (
    <Info title={g('hintInfoHeader')}>
      <>{g('hintExplanation')}</>
    </Info>
  );
}
