import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as Z from "quiz";
import { useUser } from "user";
import { retrieveQuiz, constructSocket, cancelQuiz, submitQuiz } from "network";
import { QuestionLocation } from "quizQuestionForms";
import styles from "./QuizPage.module.scss";
import { g, gt } from "language";
import QuizDescriptor from "./QuizDescriptor";
import { resolveProgress, saveOnUpdate, SaveQuiz, manualSave } from "./saveProgress";
import { ResponseState, FlaggedState, HintsRevealedState } from "./auxiliary";
import { FiCheck } from "react-icons/fi";
import Modal from "Modal";
import { Link, useNavigate } from "react-router-dom";
import ActiveQuizMessage from "ActiveQuizMessage";
import { useQuizContext } from "quizContext";
import LowerBar from "LowerBar";
import { functionalityByType } from "questionTypes";

export default function QuizPage() {
  const quizId = useParams().quiz_id;
  const navigate = useNavigate();
  const quizContext = useQuizContext();
  const [quiz, setQuiz] = useState<Z.Quiz | null>(null);
  const [responses, setResponses] = useState<ResponseState>(new Map());
  const [flagged, setFlagged] = useState<FlaggedState>(new Set());
  const [hintsRevealed, setHintsRevealed] = useState<HintsRevealedState>(new Map());
  const userContext = useUser();
  // const [socket] = useState(constructSocket((event: MessageEvent) => {}));
  const [save, setSave] = useState<SaveQuiz>(() => () => {});
  const [saveState, setSaveState] = useState<SaveState>('before-change');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  useEffect(() => {
    (async () => {
      if (userContext.user) {
        const quizResult = await retrieveQuiz(quizId);
        setSave(() => saveOnUpdate(
          userContext.user!.userId(),
          quizId,
          quizResult.questions,
          () => setSaveState('saved')
        ));
        setQuiz(quizResult);
        const rawProgress = resolveProgress(quizId, quizResult.progress);
        if (rawProgress) {
          const progress = Z.actionableProgress(rawProgress.progress);
          setResponses(Z.initializeResponsesWithPriorProgress(quizResult.questions, progress.responses));
          setFlagged(progress.flagged);
          setHintsRevealed(progress.hints);
        } else {
          setResponses(Z.initializeResponses(quizResult.questions));
        }
      }
    })();
  }, [userContext.user.userId(), quizId]);
  const saveWithState = (r: ResponseState, f: FlaggedState, h: HintsRevealedState) => {
    setSaveState('can-save');
    console.log("resetting to can-save");
    save(r, f, h);
  };
  const updateResponse = (index: number, response: Z.Response) => {
    responses.set(index, response);
    const updatedResponses = new Map(responses);
    saveWithState(updatedResponses, flagged, hintsRevealed);
    setResponses(updatedResponses);
  };
  const getResponse = (index: number) => {
    return responses.get(index);
  }
  const updateFlaggedQuestions = (questionId: string) => {
    if (flagged.has(questionId)) {
      flagged.delete(questionId);
    } else {
      flagged.add(questionId);
    }
    const updatedFlagged = new Set(flagged);
    saveWithState(responses, updatedFlagged, hintsRevealed);
    setFlagged(updatedFlagged);
  }
  const getHintsRevealed = (index: number) => {
    return hintsRevealed.get(index) || 0;
  };
  const revealNextHint = (index: number) => {
    hintsRevealed.set(index, (hintsRevealed.get(index) || 0) + 1);
    const updatedHintsRevealed = new Map(hintsRevealed);
    saveWithState(responses, flagged, updatedHintsRevealed);
    setHintsRevealed(updatedHintsRevealed);
  }
  const cancel = () => {
    if (userContext.user) {
      cancelQuiz(quizId);
      if (quiz) {
        quizContext.setQuizId(null);
        // socket.close();
        setQuiz({
          ...quiz,
          canceled: true
        });
      }
    }
  };
  const header = gt('quizHeader', [quiz?.ordinal || ""]);
  if (quizContext.quizId === null && quiz?.canceled) {
    return <CanceledQuiz/>;
  }
  const unattempted = Z.responsesNotAttempted(responses);
  const mainPageUnattempted = submitAttempted ? new Set(unattempted) : new Set<number>();
  const submit = () => {
    if (quiz && userContext.user) {
      const submission = Z.quizSubmission(responses, flagged, hintsRevealed, quiz.questions);
      submitQuiz(quizId, submission)
      .then(result => {
        // socket.close();
        if (result.status < 300) {
          quizContext.setQuizId(null);
          navigate(`/quiz-results/${quizId}`);
        } else {
          alert(JSON.stringify(result.status));
        }
      })
    }
  };
  return quiz && responses.size === quiz.questions.length ? (
    <div className={styles['quiz-page']}>
      <h1>{header}</h1>
      <QuizDescriptor settings={quiz.settings}/>
      <div className={styles['main-body']}>
        <QuestionsSection
          updateResponse={updateResponse}
          getResponse={getResponse}
          questions={quiz.questions}
          flagged={flagged}
          toggleFlagged={updateFlaggedQuestions}
          getHintsRevealed={getHintsRevealed}
          revealNextHint={revealNextHint}
          unattempted={mainPageUnattempted}
          location={'page'}
        />
      </div>
      <ButtonBar
        onSave={() => {
          if (userContext.user && quiz?.questions.length) {
            setSaveState('saved');
            manualSave(userContext.user.userId(), quizId, responses, flagged, hintsRevealed, quiz.questions);
          }
        }}
        saveState={saveState}
        onCancel={cancel}
        onSubmit={submit}
        updateResponse={updateResponse}
        getResponse={getResponse}
        questions={quiz.questions}
        flagged={flagged}
        toggleFlagged={updateFlaggedQuestions}
        getHintsRevealed={getHintsRevealed}
        revealNextHint={revealNextHint}
        unattemptedIndices={unattempted}
        indicateSubmitAttempted={() => setSubmitAttempted(true)}
      />
    </div>
  ) : null;
}

export type ResponseStateProps = {
  updateResponse: (_: number, __: Z.Response) => void;
  getResponse: (_: number) => Z.Response | undefined;
};

type QuestionSectionBasicProps = ResponseStateProps & {
  questions: Z.QuizQuestion[];
  flagged: Set<string>;
  toggleFlagged: (_: string) => void;
  getHintsRevealed: (_: number) => number;
  revealNextHint: (_: number) => void;
};

type QuestionsSectionProps = QuestionSectionBasicProps & {
  unattempted: Set<number>;
  location: QuestionLocation;
}

function QuestionsSection(props: QuestionsSectionProps) {
  return (
    <div className={styles['questions-section']}>
      {props.questions.sort((a, b) => a.index - b.index).map(question => {
        const flagged = props.flagged.has(question.questionId);
        const toggleFlagged = () => {
          props.toggleFlagged(question.questionId);
        };
        const hintsRevealed = props.getHintsRevealed(question.index);
        const revealNextHint = () => {
          props.revealNextHint(question.index);
        }
        return functionalityByType(question.questionType).quizForm({
            question: question,
            updateResponse: props.updateResponse,
            getResponse: props.getResponse,
            flagged: flagged,
            toggleFlagged: toggleFlagged,
            revealNextHint: revealNextHint,
            hintsRevealed: hintsRevealed,
            highlight: props.unattempted.has(question.index),
            location: props.location
        });
      })}
    </div>
  );
}

type SaveState = 'before-change' | 'saved' | 'can-save';

type ButtonBarProps = SaveButtonProps & CancelButtonProps & SubmitButtonProps;

function ButtonBar(props: ButtonBarProps) {
  return (
    <LowerBar fullPage={true}>
      <div className={styles['inner-bar']}>
        <div className={styles['left-bar']}>
          <CancelButton onCancel={props.onCancel}/>
        </div>
        <div className={styles['right-bar']}>
          <SaveButton onSave={props.onSave} saveState={props.saveState}/>
          <SubmitButton
            onSubmit={props.onSubmit}
            updateResponse={props.updateResponse}
            getResponse={props.getResponse}
            questions={props.questions}
            flagged={props.flagged}
            toggleFlagged={props.toggleFlagged}
            getHintsRevealed={props.getHintsRevealed}
            revealNextHint={props.revealNextHint}
            unattemptedIndices={props.unattemptedIndices}
            indicateSubmitAttempted={props.indicateSubmitAttempted}
          />
        </div>
      </div>
    </LowerBar>
  )
}

type SaveButtonProps = {
  onSave: () => void;
  saveState: SaveState;
}

function SaveButton(props: SaveButtonProps) {
  switch (props.saveState) {
    case 'before-change':
      return null;
    case 'saved':
      return (
        <div className={styles['saved-indicator']}>
          <FiCheck className={styles['saved-check']}/>
          <span>{g('quizSaved')}</span>
        </div>
      );
    case 'can-save':
      return (
        <button className={styles['save-button']} onClick={props.onSave}>
          {g('saveQuizButton')}
        </button>
      );
  }
}

type CancelButtonProps = {
  onCancel: () => void;
}

function CancelButton(props: CancelButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <Modal visible={modalVisible} setVisible={setModalVisible}>
        <div className={styles['modal-header']}>{g('cancelQuizHeader')}</div>
        <p>{g('cancelQuizText')}</p>
        <div className={styles['cancel-modal-button-bar']}>
          <button className={styles['dont-cancel-button']} onClick={() => setModalVisible(false)}>
            {g('avoidCancelButton')}
          </button>
          <button className={styles['final-cancel-button']} onClick={props.onCancel}>
            {g('cancelQuizButton')}
          </button>
        </div>
      </Modal>
      <button className={styles['cancel-button']} onClick={() => setModalVisible(true)}>
        {g('cancelQuizButton')}
      </button>
    </>
  )
}

type SubmitButtonProps = QuestionSectionBasicProps & {
  onSubmit: () => void;
  unattemptedIndices: number[];
  indicateSubmitAttempted: () => void;
}

function SubmitButton(props: SubmitButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalIndices, setModalIndices] = useState<number[]>([]);
  const submitAttempt = () => {
    if (props.unattemptedIndices.length > 0) {
      props.indicateSubmitAttempted();
      setModalIndices(props.unattemptedIndices);
      setModalVisible(true);
    } else {
      props.onSubmit();
    }
  }
  const unattempted = new Set(modalIndices);
  const relevantQuestions = props.questions.filter(question => unattempted.has(question.index));
  return (
    <>
      <Modal visible={modalVisible} setVisible={setModalVisible}>
        <div className={styles['modal-header']}>{g('submitQuizHeader')}</div>
        <p className={styles['unattempted-text']}>{g('unattemptedQuestions')}</p>
        <div className={styles['modal-unattempted']}>
          <QuestionsSection
            updateResponse={props.updateResponse}
            getResponse={props.getResponse}
            questions={relevantQuestions}
            flagged={props.flagged}
            toggleFlagged={props.toggleFlagged}
            getHintsRevealed={props.getHintsRevealed}
            revealNextHint={props.revealNextHint}
            unattempted={unattempted}
            location={'modal'}
          />
        </div>
        <div className={styles['submit-modal-buttons']}>
          <button className={styles['avoid-submit-button']} onClick={() => setModalVisible(false)}>
            {g('avoidSubmitButton')}
          </button>
          <button className={styles['submit-now-button']} onClick={props.onSubmit}>
            {g('submitNowButton')}
          </button>
        </div>
      </Modal>
      <button className={styles['quiz-submit-button']} onClick={submitAttempt}>
        {g('submitQuizButton')}
      </button>
    </>
  )
}

function CanceledQuiz() {
  return (
    <>
      <h2>{g('quizCanceled')}</h2>
      <div className={styles['canceled-quiz-links']}>
        <Link to="/new-quiz">{g('makeNewQuiz')}</Link>
        <Link to="/user-home-page">{g('backToHome')}</Link>
      </div>
    </>
  );
}
