import { MouseEvent } from 'react';
import { GlossaryKey, g } from "language";
import styles from "./SortSpecifier.module.scss";
import { SortPriority, togglePriority } from "./priority";
import Toggle from "commonComponents/Toggle";

type SortSpecifierProps = {
  sortDescriptorKey: GlossaryKey;
  decreasingKey: GlossaryKey;
  increasingKey: GlossaryKey;
  selected: boolean;
  priority: SortPriority;
  setPriority: (_: SortPriority) => void;
  onClick: () => void;
}

export default function SortSpecifier(props: SortSpecifierProps) {
  const className = styles[props.selected ? 'selected-sort-specifier' : 'deselected-sort-specifier'];
  const priorityAffectingOnClick = (priority: SortPriority | null) => {
    props.onClick();
    if (priority !== null) {
      props.setPriority(priority);
    }
  };
  return (
    <div className={className}>
      <div onClick={() => priorityAffectingOnClick(null)} className={styles['attribute-indicator']}>
        {g(props.sortDescriptorKey)}
      </div>
      <div className={styles['priority-selector']}>
        <div onClick={() => priorityAffectingOnClick('least')} className={styles['increasing']}>
          {g(props.increasingKey)}
        </div>
        <Toggle
          on={props.priority === 'greatest'}
          onClick={() => priorityAffectingOnClick(togglePriority(props.priority))}
        />
        <div onClick={() => priorityAffectingOnClick('greatest')} className={styles['decreasing']}>
          {g(props.decreasingKey)}
        </div>
      </div>
    </div>
  );
}
