export function fromMaybe<T>(maybe: T | undefined | null, def: T): T {
  return maybe === undefined || maybe === null ? def : maybe;
}

export function setToggle<T>(value: T, set: Set<T>): Set<T> {
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
  return set;
}

export function defaultBoolean(b: boolean | undefined, defaultValue: boolean): boolean {
  return b === undefined ? defaultValue : b;
}

export function typedKeys<T extends object>(t: T): (keyof T)[] {
  return Object.keys(t).sort() as (keyof T)[];
}
