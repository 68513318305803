export default class LocalStorageCache<T extends object> {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  save(payload: T) {
    localStorage.setItem(this.key, JSON.stringify(payload));
  }

  get(): T | null {
    const gotten = localStorage.getItem(this.key);
    if (!gotten) {
      return null;
    }
    return JSON.parse(gotten);
  }
}
