import { ReactElement, useState } from 'react';
import styles from "./PartialLoadTable.module.scss";
import SingleSelect from "commonComponents/SingleSelect";
import { g, gt, gpc } from 'language';
import isMobile from "responsive";
import { IoEllipsisHorizontal } from 'react-icons/io5';
import LoadingIndicator from "commonComponents/LoadingIndicator";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";
import { useScrollableBottom } from "scrollable";
import { INFINITE_SCROLL_LOAD_COUNT } from "./constants";

export type PartialLoadTableVariant = 'infinite-scroll' | 'paginated';

type RowCountProps = {
  totalRowCount: number;
}

type GeneralTableProps = {
  headerRow: ReactElement;
  rows: ReactElement[] | null;
  headerClasses?: string;
} & RowCountProps;

export type PartialLoadTableProps = {
  variant: PartialLoadTableVariant;
} & PaginatedTableSpecificProps & ScrollTableSpecificProps & GeneralTableProps;

export default function PartialLoadTable(props: PartialLoadTableProps) {
  switch (props.variant) {
    case 'paginated': return (
      <PaginatedTable
        headerRow={props.headerRow}
        rows={props.rows}
        pageSize={props.pageSize}
        pageSizeOptions={props.pageSizeOptions}
        setPageSize={props.setPageSize}
        totalRowCount={props.totalRowCount}
        headerClasses={props.headerClasses}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
      />
    );
    case 'infinite-scroll': return (
      <ScrollTable
        scrollLoadCount={props.scrollLoadCount}
        headerRow={props.headerRow}
        rows={props.rows}
        totalRowCount={props.totalRowCount}
        headerClasses={props.headerClasses}
        onFullScroll={props.onFullScroll}
        scrollLoadedSoFar={props.scrollLoadedSoFar}
        targetScrollLoads={props.targetScrollLoads}
      />
    );
  }
}

type PaginatedTableSpecificProps = {
  pageSize: number;
  pageSizeOptions?: number[];
  setPageSize: (_: number) => void;
  currentPage: number;
  setCurrentPage: (_: number) => void;
};

type PaginatedTableProps = PaginatedTableSpecificProps & GeneralTableProps;

function PaginatedTable(props: PaginatedTableProps) {
  const customizedStyling = props.headerClasses || styles['default-header-display'];
  const className = `${styles['table-header']} ${customizedStyling}`;
  const noResultsText = g('noResults');
  return (
    <div>
      <div className={className}>
        <PaginationNavigator {...props}/>
        {props.headerRow}
      </div>
      <div>
        {props.rows ? (
          props.rows.length === 0 ? (
            <div className={styles['no-results']}><AbsenceIndicator glossaryKey={'noResults'}/></div>
          ) : props.rows
        ) : <LoadingIndicator additionalClasses={styles['loading-rows']}/>}
      </div>
    </div>
  );
}

type PaginationNavigatorProps = PaginatedTableSpecificProps & RowCountProps;

function PaginationNavigator(props: PaginationNavigatorProps) {
  return (
    <div className={styles['pagination-navigator']}>
      <div className={styles['total-row-indicator']}>{gpc('totalRowCount', props.totalRowCount)}</div>
      <PageSizeSelector {...props}/>
      <PageSelector
        pageSize={props.pageSize}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        totalRowCount={props.totalRowCount}
      />
    </div>
  );
}

type PageSizeSelectorProps = PaginatedTableSpecificProps;

function PageSizeSelector(props: PageSizeSelectorProps) {
  const pageSizeOptions = props.pageSizeOptions || [10, 20, 50, 100];
  const setPageSize = (newSize: number) => {
    props.setPageSize(newSize);
    const lowIndex = props.pageSize * props.currentPage;
    const lowContained = Math.floor(lowIndex / newSize);
    props.setCurrentPage(lowContained);
  };
  return (
    <div className={styles['page-size-selector']}>
      <SingleSelect
        entries={pageSizeOptions.map(size => ({
          id: size,
          element: <>{size}</>
        }))}
        onSelect={setPageSize}
        header={{
          display: (
            <span className={styles['page-size-header']}>{gt('tableRowCountIndicator', [props.pageSize])}</span>
          )
        }}
        additionalClasses={styles['page-size-selector-internal']}
      />
    </div>
  );
}

type PageSelectorProps = {
  pageSize: number;
  currentPage: number;
  setCurrentPage: (_: number) => void;
} & RowCountProps;

function PageSelector(props: PageSelectorProps) {
  const pageCount = Math.ceil(props.totalRowCount / props.pageSize);
  const maxPage = pageCount - 1;
  const viewablePageRadius = isMobile() ? 2 : 5;
  const totalPagesVisible = viewablePageRadius * 2 + 1;
  const naiveMinVisiblePage = Math.max(0, props.currentPage - viewablePageRadius);
  const naiveMaxVisiblePage = Math.min(maxPage, props.currentPage + viewablePageRadius + 1);
  const unrealizedPages = totalPagesVisible - (naiveMaxVisiblePage - naiveMinVisiblePage);
  const minVisiblePage = naiveMinVisiblePage === 0 ? naiveMinVisiblePage : naiveMinVisiblePage - unrealizedPages;
  const maxVisiblePage = naiveMaxVisiblePage === maxPage ? naiveMaxVisiblePage : naiveMaxVisiblePage + unrealizedPages;
  const lowerEllipsisPresent = minVisiblePage > 1;
  const higherEllipsisPresent = maxVisiblePage < maxPage - 1;
  const pageLinks: JSX.Element[] = [];
  for (let i = Math.max(minVisiblePage, 1); i < Math.min(maxVisiblePage, maxPage); i++) {
    pageLinks.push(
      <PageLinkOrIndicator
        onClick={() => props.setCurrentPage(i)}
        page={i}
        currentPage={props.currentPage}
        key={i}
      />
    );
  }
  return (
    <div className={styles['page-selector']}>
      <PageLinkOrIndicator
        onClick={() => props.setCurrentPage(0)}
        page={0}
        currentPage={props.currentPage}
      />
      <PageEllipsis present={lowerEllipsisPresent}/>
      {pageLinks}
      <PageEllipsis present={higherEllipsisPresent}/>
      {pageCount > 1 ? (
        <PageLinkOrIndicator
          onClick={() => props.setCurrentPage(maxPage)}
          page={maxPage}
          currentPage={props.currentPage}
        />
      ) : null}
    </div>
  );
}

type PageLinkOrIndicatorProps = {
  onClick: () => void;
  page: number;
  currentPage: number;
};

function PageLinkOrIndicator(props: PageLinkOrIndicatorProps) {
  const isCurrentPage = props.page === props.currentPage;
  const pageNumberDisplay = props.page + 1;
  return (
    <div className={styles['page-indicator-wrapper']}>
      {isCurrentPage ? (
        <span className={styles['current-page-indicator']}>{pageNumberDisplay}</span>
      ) : (
        <span className={styles['page-link']} onClick={props.onClick}>{pageNumberDisplay}</span>
      )}
    </div>
  );
}

type PageEllipsisProps = {
  present: boolean;
}

function PageEllipsis(props: PageEllipsisProps) {
  return props.present ? (
    <IoEllipsisHorizontal className={styles['page-ellipsis']}/>
  ) : null;
}

type ScrollTableSpecificProps = {
  scrollLoadCount?: number;
  onFullScroll: () => void;
  scrollLoadedSoFar: number;
  targetScrollLoads: number;
};

type ScrollTableProps = ScrollTableSpecificProps & GeneralTableProps;

function ScrollTable(props: ScrollTableProps) {
  const scrollLoadCount = props.scrollLoadCount || INFINITE_SCROLL_LOAD_COUNT;
  const loadedSoFar = props.rows?.length || 0;
  const loading = props.scrollLoadedSoFar < props.targetScrollLoads * scrollLoadCount;
  useScrollableBottom(() => {
    if (!loading && loadedSoFar < props.totalRowCount) {
      props.onFullScroll();
    }
  }, [loadedSoFar, props.totalRowCount]);
  return (
    <div>
      {props.rows}
      {loading && <LoadingIndicator additionalClasses={styles['scroll-loading-indicator']}/>}
    </div>
  );
}
