import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from "./NavBar.module.scss";
import { useUserOptional } from "../user";
import { signOut } from "../network";
import { GiHamburgerMenu } from "react-icons/gi";
import isMobile from "../responsive";
import * as I from "../Icons";
import useClosable from "../closable";
import { useQuizContext } from "../quizContext";
import { g } from "language";

export default function NavBar() {
  const userContext = useUserOptional();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const quizContext = useQuizContext();
  return (
    <nav className={styles["nav-bar"]}>
      <div className={styles["left-nav-section"]}>
        <Link to="/" className={styles["quiz-zone-icon"]}>
          <span className={styles["quiz-zone-icon-text"]}>{g('homePageLink')}</span>
        </Link>
        {userContext.user && !quizContext.quizId && (
          <ResponsiveLinkSection setSidebarOpen={() => setSidebarOpen(!sidebarOpen)}/>
        )}
      </div>
      <AuthenticationButton/>
      {sidebarOpen ? <SideBar close={() => setSidebarOpen(false)}/> : null}
    </nav>
  )
}

function AuthenticationButton() {
  const quizContext = useQuizContext();
  const userContext = useUserOptional();
  const [userPresent, setUserPresent] = useState(!!userContext.user);
  const signUpSignInMessage = g('authenticateInMessage');
  const signOutMessage = g('signOutMessage');
  useEffect(() => {
    setUserPresent(!!userContext.user);
  }, [userContext.user]);
  return (
    <div>
      {userContext.user ?
        <button className={styles['sign-out']} onClick={() => {
          userContext.setUser(null);
          quizContext.setQuizId(null);
          signOut();
        }}>{signOutMessage}</button> :
        <Link to="/sign-up" className={styles["sign-in"]}>{signUpSignInMessage}</Link>
      }
    </div>
  )
}

type ResponsiveLinkSectionProps = {
  setSidebarOpen: () => void;
}

function ResponsiveLinkSection(props: ResponsiveLinkSectionProps) {
  return isMobile() ? (
    <GiHamburgerMenu
      className={styles['mobile-menu']}
      onClick={props.setSidebarOpen}
    />
  ) : (
    <TopNavbarLinks/>
  );
}

function TopNavbarLinks() {
  return (
      <LinkList close={() => {}}/>
  )
}

type SideBarProps = {
  close: () => void;
}

function SideBar(props: SideBarProps) {
  const ref = useRef<HTMLElement>(null);
  useClosable(ref, props.close);
  const linkClass = styles['side-bar-link'];
  const iconClass = styles['side-bar-icon'];
  return (
    <nav ref={ref} className={styles['side-bar']}>
      <LinkList close={props.close}/>
    </nav>
  )
}

type NavbarLinkProps = {
  close: () => void;
}

function LinkList(props: NavbarLinkProps) {
  return (
    <>
      <HomePageLink close={props.close}/>
      <NewQuestionLink close={props.close}/>
      <NewQuizLink close={props.close}/>
      <QuestionsLink close={props.close}/>
    </>
  )
}

function linkClass() {
  return styles['side-bar-link'];
}

function iconClass() {
  return styles['side-bar-icon'];
}

function HomePageLink(props: NavbarLinkProps) {
  return (
    <Link to="/user-home-page" className={linkClass()} onClick={props.close}>
      <I.UserIcon className={iconClass()}/>
      <span>{g('homePageNavigationLink')}</span>
    </Link>
  );
}

function NewQuestionLink(props: NavbarLinkProps) {
  return (
    <Link to="/new-question" className={linkClass()} onClick={props.close}>
      <I.NewQuestionIcon className={iconClass()}/>
      <span>{g('newQuestionNavigationLink')}</span>
    </Link>
  );
}

function NewQuizLink(props: NavbarLinkProps) {
  return (
    <Link to="/new-quiz" className={linkClass()} onClick={props.close}>
      <I.NewQuizIcon className={iconClass()}/>
      <span>{g('newQuizNavigationLink')}</span>
    </Link>
  )
}

function QuestionSearchLink(props: NavbarLinkProps) {
  return (
    <Link to="/search-questions" className={linkClass()} onClick={props.close}>
      <I.QuestionSearchIcon className={iconClass()}/>
      <span>{g('questionSearchLink')}</span>
    </Link>
  )
}

function QuestionsLink(props: NavbarLinkProps) {
  return (
    <Link to="/questions" className={linkClass()} onClick={props.close}>
      <I.QuestionsIcon className={iconClass()}/>
      <span>{g('questionsNavigationLink')}</span>
    </Link>
  )
}
