import { QuestionEditContext, getByVersion } from "questionContext";
import { Question } from "questionTypes";
import { loadAllQuestionVersions, success } from "network";

export function versionResetter(
  questionContext: QuestionEditContext,
  setQuestionContext: (_: QuestionEditContext) => void,
  setBaseVersion: (_: number) => void
): (_: number) => void {
  return async (version: number) => {
    if (questionContext) {
      const question = getByVersion(questionContext, version);
      if (question) {
        setBaseVersion(version);
        setQuestionContext({
          ...questionContext,
          question: question
        });
      }
    }
  }
}
