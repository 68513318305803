import { ReactNode, Ref, useEffect } from 'react';

export default function useClosable(ref: Ref<HTMLElement>, callback: () => void) {
  useEffect(() => {
    function onClick(event: MouseEvent) {
      // @ts-ignore
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", onClick);
  }, []);
}
