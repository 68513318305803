import { QuizProgress, rawProgress } from "./progress";
import { QuizQuestion } from "./quiz";
import * as R from "./response";

export type QuizSubmission = QuizProgress & {
  questions: QuestionIdentifier[];
}

export type QuestionIdentifier = {
  index: number;
  questionId: string;
};

export function quizSubmission(
  responses: Map<number, R.Response>,
  flagged: Set<string>,
  hints: Map<number, number>,
  questions: QuizQuestion[]
): QuizSubmission {
  const progress = rawProgress(flagged, hints, responses, questions);
  const indicators = questions.map(question => {
    return {
      index: question.index,
      questionId: question.questionId
    };
  });
  return {
    ...progress,
    questions: indicators
  };
}
