import React from 'react';
import styles from "./QuestionNotes.module.scss";
import NotesInfo from "./NotesInfo";
import * as E from "TextEditor";
import { g } from "language";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";

type QuestionNotesProps = {
  text: E.Content;
  setText: (_: E.Content) => void;
}

export default function QuestionNotes(props: QuestionNotesProps) {
  return (
    <div className={styles['layout']}>
      <div className={styles['label-row']}>
        <label>{g('notes')}</label>
        <NotesInfo/>
      </div>
      <E.Editor
        value={props.text}
        onChange={content => props.setText(content)}
      />
    </div>
  );
}

type NotesDisplayProps = {
  text: E.Content;
  includeHeader: boolean;
}

export function NotesDisplay(props: NotesDisplayProps) {
  return (
    <div>
      {props.includeHeader ? <label>{g('notes')}</label> : null}
      {E.nonEmpty(props.text) ? (
        <E.TextDisplay value={props.text}/>
      ) : (
        <AbsenceIndicator glossaryKey={'notesAbsent'}/>
      )}
    </div>
  )
}
