import { g, GlossaryKey } from "language";
import styles from "./LoadingIndicator.module.scss";

type LoadingIndicatorProps = {
  subtextKey?: GlossaryKey;
  additionalClasses?: string;
}

export default function LoadingIndicator(props: LoadingIndicatorProps) {
  const wrapperClass = `${styles['wrapper']} ${props.additionalClasses || ""}`
  return (
    <div className={wrapperClass}>
      <div className={styles['loading-indicator']}></div>
      <div className={styles['explanation']}>{g(props.subtextKey || 'loadingMessage')}</div>
    </div>
  );
}
