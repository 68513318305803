// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagPatternSelector_pattern-row__d-KLq {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/tags/TagPatternSelector/TagPatternSelector.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"../../styles/constants.module.scss\";\n\n.pattern-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pattern-row": `TagPatternSelector_pattern-row__d-KLq`
};
export default ___CSS_LOADER_EXPORT___;
