import React from 'react';
import { g } from "language";
import styles from "./Flag.module.scss";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import { fromMaybe } from "logicUtils";

type FlagProps = {
  flagged: boolean;
  onClick: () => void;
  showText?: boolean;
  showUnflagged?: boolean;
}

export default function Flag(props: FlagProps) {
  const showText = fromMaybe(props.showText, true);
  const showUnflagged = fromMaybe(props.showUnflagged, true);
  const flaggedText = g('flaggedText');
  return (
    <div className={styles['flag']} onClick={props.onClick}>
      {props.flagged ? (
        <span className={styles['flagged']}>
          {showText ? <span className={styles['flagged-text']}>{flaggedText}</span> : null}
          <IoFlag/>
        </span>
      ) : (
        <span className={styles['unflagged']}>
          {showUnflagged ? <IoFlagOutline/> : null}
        </span>
      )}
    </div>
  )
}
