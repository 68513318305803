import { useState } from "react";
import styles from "./VariableSizeList.module.scss";
import { MinusButton, AddButton } from "commonComponents/buttons";
import _ from "lodash";
import { v4 } from "uuid";

type VariableSizeListProps<R> = {
  entries: R[];
  registerChange: (_: R[]) => void;
  entryDisplay: (_: R, __: (_: R) => void) => JSX.Element;
  defaultEntry: R;
  additionalClasses?: string;
  minimum?: number;
};

export function VariableSizeList<R>(props: VariableSizeListProps<R>) {
  const [keys, setKeys] = useState(props.entries.map((_) => v4()));
  const minimum = props.minimum || 1;
  const className = `${styles['variable-size-list']} ${props.additionalClasses || ""}`;
  const onChange = (entry: R, index: number) => {
    const copy = _.cloneDeep(props.entries);
    copy.splice(index, 1, entry);
    props.registerChange(copy);
  };
  const onDelete = (index: number) => {
    const copy = _.cloneDeep(props.entries);
    copy.splice(index, 1);
    props.registerChange(copy);
  };
  const onAdd = () => {
    const copy = _.cloneDeep(props.entries);
    copy.push(props.defaultEntry);
    props.registerChange(copy);
    keys.push(v4());
    setKeys([...keys]);
  }
  return (
    <div className={className}>
      {props.entries.map((e, i) => (
        <VariableSizeRow
          entry={e}
          onDelete={() => onDelete(i)}
          display={props.entryDisplay(e, v => onChange(v, i))}
          key={keys[i]}
          canDelete={props.entries.length > minimum}
        />
      ))}
      <AddButton onClick={onAdd}/>
    </div>
  );
}

type VariableSizeRowProps<R> = {
  entry: R;
  display: JSX.Element;
  onDelete: () => void;
  canDelete: boolean;
};

export function VariableSizeRow<R>(props: VariableSizeRowProps<R>) {
  return (
    <div className={styles['variable-size-row']}>
      {props.display}
      <MinusButton onClick={props.onDelete} enabled={props.canDelete}/>
    </div>
  );
}
