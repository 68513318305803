export function inDateRange(
  year: number, month: number, day: number,
  minYear: number, minMonth: number, minDay: number,
  maxYear: number, maxMonth: number, maxDay: number
) {
  const tooLow = year < minYear
                || (year === minYear && month < minMonth)
                || (year === minYear && month === minMonth && day < minDay);
  const tooHigh = year > maxYear
                  || (year === maxYear && month > maxMonth)
                  || (year === maxYear && month === maxMonth && day > maxDay);
  return !(tooLow || tooHigh);
}

export function dateInRange(date: Date, low: Date, high: Date): boolean {
  return inDateRange(
    date.getFullYear(), date.getMonth(), date.getDate(),
    low.getFullYear(), low.getMonth(), low.getDate(),
    high.getFullYear(), high.getMonth(), high.getDate()
  );
}

export function betweenDates(date: Date, a: Date, b: Date): boolean {
  const t = date.getTime();
  return (t >= a.getTime() && t <= b.getTime()) || (t <= a.getTime() && t >= b.getTime());
}
