import { Tag } from "../tags";
import { Question, QuestionModel } from "../questionTypes";

export type QuestionEditSubmission = {
  questionUpdate: Question;
  newTags: Tag[];
};

export type BasicQuestionEditState = Omit<QuestionModel, 'body' | 'settings'>;

export function createBasicQuestionEditState(question: Question): BasicQuestionEditState {
  return {
    questionType: question.questionType,
    images: question.images,
    tagIds: question.tagIds,
    notes: question.notes,
    hints: question.hints
  };
}
