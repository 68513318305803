import { LogicalPattern, LOGICAL_PATTERNS } from "./logicalPattern";
import styles from "./LogicalPatternSelector.module.scss";
import { g, GlossaryKey } from "language";

type LogicalPatternSelectorProps = {
  pattern: LogicalPattern;
  setPattern: (_: LogicalPattern) => void;
  leftKey: GlossaryKey;
  rightKey: GlossaryKey;
};

export default function LogicalPatternSelector(props: LogicalPatternSelectorProps) {
  const selectedClass = styles['selected-pattern'];
  const defaultClass = styles['pattern'];
  const chooseClass = (x: LogicalPattern) => {
    if (props.pattern === x) {
      return selectedClass;
    } else {
      return defaultClass;
    }
  };
  const explicativeClass = styles['pattern-helper-text'];
  return (
    <div className={styles['pattern-selection-row']}>
      <div className={explicativeClass}>{g(props.leftKey)}</div>
      <div className={styles['pattern-row']}>
        {LOGICAL_PATTERNS.map(pattern => {
          return (
            <span
              key={pattern}
              className={chooseClass(pattern)}
              onClick={() => props.setPattern(pattern)}
            >{g(pattern)}</span>
          )
        })}
      </div>
      <div className={explicativeClass}>{g(props.rightKey)}</div>
    </div>
  );
}
