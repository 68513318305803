import React, { Ref, KeyboardEvent } from 'react';
import { SimpleEditorState } from "./editorState";
import styles from "./SimpleEditor.module.scss";
import { MathJax } from "better-react-mathjax";
import DOMPurify from "dompurify";

type SimpleEditorProps = {
  value: SimpleEditorState;
  onChange: (_: SimpleEditorState) => void;
  reference?: Ref<HTMLTextAreaElement>;
  additionalClasses?: string;
  editable?: boolean;
};

export default function SimpleEditor(props: SimpleEditorProps) {
  const editable = props.editable === undefined ? true : props.editable;
  const formula = props.value.formula === true ? true : false;
  const wrapperClasses = `${props.additionalClasses || ""} ${styles['simple-editor']}`;
  const textClass = `${styles['simple-text']} ${formula ? styles['formula-text'] : ""}`;
  const conditionallySetFormula = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.ctrlKey && event.altKey && event.shiftKey && event.keyCode === 77) {
      props.onChange({
        ...props.value,
        formula: !props.value.formula
      });
    }
  }
  return editable ? (
    <div className={wrapperClasses}>
      <FormulaSection value={props.value} onChange={props.onChange}/>
      <textarea
        className={textClass}
        ref={props.reference || null}
        rows={2}
        value={props.value.text}
        onKeyDown={conditionallySetFormula}
        onChange={event => {
          props.onChange({
            ...props.value,
            text: event.target.value
          })
        }}
      />
    </div>
  ) : (
    <UneditableDisplay value={props.value} additionalClasses={props.additionalClasses}/>
  )
}

type UneditableDisplayProps = {
  value: SimpleEditorState;
  additionalClasses?: string;
}

function UneditableDisplay(props: UneditableDisplayProps) {
  const classNames = props.additionalClasses || "";
  return props.value.formula ? (
    <MathJax className={classNames}>{DOMPurify.sanitize(`$${props.value.text}$`)}</MathJax>
  ) : (
    <div className={classNames}>{props.value.text.replace('&nbsp;', ' ')}</div>
  )
}

type FormulaSectionProps = {
  value: SimpleEditorState;
  onChange: (_: SimpleEditorState) => void;
}

function FormulaSection(props: FormulaSectionProps) {
  const buttonClick = () => {

  };
  const buttonClass = styles[!!props.value.formula ? 'formula-button-selected' : 'formula-button'];
  return (
    <div className={styles['formula-section']}>
      {props.value.formula && (
        <MathJax>{`$${props.value.text}$`}</MathJax>
      )}
    </div>
  );
}

type SimpleTextDisplayProps = {
  value: SimpleEditorState;
  additionalClasses?: string;
};

export function SimpleTextDisplay(props: SimpleTextDisplayProps) {
  return (
    <SimpleEditor
      value={props.value}
      onChange={() => {}}
      additionalClasses={props.additionalClasses}
      editable={false}
    />
  );
}
