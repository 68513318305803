import { Question } from "questionTypes";
import isMobile from 'responsive';

export function rowIdentifier(q: Question): string {
  return `${q.questionId}-${q.version}`;
}

export type ExpansionState = "expanded" | "contracted";

export function questionRowExpanded(q: Question | undefined, expanded: Map<string, ExpansionState>): boolean {
  if (q === undefined) {
    return false;
  }
  const id = rowIdentifier(q);
  if (expanded.has(id)) {
    return expanded.get(id) === "expanded";
  } else {
    return isMobile();
  }
}
