import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { retrieveQuizResults, success, error, retakeQuiz } from "network";
import { QuizResultEnvelope, CompletedQuizQuestion, RetakeVersion } from "./quizResults";
import { useUser } from "user";
import { g, gp, gt } from "language";
import { QuizDescriptor } from "QuizPage";
import styles from "./QuizResultsPage.module.scss";
import {
  extractSettings,
  sortQuizFirst,
  fullCredit
} from "./quizResultOperations";
import LowerBar from "LowerBar";
import { Tag, TagListDisplay } from "tags";
import { LogicalPattern } from "commonComponents/LogicalPatternSelector";
import ExpansionIndicator from "ExpansionIndicator";
import Flag from "Flag";
import FormSection from "FormSection";
import { Question } from "questionTypes";
import { QuizIdentifier } from "quiz";
import { useQuizContext } from "quizContext";
import { setToggle } from "../logicUtils";
import { ResultDetail } from "./questionResultDetail";
import { SummarizedRow, SummarizedHeaderRow } from "SummarizedTable";
import {
  questionPoints, correctnessDynamicStyling, questionPointDisplay, quizPointDisplay
} from "questionCorrectness";

type ResultState = QuizResultEnvelope | null | 'error';

export default function QuizResultsPage() {
  const [result, setResult] = useState<ResultState>(null);
  const quizId = useParams().quiz_id;
  const userContext = useUser();
  const [expandedIndices, setExpandedIndices] = useState<Set<number>>(new Set());
  const [editingIndices, setEditingIndices] = useState<Set<number>>(new Set());
  useEffect(() => {
    (async () => {
      if (userContext.user && !result) {
        const response = await retrieveQuizResults(quizId);
        if (success(response)) {
          setResult(response);
        }
        if (error(response)) {
          setResult('error');
        }
      }
    })();
  }, [quizId, userContext.user.userId()]);
  const quizResultHeader = gt('quizResults', [(result as QuizResultEnvelope)?.quiz.ordinal || ""]);
  const expandContract = (index: number) => {
    setToggle(index, expandedIndices);
    setExpandedIndices(new Set(expandedIndices));
  };
  const toggleEditing = (index: number, editing: boolean) => {
    if (editing) {
      editingIndices.add(index);
    } else {
      editingIndices.delete(index);
    }
    setEditingIndices(new Set(editingIndices));
  };
  if (result === 'error') {
    return <FailedLoad/>;
  } else if (result === null) {
    return <StillLoading/>;
  } else {
    return (
      <div className={styles['quiz-result-page']}>
        <h1>{quizResultHeader}</h1>
        <QuizSummary result={result}/>
        <ResultDescriptor
          result={result}
          expandedIndices={expandedIndices}
          expandContract={expandContract}
          editingIndices={editingIndices}
          toggleEditing={toggleEditing}
        />
        {userContext.user ? (
          <ActionBar
            result={result}
            userId={userContext.user.userId()}
            editing={editingIndices}
            expanded={expandedIndices}
            missedOption={!fullCredit(result.quiz.totalScore)}
          />
        ) : null}
      </div>
    )
  }
}

function FailedLoad() {
  return (
    <>
      <h2>{g('loadingError')}</h2>
      <p>{g('quizResultLoadFailed')}</p>
    </>
  )
}

function StillLoading() {
  return (
    <>
      <h2>{g('stillLoading')}</h2>
      <p>{g('quizResultLoadingMessage')}</p>
    </>
  )
}

type QuizSummaryProps = {
  result: QuizResultEnvelope;
}

function QuizSummary(props: QuizSummaryProps) {
  const score = props.result.quiz.totalScore;
  const pointText = gp('point', score.points);
  const pointsDisplay = quizPointDisplay(score, pointText);
  return (
    <FormSection title={g('summary')} additionalClasses={styles['summary-box']}>
      <QuizDescriptor settings={extractSettings(props.result.quiz)}/>
      <div className={styles['quiz-points']}>
        {pointsDisplay}
      </div>
    </FormSection>
  )
}

type ResultDescriptorProps = QuestionResultsTableProps;

function ResultDescriptor(props: ResultDescriptorProps) {
  return (
    <div className={styles['result-description-section']}>
      <QuestionResultsTable
        result={props.result}
        expandedIndices={props.expandedIndices}
        expandContract={props.expandContract}
        editingIndices={props.editingIndices}
        toggleEditing={props.toggleEditing}
      />
    </div>
  );
}

type QuestionResultsTableProps = {
  result: QuizResultEnvelope;
  expandedIndices: Set<number>;
  expandContract: (_: number) => void;
  editingIndices: Set<number>;
  toggleEditing: (_: number, __: boolean) => void;
}

function QuestionResultsTable(props: QuestionResultsTableProps) {
  const [questionLatestVersions, setQuestionLatestVersions] = useState<Map<string, Question>>(
    new Map(props.result.latestQuestionVersions.map(question => [question.questionId, question]))
  );
  const addQuestionVersion = (id: string) => {
    return (q: Question) => {
      questionLatestVersions.set(id, q);
      setQuestionLatestVersions(new Map(questionLatestVersions));
    };
  };
  return (
    <div className={styles['quiz-result-summary']}>
      <SummarizedHeaderRow additionalClasses={styles['summary-header-row']}>
        <div>{g('questionNumberHeader')}</div>
        <div>{g('questionId')}</div>
        <div className={styles['tags-header']}>{g('questionTagsHeader')}</div>
        <div className={styles['points-header']}>{g('questionPointsHeader')}</div>
        <div>{g('questionFlaggedHeader')}</div>
        <span/>
      </SummarizedHeaderRow>
      {props.result.questions.sort((a, b) => a.quizSpecific.index - b.quizSpecific.index).map(question => {
        const index = question.quizSpecific.index;
        const id = question.question.question.questionId;
        return (
          <ResultSummaryQuestionRow
            question={question}
            tagIds={props.result.quiz.tagIds}
            tagPattern={props.result.quiz.tagPattern}
            open={props.expandedIndices.has(index)}
            aboveOpen={props.expandedIndices.has(index - 1)}
            expandContract={() => props.expandContract(index)}
            key={question.quizSpecific.index}
            latest={questionLatestVersions.get(id) || null}
            addLatest={addQuestionVersion(id)}
            allTags={props.result.allTags}
            editing={props.editingIndices.has(question.quizSpecific.index)}
            toggleEditing={editing => props.toggleEditing(index, editing)}
          />
        );
      })}
    </div>
  )
}

type ResultSummaryQuestionRowProps = {
  question: CompletedQuizQuestion;
  tagIds: string[];
  tagPattern: LogicalPattern;
  open: boolean;
  aboveOpen: boolean;
  expandContract: () => void;
  latest: Question | null;
  addLatest: (_: Question) => void;
  allTags: Tag[];
  editing: boolean;
  toggleEditing: (_: boolean) => void;
};

function ResultSummaryQuestionRow(props: ResultSummaryQuestionRowProps) {
  const score = props.question.quizSpecific.score;
  const userContext = useUser().user;
  const tagSet: Set<Tag> = new Set(userContext.getTagsById(props.tagIds));
  const questionTags: Tag[] = userContext.getTagsById(props.question.question.question.tagIds);
  const dynamicStyle = correctnessDynamicStyling(score);
  return (
    <>
      <SummarizedRow
        open={props.open}
        aboveOpen={props.aboveOpen}
        additionalStyle={dynamicStyle}
        additionalClasses={styles['summary-row']}
        expandContract={props.expandContract}
        summary={
          <>
            <div className={styles['number-cell']}>{props.question.quizSpecific.index + 1}.</div>
            <Link className={styles['symbol-cell']} to={`/question/${props.question.question.question.questionId}`}>{props.question.question.question.ordinal}</Link>
            <QuestionSummaryTags
              questionTags={questionTags}
              quizTags={tagSet}
              tagPattern={props.tagPattern}
            />
            <div className={styles['number-cell']}>{questionPointDisplay(score)}</div>
            <div className={styles['flag-cell']}>
              <Flag
                flagged={props.question.quizSpecific.flagged}
                onClick={() => {}}
                showText={false}
                showUnflagged={false}
              />
            </div>
            <div className={styles['symbol-cell']}>
              <ExpansionIndicator open={props.open}/>
            </div>
          </>
        }
        detail={
          <ResultDetail
            question={props.question}
            open={props.open}
            latest={props.latest}
            addLatest={props.addLatest}
            allTags={props.allTags}
            editing={props.editing}
            toggleEditing={props.toggleEditing}
          />
        }
      />
    </>
  )
}

type QuestionSummaryTagsProps = {
  questionTags: Tag[];
  quizTags: Set<Tag>;
  tagPattern: LogicalPattern;
};

function QuestionSummaryTags(props: QuestionSummaryTagsProps) {
  return (
    <div className={styles['summary-tags']}>
      <QuestionTags {...props}/>
    </div>
  )
}

type QuestionTagsProps = {
  questionTags: Tag[];
  quizTags: Set<Tag>;
  tagPattern: LogicalPattern;
};

function QuestionTags(props: QuestionTagsProps) {
  return (
    <TagListDisplay tagIds={sortQuizFirst(props.questionTags, props.quizTags).map(tag => tag.tagId)} indicateNoTags={true}/>
  )
}

type ActionBarProps = {
  result: QuizResultEnvelope;
  userId: string;
  expanded: Set<number>;
  editing: Set<number>;
  missedOption: boolean;
};

function ActionBar(props: ActionBarProps) {
  const quiz = useQuizContext();
  const navigate = useNavigate();
  const onRetake = async (version: RetakeVersion) => {
    const response = await retakeQuiz(props.result.quiz.quizId, version);
    if (success(response)) {
      const indicator = response as QuizIdentifier;
      quiz.setQuizId(indicator.identifier);
      navigate(`/quiz/${indicator.identifier}`);
    }
  };
  let editInProgress = props.editing.size > 0 ? (
    Array.from(props.editing.values()).reduce((acc, entry) => {
      return acc || props.expanded.has(entry);
    }, false as boolean)
  ) : false;
  const explanation = g('quizResultsEditingMessage');
  return (
    <LowerBar fullPage={true}>
      <div className={styles['inner-bar']}>
        {editInProgress ? <div className={styles['no-retake-explanation']}>{explanation}</div> : null}
        {props.missedOption ? <RetakeMissedButton onClick={onRetake} disabled={editInProgress}/> : null}
        <RetakeButton onClick={onRetake} disabled={editInProgress}/>
      </div>
    </LowerBar>
  );
}

type RetakeButtonProps = {
  onClick: (_: RetakeVersion) => void;
  disabled: boolean;
};

function RetakeButton(props: RetakeButtonProps) {
  const buttonStyle = styles[props.disabled ? 'retake-button-disabled' : 'retake-button'];
  const onClick = () => {
    if (!props.disabled) {
      props.onClick('all');
    }
  };
  return (
    <button className={buttonStyle} onClick={onClick}>
      {g('retakeButtonText')}
    </button>
  )
}

function RetakeMissedButton(props: RetakeButtonProps) {
  const buttonStyle = styles[props.disabled ? 'retake-missed-button-disabled' : 'retake-missed-button'];
  const onClick = () => {
    if (!props.disabled) {
      props.onClick('missed');
    }
  };
  return (
    <button className={buttonStyle} onClick={onClick}>
      {g('retakeMissedButtonText')}
    </button>
  )
}
