// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveQuizMessage_active-quiz-warning__LBSFe {
  font-size: 1.1rem;
  color: teal;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .ActiveQuizMessage_active-quiz-warning__LBSFe {
    margin-right: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/ActiveQuizMessage/ActiveQuizMessage.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,WAAA;EACA,mBAAA;AADF;AAEE;EAJF;IAKI,kBAAA;EACF;AACF","sourcesContent":["@import \"../styles/constants.module.scss\";\n\n.active-quiz-warning {\n  font-size: 1.1rem;\n  color: teal;\n  margin-bottom: 1rem;\n  @media(max-width: $mobile) {\n    margin-right: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active-quiz-warning": `ActiveQuizMessage_active-quiz-warning__LBSFe`
};
export default ___CSS_LOADER_EXPORT___;
