import { useContext, createContext } from 'react';

export const QuizContext = createContext<QuizContextValue>({
  quizId: null,
  setQuizId: () => {}
});

export type QuizContextValue = {
  quizId: string | null;
  setQuizId: (_: string | null) => void;
};

export function useQuizContext(): QuizContextValue {
  const quizContext = useContext(QuizContext);
  if (!quizContext) {
    throw new Error("Can't use quiz context");
  } else {
    return quizContext;
  }
}

export type ActiveQuizPayload = {
  quizId: string | null;
};
