import React from 'react';
import { g } from "../language";
import Info from "Info";

export default function NotesInfo() {
  return (
    <Info title={g('notesInfoHeader')}>
      <>{g("notesExplanation")}</>
    </Info>
  )
}
