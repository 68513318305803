import React from 'react';
import { QuizSettings } from "quiz";
import styles from "./QuizPage.module.scss";
import { useGlossary as g } from "language";
import { TagListDisplay } from "tags";
import { LogicalPattern } from "commonComponents/LogicalPatternSelector";

type QuizDescriptorProps = {
  settings: QuizSettings;
};

export default function QuizDescriptor(props: QuizDescriptorProps) {
  return (
    <div>
      <div className={styles['question-count-indicator']}>
        <span>{props.settings.questionNumber}</span>
        <span>{g(props.settings.questionNumber === 1 ? 'questionMinor' : 'questionsMinor')}</span>
      </div>
      <QuizTagDescriptor
        tagPattern={props.settings.tagPattern}
        tagIds={props.settings.tagIds}
        excludedTagIds={props.settings.excludedTagIds}
      />
    </div>
  );
}

type QuizTagDescriptorProps = {
  tagPattern: LogicalPattern;
  tagIds: string[];
  excludedTagIds: string[];
}

export function QuizTagDescriptor(props: QuizTagDescriptorProps) {
  const tagNumber = props.tagIds.length;
  const excludedTagNumber = props.excludedTagIds.length;
  return (
    <div className={styles['tag-matching-indicator']}>
      <span>{descriptorString(props.tagPattern, tagNumber)}</span>
      <div className={styles['tag-list-wrapper']}>
        <TagListDisplay tagIds={props.tagIds} indicateNoTags={false} additionalClasses={styles['tag-list']}/>
      </div>
      <span>{excludedDescriptorString(tagNumber, excludedTagNumber)}</span>
      <div className={styles['tag-list-wrapper']}>
        <TagListDisplay tagIds={props.excludedTagIds} indicateNoTags={false}/>
      </div>
    </div>
  );
}

function descriptorString(pattern: LogicalPattern, tagNumber: number): string {
  if (tagNumber === 0) {
    return '';
  }
  switch (pattern) {
    case 'all': return g('matchingAll');
    case 'any': return g('matchingAny');
  }
}

function excludedDescriptorString(tagNumber: number, excludedTagNumber: number): string {
  if (excludedTagNumber === 0) {
    return '';
  }
  if (tagNumber > 0) {
    return g('excludingTagsQualifier');
  }
  return g('excludingTagsStandalone');
}
