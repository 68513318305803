import React, { useState } from 'react';
import { MaximalQuestionSettingsModel } from "./questionSettingsModel";
import { AnswerLabel } from "labels";

export type QuestionSettingsContext = {
  ignoreWhitespace: boolean;
  setIgnoreWhitespace: (_: boolean) => void;
  ignoreCapitalization: boolean;
  setIgnoreCapitalization: (_: boolean) => void;
  ignorePunctuation: boolean;
  setIgnorePunctuation: (_: boolean) => void;
  errorMargin: number;
  setErrorMargin: (_: number) => void;
  randomize: boolean;
  setRandomize: (_: boolean) => void;
  imageAfter: boolean;
  setImageAfter: (_: boolean) => void;
  labelType: AnswerLabel | null;
  setLabelType: (_: AnswerLabel) => void;
  ordered: boolean;
  setOrdered: (_: boolean) => void;
  requiredCount: number | null;
  setRequiredCount: (_: number | null) => void;
}

function optionalBoolean(latestValue: boolean | null | undefined, defaultValue: boolean): boolean {
  if (latestValue === undefined || latestValue === null) {
    return defaultValue;
  } else {
    return latestValue;
  }
}

export function useSettingsState(settings?: Partial<MaximalQuestionSettingsModel>): QuestionSettingsContext {
  const [ignoreWhitespace, setIgnoreWhitespace] = useState(optionalBoolean(settings?.ignoreWhitespace, true));
  const [ignoreCapitalization, setIgnoreCapitalization] = useState(optionalBoolean(settings?.ignoreCapitalization, true));
  const [ignorePunctuation, setIgnorePunctuation] = useState(optionalBoolean(settings?.ignorePunctuation, true));
  const [errorMargin, setErrorMargin] = useState(settings?.errorMargin || 0);
  const [randomize, setRandomize] = useState(optionalBoolean(settings?.randomize, false));
  const [imageAfter, setImageAfter] = useState(optionalBoolean(settings?.imageAfter, false));
  const [labelType, setLabelType] = useState<AnswerLabel | null>(settings?.labelType || null);
  const [ordered, setOrdered] = useState(settings?.ordered || false);
  const [requiredCount, setRequiredCount] = useState<number | null>(settings?.requiredCount || null);
  return {
    ignoreWhitespace, setIgnoreWhitespace,
    ignoreCapitalization, setIgnoreCapitalization,
    ignorePunctuation, setIgnorePunctuation,
    errorMargin, setErrorMargin,
    randomize, setRandomize,
    imageAfter, setImageAfter,
    labelType, setLabelType,
    ordered, setOrdered,
    requiredCount, setRequiredCount
  }
}

export function updateSettingsState(settings: QuestionSettingsContext, newSettings: Partial<MaximalQuestionSettingsModel>) {
  settings.setIgnoreWhitespace(optionalBoolean(newSettings?.ignoreWhitespace, settings.ignoreWhitespace));
  settings.setIgnoreCapitalization(optionalBoolean(newSettings?.ignoreCapitalization, settings.ignoreCapitalization));
  settings.setIgnorePunctuation(optionalBoolean(newSettings?.ignorePunctuation, settings.ignorePunctuation));
  settings.setErrorMargin(newSettings?.errorMargin || settings.errorMargin);
  settings.setImageAfter(optionalBoolean(newSettings?.imageAfter, settings.imageAfter));
}
