import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Question, QuestionType, functionalityByType, questionTypes as QUESTION_TYPES } from "questionTypes";
import { PaginatedCache } from "paginatedCache";
import { g, gt, GlossaryKey } from "language";
import { Tag, TagListDisplay } from "tags";
import LogicalPatternSelector, { LogicalPattern } from "commonComponents/LogicalPatternSelector";
import styles from "./QuestionsPage.module.scss";
import FormSection from "FormSection";
import { TagSelector } from "tags";
import {
  CachedPartialLoadTable,
  PartialLoadTableVariant,
  TableCache,
  calculateLoadRangeByVariant,
  IndexedQuery
} from "PartialLoadTable";
import { loadQuestions, success, error } from "network";
import { useUser } from "user";
import { SummarizedRow, SummarizedHeaderRow } from "SummarizedTable";
import isMobile from "responsive";
import { Link, useNavigate } from 'react-router-dom';
import { labelType } from "QuestionSettings";
import ExpansionIndicator from "ExpansionIndicator";
import { rowIdentifier, questionRowExpanded, ExpansionState } from "./operations";
import { LatestVersionLabel } from "QuestionTimeline";
import { useNiceDateFromString, toStringNullable, fromStringNullable } from "timeUtils";
import { stringRepresentation } from "TextEditor";
import * as Q from "questionTypes";
import { QuestionEditableDisplay } from "QuestionPage";
import { QuestionEditContext, QuestionContext } from "questionContext";
import * as S from "./search";
import TabNavigator from "commonComponents/TabNavigator";
import DateRangeSelector, { DateRangeSelectorProps } from "commonComponents/dateSelectors/DateRangeSelector";
import { SubmissionResult, SubmissionIndicator } from "SubmissionResult";
import { questionRouteDefaultEditable } from "MainContent";
import LocalStorageCache from "localStorageCache";
import SingleSelect from "commonComponents/SingleSelect";
import {
  SettingsSearchModel, defaultSettingsSearchState, Setting, QuestionSettingsSearch, SETTINGS_FORM_ORDER
} from "QuestionSettings";
import { typedKeys } from "logicUtils";
import CheckBox from "commonComponents/CheckBox";
import Toggle, { SymmetricToggle } from "commonComponents/Toggle";
import { setToList } from "utils";
import { VariableSizeList } from "commonComponents/VariableSizeList";

export default function QuestionsPage() {
  const userContext = useUser();
  const [localCache, _] = useState(new LocalStorageCache<S.LocalCacheSearch>(`QUESTIONS_PAGE_${userContext.user.userId()}`));
  const defaultCriteria = localCache.get();
  const [submissionResult, setSubmissionResult] = useState<SubmissionResult>('none');
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(defaultCriteria?.tagSearch?.tagIds || []);
  const [excludedTagIds, setExcludedTagIds] = useState<string[]>(defaultCriteria?.tagSearch?.excludedTagIds || []);
  const [pattern, setPattern] = useState<LogicalPattern>(defaultCriteria?.tagSearch?.tagPattern || 'any');
  const [earliestDate, setEarliestDate] = useState<Date | null>(fromStringNullable(defaultCriteria?.dateSearch.earliestDateString));
  const [latestDate, setLatestDate] = useState<Date | null>(fromStringNullable(defaultCriteria?.dateSearch.latestDateString));
  const [dateVariant, setDateVariant] = useState<S.QuestionDateVariant>(defaultCriteria?.dateSearch.dateVariant || 'questionLastEditedDateVariant');
  const [textSearch, setTextSearch] = useState<S.TextSearch>(defaultCriteria?.textSearch || S.defaultTextSearch());
  const [tableVariant, setTableVariant] = useState<PartialLoadTableVariant>(isMobile() ? 'infinite-scroll' : 'paginated');
  const [questionTypes, setQuestionTypes] = useState<Set<QuestionType>>(new Set(defaultCriteria?.questionTypes || QUESTION_TYPES));
  const [settings, setSettings] = useState<SettingsSearchModel>(defaultCriteria?.settingsSearch || defaultSettingsSearchState());
  const [includedSettings, setIncludedSettings] = useState<Set<Setting>>(new Set(defaultCriteria?.includedSettings || []));
  const [componentPresence, setComponentPresence] = useState<S.ComponentPresence>(defaultCriteria?.componentPresence || S.defaultComponentPresence());
  const questionId = (q: QuestionContext): string => q.questionContext.question.questionId;
  const constructCriteria = (): S.QuestionSearchParameters => {
    const earliestDateString = toStringNullable(earliestDate);
    const latestDateString = toStringNullable(latestDate);
    const tagSearch = {
      tagPattern: pattern,
      tagIds: selectedTagIds,
      excludedTagIds: excludedTagIds
    };
    const dateSearch = {
      earliestDateString,
      latestDateString,
      dateVariant
    };
    return {
      dateSearch,
      questionTypes: S.effectiveQuestionTypeSearch(questionTypes),
      tagSearch,
      componentPresence,
      settingsSearch: S.partializeSettingsSearch(settings, includedSettings),
      textSearch
    };
  };
  const constructLocalCache = (): S.LocalCacheSearch => {
    const c = constructCriteria();
    return {
      dateSearch: c.dateSearch,
      questionTypes: c.questionTypes,
      tagSearch: c.tagSearch,
      componentPresence: c.componentPresence,
      settingsSearch: settings,
      includedSettings: setToList(includedSettings),
      textSearch: c.textSearch
    };
  }
  const [criteria, setCriteria] = useState<S.QuestionSearchParameters>(constructCriteria());
  const [expanded, setExpanded] = useState<Map<string, ExpansionState>>(new Map());
  const load = async (i: IndexedQuery<S.QuestionSearchParameters>) => {
    localCache.save(constructLocalCache());
    const newlyLoadedQuestions = await loadQuestions({
      ...i,
      parameters: S.prepareForBackend(i.parameters)
    });
    if (success(newlyLoadedQuestions)) {
      return newlyLoadedQuestions;
    } else {
      setSubmissionResult('failure');
      return null;
    }
  };
  const [loadCounter, setLoadCounter] = useState(0);
  const submitSearch = () => {
    setLoadCounter(loadCounter + 1);
  };
  const toggleExpanded = (q: Question) => {
    const open = questionRowExpanded(q, expanded);
    if (!open) {
      expanded.set(rowIdentifier(q), "expanded");
    } else {
      expanded.set(rowIdentifier(q), "contracted");
    }
    setExpanded(new Map(expanded));
  };
  const [resetCounter, setResetCounter] = useState(0);
  const onQuestionEditCompletion = (q: Question) => {
    setResetCounter(resetCounter + 1);
  };
  const onFullScroll = () => {
    setLoadCounter(loadCounter + 1);
  };
  return (
    <div className={styles['questions-page']}>
      <SubmissionIndicator
        result={submissionResult}
        close={() => setSubmissionResult('none')}
        failureKey={'questionsLoadFailed'}
      />
      <h1>{g('questionsPageHeader')}</h1>
      <QuestionFilterOptions
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        excludedTagIds={excludedTagIds}
        setExcludedTagIds={setExcludedTagIds}
        pattern={pattern}
        setPattern={setPattern}
        submitSearch={submitSearch}
        questionTypes={questionTypes}
        setQuestionTypes={setQuestionTypes}
        earliestDate={earliestDate}
        setEarliestDate={setEarliestDate}
        latestDate={latestDate}
        setLatestDate={setLatestDate}
        dateVariant={dateVariant}
        setDateVariant={setDateVariant}
        settings={settings}
        setSettings={setSettings}
        includedSettings={includedSettings}
        setIncludedSettings={setIncludedSettings}
        componentPresence={componentPresence}
        setComponentPresence={setComponentPresence}
        textSearch={textSearch}
        setTextSearch={setTextSearch}
      />
      <QuestionsTable
        variant={tableVariant}
        load={load}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        constructQuery={constructCriteria}
        currentQuery={criteria}
        setCurrentQuery={setCriteria}
        loadCounter={loadCounter}
        resetCounter={resetCounter}
        onQuestionEditCompletion={onQuestionEditCompletion}
        onFullScroll={onFullScroll}
      />
    </div>
  );
}

type QuestionFilterOptionsProps = {
  selectedTagIds: string[];
  setSelectedTagIds: (_: string[]) => void;
  excludedTagIds: string[];
  setExcludedTagIds: (_: string[]) => void;
  pattern: LogicalPattern;
  setPattern: (_: LogicalPattern) => void;
  submitSearch: () => void;
  questionTypes: Set<QuestionType>;
  setQuestionTypes: (_: Set<QuestionType>) => void;
  earliestDate: Date | null;
  setEarliestDate: (_: Date | null) => void;
  latestDate: Date | null;
  setLatestDate: (_: Date | null) => void;
  dateVariant: S.QuestionDateVariant;
  setDateVariant: (_: S.QuestionDateVariant) => void;
  settings: SettingsSearchModel;
  setSettings: (_: SettingsSearchModel) => void;
  includedSettings: Set<Setting>;
  setIncludedSettings: (_: Set<Setting>) => void;
  componentPresence: S.ComponentPresence;
  setComponentPresence: (_: S.ComponentPresence) => void;
  textSearch: S.TextSearch;
  setTextSearch: (_: S.TextSearch) => void;
};

function QuestionFilterOptions(props: QuestionFilterOptionsProps) {
  return (
    <div className={styles['form-wrapper']}>
      <FormSection title={g('questionFilterHeader')} additionalClasses={styles['search-section']}>
        <TabNavigator
          tabs={[
            {
              titleKey: 'sortByLabel',
              display: <div>Sort goes here</div>
            },
            {
              titleKey: 'tagLabel',
              display: (
                <TagSelector
                  selectedTagIds={props.selectedTagIds}
                  setSelectedTagIds={props.setSelectedTagIds}
                  expandable={false}
                  addNewTag={() => {}}
                  patternSelectorProps={{
                    pattern: props.pattern,
                    setPattern: props.setPattern
                  }}
                  excludedTagProps={{
                    excludedTagIds: props.excludedTagIds,
                    setExcludedTagIds: props.setExcludedTagIds
                  }}
                  additionalClasses={styles['tag-selector']}
                />
              )
            },
            {
              titleKey: 'dateLabel',
              display: (
                <DatePanel
                  dateVariant={props.dateVariant}
                  setDateVariant={props.setDateVariant}
                  startDateProps={{
                    date: props.earliestDate,
                    setDate: props.setEarliestDate
                  }}
                  endDateProps={{
                    date: props.latestDate,
                    setDate: props.setLatestDate
                  }}
                />
              )
            },
            {
              titleKey: 'textSearchLabel',
              display: (
                <TextSearchPanel
                  textSearch={props.textSearch}
                  setTextSearch={props.setTextSearch}
                />
              )
            },
            {
              titleKey: 'questionTypeAndSettingsLabel',
              display: (
                <QuestionTypeAndSettingsPanel
                  selected={props.questionTypes}
                  setSelected={props.setQuestionTypes}
                  settings={props.settings}
                  setSettings={props.setSettings}
                  includedSettings={props.includedSettings}
                  setIncludedSettings={props.setIncludedSettings}
                  questionTypes={props.questionTypes}
                  componentPresence={props.componentPresence}
                  setComponentPresence={props.setComponentPresence}
                />
              )
            }
          ]}
          direction={'horizontal'}
          additionalClasses={styles['search-tabs']}
          initialTitleKey={'textSearchLabel'}
        />
        <div className={styles['button-row']}>
          <button className={styles['create-button']} onClick={props.submitSearch}>
            {g('searchButton')}
          </button>
        </div>
      </FormSection>
    </div>
  )
}

type TextSearchPanelProps = {
  textSearch: S.TextSearch;
  setTextSearch: (_: S.TextSearch) => void;
};

function TextSearchPanel(props: TextSearchPanelProps) {
  return (
    <div className={styles['text-search-panel']}>
      <TextSearchInput {...props}/>
      <TextOptions {...props}/>
    </div>
  );
}

type TextOptionsProps = TextSearchPanelProps;

function TextOptions(props: TextOptionsProps) {
  const setInclusionPattern = (inclusionPattern: LogicalPattern) => {
    props.setTextSearch({
      ...props.textSearch,
      inclusionPattern
    });
  }
  const toggleIgnoreCase = () => {
    props.setTextSearch({
      ...props.textSearch,
      ignoreCase: !props.textSearch.ignoreCase
    })
  };
  const setAllVersions = (v: boolean) => {
    props.setTextSearch({
      ...props.textSearch,
      allVersions: v
    })
  };
  return (
    <div className={styles['text-panel-options']}>
      <LogicalPatternSelector
        pattern={props.textSearch.inclusionPattern}
        setPattern={setInclusionPattern}
        leftKey={'beforeTextPattern'}
        rightKey={'afterTextPattern'}
      />
      <div className={styles['text-toggle-options']}>
        <label>{g('textSearchIgnoreCase')}</label>
        <Toggle on={props.textSearch.ignoreCase} onClick={() => toggleIgnoreCase()}/>
      </div>
      <SymmetricToggle
        on={props.textSearch.allVersions}
        leftKey={'textSearchLatestVersionOnly'}
        rightKey={'textSearchAllVersions'}
        onClick={() => setAllVersions(!props.textSearch.allVersions)}
        leftClick={() => setAllVersions(false)}
        rightClick={() => setAllVersions(true)}
      />
    </div>
  );
}

type TextSearchInputProps = TextSearchPanelProps;

function TextSearchInput(props: TextSearchInputProps) {
  const registerChange = (newTexts: string[]) => {
    props.setTextSearch({
      ...props.textSearch,
      texts: newTexts
    });
  };
  return (
    <div className={styles['text-search-input-list']}>
      <label>{g('textSearchLabel')}</label>
      <VariableSizeList<string>
        entries={props.textSearch.texts}
        registerChange={registerChange}
        defaultEntry={''}
        entryDisplay={(value: string, setter: (_: string) => void) => (
          <input
            type="text"
            className={styles['text-search-input']}
            value={value}
            onChange={event => setter(event.target.value)}
          />
        )}
      />
    </div>
  );
}

type TextInputProps = {
  value: string;
  index: number;
  onChange: (_: string, __: number) => void;
  onDelete: (_: number) => void;
};

function TextInput(props: TextInputProps) {
  return null;
}

type DatePanelProps = DateVariantSelectorProps & DateRangeSelectorProps;

function DatePanel(props: DatePanelProps) {
  return (
    <div className={styles['date-panel']}>
      <DateVariantSelector
        dateVariant={props.dateVariant}
        setDateVariant={props.setDateVariant}
      />
      <DateRangeSelector
        startDateProps={props.startDateProps}
        endDateProps={props.endDateProps}
      />
    </div>
  )
}

type DateVariantSelectorProps = {
  dateVariant: S.QuestionDateVariant;
  setDateVariant: (_: S.QuestionDateVariant) => void;
};

function DateVariantSelector(props: DateVariantSelectorProps) {
  const entries = (S.QUESTION_DATE_VARIANTS as GlossaryKey[]).map(key => ({
    id: key,
    element: <div>{g(key)}</div>
  }));
  return (
    <SingleSelect
      entries={entries}
      onSelect={props.setDateVariant}
      header={{
        elementId: props.dateVariant
      }}
      additionalClasses={styles['date-variant-selector']}
      label={g('questionDateMeaning')}
    />
  );
}

type QuestionTypeAndSettingsPanelProps = {
  selected: Set<QuestionType>;
  setSelected: (_: Set<QuestionType>) => void;
  settings: SettingsSearchModel;
  setSettings: (_: SettingsSearchModel) => void;
  includedSettings: Set<Setting>;
  setIncludedSettings: (_: Set<Setting>) => void;
  questionTypes: Set<QuestionType>;
  componentPresence: S.ComponentPresence;
  setComponentPresence: (_: S.ComponentPresence) => void;
};

function QuestionTypeAndSettingsPanel(props: QuestionTypeAndSettingsPanelProps) {
  const labelClass = styles['spaced-label'];
  const sectionClass = styles['metadata-section-wrapper'];
  return (
    <div>
      <div className={sectionClass}>
        <label className={labelClass}>{g('questionTypeInfoHeader')}</label>
        <Q.MultipleQuestionTypeSelector
          selected={props.selected}
          setSelected={props.setSelected}
        />
      </div>
      <div className={sectionClass}>
        <label className={labelClass}>{g('settingsSectionHeader')}</label>
        <QuestionSettingsSearch
          search={props.settings}
          setSearch={props.setSettings}
          includedSettings={props.includedSettings}
          setIncludedSettings={props.setIncludedSettings}
          questionTypes={props.questionTypes}
        />
      </div>
      <div>
        <label className={labelClass}>{g('componentPresenceHeader')}</label>
        <ComponentPresenceSearch
          componentPresence={props.componentPresence}
          setComponentPresence={props.setComponentPresence}
        />
      </div>
    </div>
  )
}

type ComponentPresenceSearchProps = {
  componentPresence: S.ComponentPresence;
  setComponentPresence: (_: S.ComponentPresence) => void
};

function ComponentPresenceSearch(props: ComponentPresenceSearchProps) {
  const setter = (k: S.ComponentPresenceKey) => {
    return (v: boolean | null) => {
      props.setComponentPresence({
        ...props.componentPresence,
        [k]: v
      });
    };
  }
  return (
    <div className={styles['component-presence-search']}>
      {typedKeys(props.componentPresence).map(k => (
        <ComponentPresenceIndicator
          presence={props.componentPresence[k]}
          setPresence={setter(k)}
          labelKey={`${k}Label` as GlossaryKey}
          key={k}
        />
      ))}
    </div>
  )
}

type ComponentPresenceIndicatorProps = {
  presence: boolean | null;
  setPresence: (_: boolean | null) => void;
  labelKey: GlossaryKey;
}

function ComponentPresenceIndicator(props: ComponentPresenceIndicatorProps) {
  const [visibleSetting, setVisibleSetting] = useState(props.presence || false);
  useEffect(() => {
    if (props.presence !== null) {
      setVisibleSetting(props.presence);
    }
  }, [props.presence]);
  const checkboxClick = () => {
    if (props.presence === null) {
      props.setPresence(visibleSetting);
    } else {
      props.setPresence(null);
    }
  };
  return (
    <div className={styles['component-presence']}>
      <div className={styles['component-presence-include']} onClick={checkboxClick}>
        <label>{g(props.labelKey)}</label>
        <CheckBox selected={props.presence !== null}/>
      </div>
      <SymmetricToggle
        on={visibleSetting}
        leftKey={'notPresentLabel'}
        rightKey={'presentLabel'}
        disabled={props.presence === null}
        onClick={() => props.setPresence(!props.presence)}
        leftClick={() => props.setPresence(false)}
        rightClick={() => props.setPresence(true)}
      />
    </div>
  );
}

type QuestionsTableProps = {
  variant: PartialLoadTableVariant;
  load: (_: IndexedQuery<S.QuestionSearchParameters>) => Promise<S.QuestionPartialLoad | null>;
  expanded: Map<string, ExpansionState>;
  toggleExpanded: (_: Question) => void;
  constructQuery: () => S.QuestionSearchParameters;
  currentQuery: S.QuestionSearchParameters;
  setCurrentQuery: (_: S.QuestionSearchParameters) => void;
  loadCounter: number;
  resetCounter: number;
  onQuestionEditCompletion: (_: Question) => void;
  onFullScroll: () => void;
};

function QuestionsTable(props: QuestionsTableProps) {
  const [expandedIndices, setExpandedIndices] = useState<Set<number>>(new Set());
  const headerRow = isMobile() ? <div></div> : <QuestionHeaders/>;
  const userId = useUser().user.userId();
  const questionId = (q: QuestionContext) => q.questionContext.question.questionId;
  return (
    <CachedPartialLoadTable<QuestionContext, S.QuestionSearchParameters, null, S.QuestionPartialLoad>
      variant={props.variant}
      load={props.load}
      header={() => headerRow}
      headerClasses={styles['header-wrapper']}
      rowId={questionId}
      queryKey={S.serializeQuestionSearchParameters}
      constructQuery={props.constructQuery}
      currentQuery={props.currentQuery}
      setCurrentQuery={props.setCurrentQuery}
      extractFromLoad={(response: S.QuestionPartialLoad) => ({
        rows: response.questions,
        metadata: null,
        totalRows: response.totalResultCount
      })}
      loadCounter={props.loadCounter}
      resetCounter={props.resetCounter}
      onFullScroll={props.onFullScroll}
      row={((row, index, globalIndex, _, allRows) => (
        <QuestionRow
          questionContext={row}
          open={questionRowExpanded(row.questionContext.question, props.expanded)}
          aboveOpen={index > 0 && questionRowExpanded(allRows[index - 1].questionContext.question, props.expanded)}
          onClick={(q: Question) => {
            props.toggleExpanded(row.questionContext.question);
          }}
          onClickExpansionIndicator={(q: Question) => {}}
          displayIndex={index}
          key={row.questionContext.question.questionId}
          onQuestionEditCompletion={props.onQuestionEditCompletion}
          index={globalIndex}
        />
      ))}
    />
  )
}

type QuestionHeadersProps = {};

function QuestionHeaders() {
  return (
    <div className={styles['header']}>
      <div></div>
      <div>{g('questionOrdinalHeader')}</div>
      <div>{g('versionHeader')}</div>
      <div>{g('questionTypeHeader')}</div>
      <div>{g('promptHeader')}</div>
      <div>{g('editedDateHeader')}</div>
      <div>{g('tagsHeader')}</div>
      <div></div>
    </div>
  )
}

type QuestionRowProps = {
  questionContext: QuestionContext;
  open: boolean;
  aboveOpen: boolean;
  onClick: (_: Question) => void;
  onClickExpansionIndicator: (_: Question) => void;
  displayIndex: number;
  onQuestionEditCompletion: (_: Question) => void;
  index: number;
}

function QuestionRow(props: QuestionRowProps) {
  const key = `${props.questionContext.questionContext.question.questionId}${props.questionContext.questionContext.question.version}`;
  return isMobile() ? (
    <QuestionRowMobile {...props} key={key}/>
  ) : (
    <QuestionRowDesktop {...props} key={key}/>
  );
}

function QuestionRowDesktop(props: QuestionRowProps) {
  const question = props.questionContext.questionContext.question;
  const dateDisplay = useNiceDateFromString(question.writtenDate);
  const backgroundClass = props.displayIndex % 2 === 1 ? styles['row-color-background'] : "";
  const classes = `${styles['question-row']} ${backgroundClass}`;
  const [editing, setEditing] = useState(false);
  const [baseVersion, setBaseVersion] = useState(question.version);
  const detailClasses = styles['question-expanded-view'];
  const navigate = useNavigate();
  return (
    <SummarizedRow
      open={props.open}
      aboveOpen={props.aboveOpen}
      expandContract={() => props.onClick(question)}
      summary={
        <>
          <div className={styles['row-index']}>{props.index + 1}.</div>
          <div>
            <Link to={`question/${question.questionId}`}>
              {props.questionContext.questionContext.question.ordinal}
            </Link>
          </div>
          <div className={styles['version-entry']}>{question.version}</div>
          <div className={styles['question-type-entry']}>{g(question.questionType)}</div>
          <QuestionPromptRowEntry question={question}/>
          <div className={styles['last-written-entry']}>{dateDisplay}</div>
          <TagListDisplay tagIds={question.tagIds} indicateNoTags={true} additionalClasses={styles['tag-list']}/>
          <ExpansionIndicator open={props.open}/>
        </>
      }
      detail={
        <QuestionEditableDisplay
          questionContext={props.questionContext.questionContext}
          quizHistory={props.questionContext.statistics}
          setQuestionContext={() => {}}
          baseVersion={baseVersion}
          setBaseVersion={setBaseVersion}
          editing={editing}
          setEditing={setEditing}
          onCompletion={props.onQuestionEditCompletion}
          displayContext={'inline'}
          additionalClasses={detailClasses}
          editOverride={() => navigate(questionRouteDefaultEditable(question.questionId))}
        />
      }
      additionalClasses={classes}
    />
  );
}

function QuestionRowMobile(props: QuestionRowProps) {
  const question = props.questionContext.questionContext.question;
  const ordinalText = gt('questionWithOrdinal', [question.ordinal.toString()]);
  const questionTypeText = `(${g(question.questionType)})`;
  const ordinalHeaderText = `${question.ordinal}. `;
  const tagClass = question.tagIds.length > 0 ? styles['mobile-demarcated'] : '';
  const functionality = Q.functionalityByType(question.questionType);
  const questionPromptClass = styles[props.open ? 'expanded-prompt' : 'contracted-prompt'];
  return (
    <FormSection title="" additionalClasses={styles['mobile-question-outline']}>
      <ExpansionIndicator
        open={props.open}
        additionalClasses={styles['expansion-indicator']}
        onClick={() => props.onClick(question)}
      />
      <div>
        <div className={styles['row-header']} onClick={event => props.onClick(question)}>
          <Link to={`/question/${question.questionId}`} className={styles['mobile-question-link']}>
            <span className={styles['ordinal-label']}>{ordinalHeaderText}</span>
            <span className={styles['question-type-label']}>{questionTypeText}</span>
          </Link>
        </div>
        {props.open ? (
          <>
            {functionality.bodyDisplay({
              question: question.body,
              labelType: labelType(question.settings),
              additionalClasses: styles['mobile-demarcated']
            })}
            <TagListDisplay
              tagIds={question.tagIds}
              indicateNoTags={false}
              additionalClasses={tagClass}/>
            <LatestVersionLabel
              version={question.version}
              writtenDate={question.writtenDate}
              additionalClasses={styles['version-label']}
            />
          </>
        ) : null}
      </div>
    </FormSection>
  );
}

type QuestionPromptRowEntryProps = {
  question: Question;
};

function QuestionPromptRowEntry(props: QuestionPromptRowEntryProps) {
  const display = functionalityByType(props.question.questionType).promptDisplay({
    question: props.question.body,
    singleLine: true
  });
  return (
    <div className={styles['prompt-entry']}>
      {display}
    </div>
  );
}
