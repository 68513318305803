import { NumericScore } from "QuizResultsPage";

export function questionPointDisplay(score: NumericScore, nominal?: string): string {
  const pointsFraction = scoreDecimalDisplay(questionPoints(score));
  const nominalText = nominal === undefined ? "" : ` ${nominal}`;
  return `${score.points}/${score.pointsPossible} = ${pointsFraction}${nominalText}`;
}

export function quizPointDisplay(score: NumericScore, nominal?: string): string {
  const points = scoreDecimalDisplay(score.points);
  const nominalText = nominal === undefined ? "" : ` ${nominal}`;
  return `${points}${nominalText}`;
}

export function scoreDecimalDisplay(score: number): string {
  const rounded = score.toFixed(2);
  const split = rounded.split("");
  let i = split.length - 1;
  while (i >= 1 && (split[i] === "0" || split[i] === ".")) {
    const shouldBreak = split[i] === "."
    split[i] = "";
    i--;
    if (shouldBreak) {
      break;
    }
  }
  return split.join("");
}

export function questionPoints(score: NumericScore): number {
  return score.points / score.pointsPossible;
}
