import styles from "./TagSelector.module.scss";
import { HiOutlineX } from "react-icons/hi";
import { Tag } from "../tags";

type TagChicletProps = {
  tag: Tag;
  onClick: () => void;
  index: number;
  external?: boolean;
};

export default function TagChiclet(props: TagChicletProps) {
  const external = props.external === undefined ? false : props.external;
  const baseClass = external ? styles['tag-chiclet-external'] : styles['tag-chiclet'];
  const colorClass = styles[`chiclet-${props.index % 4}`];
  const chicletClass = `${colorClass} ${baseClass}`;
  return (
    <span className={chicletClass} onClick={() => props.onClick()}>
      {props.tag.body.text}
      {external ? null : <HiOutlineX className={styles['remove-tag']}/>}
    </span>
  );
}
