import { indexOfOrLength, indexDefaultNull, parseIntNullable } from "utils";
import { DAYS_PER_MONTH, daysPerMonthByYear } from "timeUtils";

const mmddyyyy_regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

export type ParsedDate = {
  day: number | null;
  month: number | null;
  year: number | null;
};

export function fullySpecified(pd: ParsedDate): boolean {
  return pd.day !== null && pd.month !== null && pd.year !== null;
}

export function monthValid(m: number): boolean {
  return m >= 1 && m <= 12;
}

export function dayValid(pd: ParsedDate): boolean {
  return pd.day === salvageDay(pd);
}

export function salvageDay(pd: ParsedDate): number | null {
  if (pd.day === null || pd.month === null) {
    return pd.day;
  } else {
    const daysPerMonth = daysPerMonthByYear(pd.year);
    return Math.min(daysPerMonth[pd.month - 1], pd.day);
  }
}

export function parseNumber(raw: string): number | null {
    if (raw === "") {
      return null;
    } else {
      return parseInt(raw, 10);
    }
}
