// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SimpleEditor_simple-text__bzZjd, .SimpleEditor_formula-text__GSy2T {
  align-self: stretch;
  resize: vertical;
}

.SimpleEditor_simple-editor__FDYgz {
  display: flex;
  flex-direction: column;
}

.SimpleEditor_formula-button-wrapper__ckdnC {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.SimpleEditor_formula-button__9t6FU, .SimpleEditor_formula-button-selected__TdKLO {
  border: 1px solid #dddddd;
  padding: 3px;
  border-radius: 3px;
}

.SimpleEditor_formula-button-selected__TdKLO {
  background: #00aaff;
  color: white;
}

.SimpleEditor_formula-text__GSy2T {
  border: 3px solid #00aaff;
}`, "",{"version":3,"sources":["webpack://./src/TextEditor/SimpleEditor/SimpleEditor.module.scss","webpack://./src/styles/constants.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AADF;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EAEE,mBC1BK;ED2BL,YAAA;AAFF;;AAKA;EAEE,yBAAA;AAHF","sourcesContent":["@import \"../../styles/constants.module.scss\";\n\n.simple-text {\n  align-self: stretch;\n  resize: vertical;\n}\n\n.simple-editor {\n  display: flex;\n  flex-direction: column;\n}\n\n.formula-button-wrapper {\n  align-self: stretch;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.formula-button {\n  border: 1px solid $light-gray;\n  padding: 3px;\n  border-radius: 3px;\n}\n\n.formula-button-selected {\n  @extend .formula-button;\n  background: $blue;\n  color: white;\n}\n\n.formula-text {\n  @extend .simple-text;\n  border: 3px solid $blue;\n}\n","$blue-green: #00ccaa;\n$blue: #00aaff;\n$dark-blue: #0066ff;\n$green: #00bb00;\n$dark-green: #008800;\n$light-gray: #dddddd;\n$ultra-light-gray: #dfdfdf;\n$mid-light-gray: #bbbbbb;\n$gray: gray;\n$dark-gray: #282828;\n$red: salmon;\n$pale-blue: #87cefa;\n$off-white: #f8f8ff;\n$purple: #800080;\n$dark-red: #dc143c;\n$light-green: #00ffef;\n\n$navbar-height: 3rem;\n$mobile: 600px;\n$desktop-left-margin: 1rem;\n$mobile-margin: 0.5rem;\n$desktop-margin: 20rem;\n$desktop-wide-view-margin: 3rem;\n$mobile-default-font-size: 0.7rem;\n$mobile-label-size: 0.8rem;\n\n$external-chiclet-padding: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simple-text": `SimpleEditor_simple-text__bzZjd`,
	"formula-text": `SimpleEditor_formula-text__GSy2T`,
	"simple-editor": `SimpleEditor_simple-editor__FDYgz`,
	"formula-button-wrapper": `SimpleEditor_formula-button-wrapper__ckdnC`,
	"formula-button": `SimpleEditor_formula-button__9t6FU`,
	"formula-button-selected": `SimpleEditor_formula-button-selected__TdKLO`
};
export default ___CSS_LOADER_EXPORT___;
