import { Hints, Hint } from "./hintsModel";
import * as E from "TextEditor";
import { listUndoSufficientDifference } from "undo";

export function hintsEqual(a: Hints, b: Hints): boolean {
  if (a.hints.length !== b.hints.length) {
    return false;
  } else {
    return a.hints.reduce((equal, ahint, index) => {
      const bhint = b.hints[index];
      return equal && E.equals(ahint.text, bhint.text);
    }, true as boolean);
  }
}

export function hintUndoSufficientDifference(last: Hints, current: Hints): boolean {
  return listUndoSufficientDifference(last.hints, current.hints, (l: Hint, c: Hint) => {
    return E.undoSufficientDifference(l.text, c.text);
  });
}