import React, { useState, useEffect } from 'react';
import { QuestionEditSubmission } from "./questionEditContext";
import { QuestionEditContext } from "questionContext";
import { Tag } from "tags";
import styles from "./QuestionEditor.module.scss";
import * as S from "QuestionSettings";
import * as Q from "questionTypes";
import {
  LabeledFile, handlesToLabeledFiles, answerImages, picturesForUpload
} from "../pictures";
import { useGlossary as g } from "language";
import Modal from "Modal";
import { deleteQuestion, updateQuestion, uploadPictures } from "network";
import { useUser } from "user";
import { SubmissionResult } from "SubmissionResult";
import FormSection from "FormSection";
import QuestionForm, { InvalidityIndicator } from "QuestionForm";
import { Hints } from "QuestionHints";
import LowerBar from "LowerBar";
import QuestionTimeline from "QuestionTimeline";

export type DisplayContext = 'full-page' | 'inline';

type QuestionEditorProps = {
  questionContext: QuestionEditContext;
  setQuestionContext: (_: QuestionEditContext) => void;
  setSubmissionResult: (_: SubmissionResult) => void;
  displayContext: DisplayContext;
  onCompletion: (_: Q.Question) => void;
  baseVersion: number;
  setBaseVersion: (_: number) => void;
  onSelectVersion: (_: number) => void;
}

export default function QuestionEditor(props: QuestionEditorProps) {
  const question = props.questionContext.question;
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(question.tagIds);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [notes, setNotes] = useState(question.notes);
  const [settings, setSettings] = useState<S.MaximalQuestionSettingsModel>(S.bloatedSettingsState(question.settings));
  const [questionType, setQuestionType] = useState<Q.QuestionType>(question.questionType);
  const questionState = Q.useVariadicQuestionState(question.body);
  const [files, setFiles] = useState<LabeledFile[]>(handlesToLabeledFiles(question.images));
  const [answerFiles, setAnswerFiles] = useState<LabeledFile[]>(answerImages(question.questionType, question.body));
  const [version, setVersion] = useState(question.version);
  const [writtenDate, setWrittenDate] = useState(new Date(question.writtenDate));
  const [hints, setHints] = useState<Hints>(question.hints);
  const picturesMerged = picturesForUpload(questionType, Q.functionalityByType(questionType).resolveVariadicQuestionBody(questionState.state), files, answerFiles);
  const newQuestionSubmission = {
    questionType,
    body: Q.functionalityByType(questionType).resolveVariadicQuestionBody(questionState.state),
    images: picturesMerged.handles,
    tagIds: selectedTagIds,
    settings: S.clampedSettingsModel(questionType, settings),
    notes,
    version: version + 1,
    writtenDate: question.writtenDate,
    questionId: question.questionId,
    hints: hints,
    ordinal: question.ordinal
  };
  const payload = {
    questionUpdate: newQuestionSubmission,
    newTags: newTags
  };
  const answerCount = Q.functionalityByType(questionType).answerCount(questionState);
  const wrapperClass = styles[props.displayContext === 'full-page' ? 'full-page-wrapper' : 'inline-wrapper'];
  const addNewTag = (tag: Tag) => {
    setSelectedTagIds(selectedTagIds.concat([tag.tagId]))
    setNewTags(newTags.concat([tag]));
  };
  useEffect(() => {
    const q = props.questionContext.question;
    Q.setVariadicQuestionState(q.body, questionState);
    setSettings(S.bloatedSettingsState(q.settings));
    setHints(q.hints);
    setSelectedTagIds(q.tagIds);
    setNotes(q.notes);
    setQuestionType(q.questionType);
    setFiles(handlesToLabeledFiles(q.images));
    setAnswerFiles(answerImages(q.questionType, q.body));
  }, [props.questionContext]);
  return (
    <div className={wrapperClass}>
      <div className={styles['caveat']}>{g('editCaveat')}</div>
      <QuestionTimeline
        currentVersion={props.baseVersion}
        allVersions={props.questionContext.versions}
        onSelectVersion={props.onSelectVersion}
      />
      <QuestionForm
        questionType={questionType}
        setQuestionType={setQuestionType}
        body={questionState}
        hints={hints}
        setHints={setHints}
        files={files}
        setFiles={setFiles}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        addNewTag={addNewTag}
        settings={settings}
        setSettings={setSettings}
        answerCount={answerCount}
        notes={notes}
        setNotes={setNotes}
        answerFiles={answerFiles}
        setAnswerFiles={setAnswerFiles}
      />
      <ButtonRow
        payload={payload}
        files={picturesMerged.files}
        setSubmissionResult={props.setSubmissionResult}
        updateVersion={setVersion}
        updateWrittenDate={setWrittenDate}
        displayContext={props.displayContext}
        onCompletion={props.onCompletion}
      />
    </div>
  )
}

type ButtonRowProps = UpdateButtonProps & {
  displayContext: DisplayContext;
};

function ButtonRow(props: ButtonRowProps) {
  const [visible, setVisible] = useState(false);
  return (
    <LowerBar fullPage={props.displayContext === 'full-page'}>
      <div className={styles["button-row"]}>
        <button className={styles["delete-button"]} onClick={() => setVisible(true)}>{g('disable')}</button>
        <UpdateButton
          payload={props.payload}
          files={props.files}
          onCompletion={props.onCompletion}
          setSubmissionResult={props.setSubmissionResult}
          updateVersion={props.updateVersion}
          updateWrittenDate={props.updateWrittenDate}
        />
      </div>
      <DeleteModal
        visible={visible}
        setVisible={setVisible}
        questionId={props.payload.questionUpdate.questionId}
        setSubmissionResult={props.setSubmissionResult}
        onCancel={() => props.onCompletion(props.payload.questionUpdate)}
      />
    </LowerBar>
  )
}

type UpdateButtonProps = {
  payload: QuestionEditSubmission;
  files: LabeledFile[];
  onCompletion: (_: Q.Question) => void;
  setSubmissionResult: (_: SubmissionResult) => void;
  updateVersion: (_: number) => void;
  updateWrittenDate: (_: Date) => void;
};

function UpdateButton(props: UpdateButtonProps) {
  const userContext = useUser();
  const question = props.payload.questionUpdate;
  const enabled = Q.functionalityByType(question.questionType).invalidVariant(question.body) === 'valid';
  const className = styles[enabled ? 'update-button' : 'update-button-disabled'];
  return (
    <div className={styles['update-button-section']}>
      <InvalidityIndicator body={question.body} questionType={question.questionType}/>
      <button className={className} onClick={() => {
        if (enabled && userContext.user) {
          return Promise.all([updateQuestion(props.payload), uploadPictures(props.files)])
          .then(() => {
            props.onCompletion(question);
            props.setSubmissionResult('success');
            props.updateVersion(question.version);
            props.updateWrittenDate(new Date());
          })
          .catch(() => props.setSubmissionResult('failure'));
        }
      }}
      >{g('update')}</button>
    </div>
  )
}

type DeleteModalProps = {
  visible: boolean;
  setVisible: (_: boolean) => void;
  questionId: string;
  setSubmissionResult: (_: SubmissionResult) => void;
  onCancel: () => void;
}

function DeleteModal(props: DeleteModalProps) {
  const userContext = useUser();
  return (
    <Modal visible={props.visible} setVisible={props.setVisible}>
      <h3>{g("disableHeader")}</h3>
      <p>{g("disableExplanation")}</p>
      <div className={styles['modal-button-row']}>
        <button className={styles['dont-delete']} onClick={() => props.setVisible(false)}>{g('cancel')}</button>
        <button className={styles['final-delete']} onClick={() => {
          if (userContext.user) {
            deleteQuestion(props.questionId)
            .then(() => props.onCancel())
            .catch(() => props.setSubmissionResult('failure'));
          }
        }}
        >{g('disable')}</button>
      </div>
    </Modal>
  )
}
