import { useState } from 'react';
import * as Q from "./questionTypes";
import * as C from "./questionTypeConversions";
import { functionalityByType, functionalityByBody } from "./questionTypeFunctionality";
import { bodiesEqual } from "./questionOperations";

export type VariadicQuestion = {
  writeIn: Q.WriteIn;
  multipleChoice: Q.MultipleChoice;
  fillInTheBlank: Q.FillInTheBlank;
  trueFalse: Q.TrueFalse;
  multiSelect: Q.MultiSelect;
  ordering: Q.Ordering;
}

export type VariadicQuestionSetters = {
  setWriteIn: (_: Q.WriteIn) => void;
  setMultipleChoice: (_: Q.MultipleChoice) => void;
  setFillInTheBlank: (_: Q.FillInTheBlank) => void;
  setTrueFalse: (_: Q.TrueFalse) => void;
  setMultiSelect: (_: Q.MultiSelect) => void;
  setOrdering: (_: Q.Ordering) => void;
}

export type VariadicQuestionState = {
  state: VariadicQuestion;
  setters: VariadicQuestionSetters;
  switchbacks: Set<Q.QuestionType>;
  setSwitchbacks: (_: Set<Q.QuestionType>) => void;
};

type Setter<T> = (_: T) => void;

export function useVariadicQuestionState(q?: Q.QuestionBody): VariadicQuestionState {
  const [writeIn, setWriteIn] = useState(q ? C.toWriteIn(q) : Q.initialWriteIn());
  const [trueFalse, setTrueFalse] = useState(q ? C.toTrueFalse(q) : Q.initialTrueFalse());
  const [multipleChoice, setMultipleChoice] = useState(q ? C.toMultipleChoice(q) : Q.initialMultipleChoice());
  const [fillInTheBlank, setFillInTheBlank] = useState(q ? C.toFillInTheBlank(q) : Q.initialFillInTheBlank());
  const [multiSelect, setMultiSelect] = useState(q ? C.toMultiSelect(q) : Q.initialMultiSelect());
  const [ordering, setOrdering] = useState(q ? C.toOrdering(q) : Q.initialOrdering());
  const [switchbacks, setSwitchbacks] = useState<Set<Q.QuestionType>>(new Set());
  return {
    state: {
      writeIn, trueFalse, multipleChoice, fillInTheBlank, multiSelect, ordering
    },
    setters: {
      setWriteIn,
      setTrueFalse,
      setMultipleChoice,
      setFillInTheBlank,
      setMultiSelect,
      setOrdering
    },
    switchbacks: switchbacks,
    setSwitchbacks: setSwitchbacks
  }
}

export function updateVariadicQuestionState(
  currentQuestionType: Q.QuestionType, newQuestionType: Q.QuestionType, v: VariadicQuestionState
) {
  const currentFunctionality = functionalityByType(currentQuestionType);
  const newFunctionality = functionalityByType(newQuestionType);
  const currentBody = currentFunctionality.resolveVariadicQuestionBody(v.state);
  const incumbentBody = newFunctionality.resolveVariadicQuestionBody(v.state);
  if (v.switchbacks.has(newFunctionality.questionType())) {
    newFunctionality.updateVariadicQuestionState(v, incumbentBody);
  // } else if (!newFunctionality.indistinguishableFromDerived(incumbentBody, currentBody)) {
  } else {
    v.switchbacks.add(currentQuestionType);
    v.setSwitchbacks(new Set(v.switchbacks));
    newFunctionality.updateVariadicQuestionState(v, currentBody);
  }
}

export function questionSetterConsideringSwitchbacks<T extends Q.QuestionBody>(
  setter: Setter<T>,
  switchbackSetter: (_: Set<Q.QuestionType>) => void
): Setter<T> {
  return (t: T) => {
    const qt: Q.QuestionType = functionalityByBody(t).questionType();
    switchbackSetter(new Set<Q.QuestionType>([qt]));
    setter(t);
  };
}

export function setVariadicQuestionState(q: Q.QuestionBody, v: VariadicQuestionState) {
  const s = v.setters;
  s.setWriteIn(C.toWriteIn(q));
  s.setTrueFalse(C.toTrueFalse(q));
  s.setMultipleChoice(C.toMultipleChoice(q));
  s.setFillInTheBlank(C.toFillInTheBlank(q));
  s.setMultiSelect(C.toMultiSelect(q));
  s.setOrdering(C.toOrdering(q));
}

export function indistinguishableFromDerived<T extends Q.QuestionBody>(
  currentBody: T, newBody: Q.QuestionBody, converter: (_: Q.QuestionBody) => T
): boolean {
  const derived = converter(newBody);
  return bodiesEqual(currentBody, derived);
}
