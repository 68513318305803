import * as Q from "./questionTypes";
import * as E from "../TextEditor";
import * as C from "./questionTypeConversions";
import * as SA from "./selectableAnswer";
import { GlossaryKey } from "language";
import * as O from "./orderingAnswer";
import { zip } from "utils";
import { listUndoSufficientDifference } from "undo";
import * as F from "./questionTypeFunctionality";

export type InvalidVariant = 'question-blank' |
                             'answer-blank' |
                             'duplicate' |
                             'no-incorrect-answers' |
                             'no-blanks' |
                             'no-answers' |
                             'less-than-two-answers' |
                             'no-active-partitions' |
                             'valid';

export function invalidityMessage(variant: InvalidVariant): GlossaryKey {
  switch (variant) {
    case 'question-blank': return 'questionBlankMessage';
    case 'answer-blank': return 'answerBlankMessage';
    case 'duplicate': return 'duplicateAnswerMessage';
    case 'no-incorrect-answers': return 'noIncorrectAnswersMessage';
    case 'no-blanks': return 'noBlanksMessage';
    case 'no-answers': return 'noAnswersMessage';
    case 'less-than-two-answers': return 'lessThanTwoAnswersMessage';
    case 'no-active-partitions': return 'noActivePartitionsMessage';
    case 'valid': return 'validMessage';
  }
}

type QuestionViability = {
  valid: boolean;
  invalidityVariant: InvalidVariant;
};

export function writeInValidity(body: Q.QuestionBody): InvalidVariant {
  let wi = body as Q.WriteIn;
  const answersFilledIn = allNonEmptyContent(wi.answers.map(a => a.content));
  const questionFilledIn = E.nonEmpty(wi.question);
  return writeInInvalidVariant(questionFilledIn, answersFilledIn);
}

export function multipleChoiceValidity(body: Q.QuestionBody): InvalidVariant {
  let mc = body as Q.MultipleChoice;
  const questionFilledIn = E.nonEmpty(mc.question);
  const correctFilledIn = SA.nonEmpty(mc.correctAnswer);
  const otherFilledIn = allNonEmpty(mc.otherAnswers);
  const notDuplicate = noDuplicates((mc.otherAnswers.concat([mc.correctAnswer])));
  const otherPresent = mc.otherAnswers.length > 0;
  return multipleChoiceInvalidVariant(questionFilledIn, correctFilledIn, otherFilledIn, notDuplicate, otherPresent);
}

export function trueFalseValidity(body: Q.QuestionBody): InvalidVariant {
  const tf = body as Q.TrueFalse;
  return trueFalseInvalidVariant(E.nonEmpty(tf.question));
}

export function fillInTheBlankValidity(body: Q.QuestionBody): InvalidVariant {
  const fb = body as Q.FillInTheBlank;
  const blanksPresent = fb.elements.length > 1;
  const answersFilledIn = fb.elements.reduce((acc, element) => {
    if (Q.isBlankElement(element)) {
      return acc && E.nonEmpty(element.answer);
    } else {
      return acc;
    }
  }, true);
  return fillInTheBlankInvalidVariant(blanksPresent, answersFilledIn);
}

export function multiSelectValidity(body: Q.QuestionBody): InvalidVariant {
  const ms = body as Q.MultiSelect;
  const questionFilledIn = E.nonEmpty(ms.question);
  const allAnswers = ms.correctAnswers.concat(ms.otherAnswers);
  const answersFilledIn = allNonEmpty(allAnswers);
  const notDuplicated = noDuplicates(allAnswers);
  const answersPresent = allAnswers.length > 0;
  return multiSelectInvalidVariant(questionFilledIn, answersPresent, answersFilledIn, notDuplicated);
}

export function orderingValidity(body: Q.QuestionBody): InvalidVariant {
  const o = body as Q.Ordering;
  const questionFilledIn = E.nonEmpty(o.question);
  const answers = o.order;
  const answersFilledIn = allNonEmpty(answers);
  const enoughAnswers = answers.length >= 2;
  const activePartitionPresent = o.inactivePartitions.length < answers.length - 1;
  return orderingInvalidVariant(questionFilledIn, answersFilledIn, enoughAnswers, activePartitionPresent);
}

function writeInInvalidVariant(questionFilledIn: boolean, answersFilledIn: boolean): InvalidVariant {
  if (!questionFilledIn) {
    return 'question-blank';
  } else if (!answersFilledIn) {
    return 'answer-blank';
  } else {
    return 'valid';
  }
}

function multipleChoiceInvalidVariant(
  questionFilledIn: boolean,
  correctAnswerFilledIn: boolean,
  otherAnswersFilledIn: boolean,
  noDuplicates: boolean,
  otherAnswersPresent: boolean
): InvalidVariant {
  if (!questionFilledIn) {
    return 'question-blank';
  } else if (!correctAnswerFilledIn || !otherAnswersFilledIn) {
    return 'answer-blank';
  } else if (!noDuplicates) {
    return 'duplicate';
  } else if (!otherAnswersPresent) {
    return 'no-incorrect-answers';
  } else {
    return 'valid';
  }
}

function trueFalseInvalidVariant(questionFilledIn: boolean): InvalidVariant {
  if (questionFilledIn) {
    return 'valid';
  } else {
    return 'question-blank';
  }
}

function fillInTheBlankInvalidVariant(blankPresent: boolean, answersFilledIn: boolean): InvalidVariant {
  if (!blankPresent) {
    return 'no-blanks';
  } else if (!answersFilledIn) {
    return 'answer-blank';
  } else {
    return 'valid';
  }
}

function multiSelectInvalidVariant(
  questionFilledIn: boolean,
  answersPresent: boolean,
  answersFilledIn: boolean,
  noDuplicates: boolean
): InvalidVariant {
  if (!questionFilledIn) {
    return 'question-blank';
  } else if (!answersPresent) {
    return 'no-answers';
  } else if (!answersFilledIn) {
    return 'answer-blank';
  } else if (!noDuplicates) {
    return 'duplicate';
  } else {
    return 'valid';
  }
}

function orderingInvalidVariant(
  questionFilledIn: boolean,
  answersFilledIn: boolean,
  enoughAnswers: boolean,
  activePartitionPresent: boolean
): InvalidVariant {
  if (!questionFilledIn) {
    return 'question-blank';
  } else if (!answersFilledIn) {
    return 'answer-blank';
  } else if (!enoughAnswers) {
    return 'less-than-two-answers';
  } else if (!activePartitionPresent) {
    return 'no-active-partitions'
  } else {
    return 'valid';
  }
}

function allNonEmptyContent(contents: E.Content[]): boolean {
  return contents.every(content => E.nonEmpty(content));
}

function allNonEmpty(answers: SA.SelectableAnswer[]): boolean {
  return answers.every(answer => SA.nonEmpty(answer));
}

function noDuplicates(strings: SA.SelectableAnswer[]): boolean {
  let cache = new Set<string>();
  let duplicate = false;
  strings.forEach(answer => {
    if (cache.has(SA.stringRepresentation(answer))) {
      duplicate = true;
    } else {
      cache.add(SA.stringRepresentation(answer));
    }
  });
  return !duplicate;
}

export function unifiedFillInTheBlank(question: Q.FillInTheBlank): string {
  return question.elements.reduce((acc, element) => {
    if (Q.isTextElement(element)) {
      return acc + E.stringRepresentation((element as Q.TextElement).text) + " ";
    } else {
      return acc + E.stringRepresentation((element as Q.BlankElement).answer) + " ";
    }
  }, "");
}

function writeInEqual(a: Q.WriteIn, b: Q.WriteIn): boolean {
  const questionsEqual = E.equals(a.question, b.question);
  const a_answers = new Map(a.answers.map(answer => [answer.position, answer]));
  return questionsEqual && b.answers.reduce((accumulator, b_answer) => {
    const a_answer = a_answers.get(b_answer.position);
    return accumulator && a_answer !== undefined && E.equals(a_answer.content, b_answer.content);
  }, true as boolean);
}

function trueFalseEqual(a: Q.TrueFalse, b: Q.TrueFalse): boolean {
  return E.equals(a.question, b.question) && a.validity === b.validity;
}

function selectableAnswerListsEqual(a: SA.SelectableAnswer[], b: SA.SelectableAnswer[]): boolean {
  const a_answers = new Set(a.map(answer => SA.stringRepresentation(answer)));
  return b.reduce((accumulator, answer) => {
    return accumulator && a_answers.has(SA.stringRepresentation(answer));
  }, true as boolean);
}

function multipleChoiceEqual(a: Q.MultipleChoice, b: Q.MultipleChoice): boolean {
  const questionEqual = E.equals(a.question, b.question);
  const correctEqual = SA.selectableAnswerEquals(a.correctAnswer, b.correctAnswer);
  const otherEqual = selectableAnswerListsEqual(a.otherAnswers, b.otherAnswers);
  return questionEqual && correctEqual && otherEqual;
}

function multiSelectEqual(a: Q.MultiSelect, b: Q.MultiSelect): boolean {
  const questionEqual = E.equals(a.question, b.question);
  const correctEqual = selectableAnswerListsEqual(a.correctAnswers, b.correctAnswers);
  const otherEqual = selectableAnswerListsEqual(a.otherAnswers, b.otherAnswers);
  return questionEqual && correctEqual && otherEqual;
}

function elementsEqual(a: Q.FillInTheBlankElement, b: Q.FillInTheBlankElement): boolean {
  if (Q.isTextElement(a) && Q.isTextElement(b)) {
    return E.equals(a.text, b.text);
  } else if (Q.isSuppliedElement(a) && Q.isSuppliedElement(b)) {
    return E.equals(a.suppliedAnswer, b.suppliedAnswer);
  } else if (Q.isBlankElement(a) && Q.isBlankElement(b)) {
    return E.equals(a.answer, b.answer);
  } else {
    return false;
  }
}

function fillInTheBlankEqual(a: Q.FillInTheBlank, b: Q.FillInTheBlank): boolean {
  return a.elements.length === b.elements.length && a.elements.reduce((accumulator, a_element, index) => {
    const b_element = b.elements[index];
    return accumulator && elementsEqual(a_element, b_element);
  }, true as boolean);
}

function orderingEqual(a: Q.Ordering, b: Q.Ordering): boolean {
  const questionEqual = E.equals(a.question, b.question);
  const answersEqual = a.order.length === b.order.length && zip(a.order, b.order).reduce((accumulator, [aa, ba]) => {
    return accumulator && O.orderingAnswerEquals(aa, ba);
  }, true as boolean);
  return questionEqual && answersEqual;
}

function questionTypesMatch(a: Q.QuestionBody, b: Q.QuestionBody): boolean {
  const typeDeterminants = [C.isWriteIn, C.isMultipleChoice, C.isMultiSelect, C.isTrueFalse, C.isFillInTheBlank];
  return typeDeterminants.reduce((acc, determinant) => {
    return acc || (determinant(a) && determinant(b))
  }, false as boolean);
}

export function bodiesEqual(a: Q.QuestionBody, b: Q.QuestionBody): boolean {
  if (questionTypesMatch(a, b)) {
    if (C.isWriteIn(a) && C.isWriteIn(b)) {
      return writeInEqual(a, b);
    } else if (C.isTrueFalse(a) && C.isTrueFalse(b)) {
      return trueFalseEqual(a, b);
    } else if (C.isMultipleChoice(a) && C.isMultipleChoice(b)) {
      return multipleChoiceEqual(a, b);
    } else if (C.isMultiSelect(a) && C.isMultiSelect(b)) {
      return multiSelectEqual(a, b);
    } else if (C.isFillInTheBlank(a) && C.isFillInTheBlank(b)) {
      return fillInTheBlankEqual(a, b);
    } else if (C.isOrdering(a) && C.isOrdering(b)) {
      return orderingEqual(a, b);
    } else {
      throw new Error("unrecognized question type")
    }
  } else {
    return false;
  }
}

export function duplicateAnswers(answers: SA.SelectableAnswer[]): Set<string> {
  let duplicates = new Set<string>();
  let seen = new Set<string>();
  answers.forEach((answer, index) => {
    let raw = SA.stringRepresentation(answer);
    if (seen.has(raw)) {
      duplicates.add(raw);
    }
    seen.add(raw);
  });
  return duplicates;
}

export function allMultipleChoiceAnswers(q: Q.QuestionBody): SA.SelectableAnswer[] {
  if (C.isMultipleChoice(q)) {
    return [q.correctAnswer].concat(q.otherAnswers);
  } else {
    throw new Error("non multiple choice")
  }
}

export function allMultiSelectAnswers(q: Q.QuestionBody): SA.SelectableAnswer[] {
  if (C.isMultiSelect(q)) {
    return q.correctAnswers.concat(q.otherAnswers);
  } else {
    throw new Error("non multi select")
  }
}

export function reorderOrdering(question: Q.Ordering, newOrder: O.OrderingAnswer[], setter: (_: Q.Ordering) => void) {
  const positioned = O.reposition(newOrder);
  setter({
    ...question,
    order: positioned
  });
}

function questionSufficientDifference(last: Q.TextualQuestion, current: Q.TextualQuestion): boolean {
  return E.undoSufficientDifference(last.question, current.question);
}

export function writeInUndoSufficientDifference(last: Q.WriteIn, current: Q.WriteIn): boolean {
  const questionDifference = questionSufficientDifference(last, current);
  const answerDifference = listUndoSufficientDifference(last.answers, current.answers, (a, b) => {
    return E.undoSufficientDifference(a.content, b.content);
  });
  return questionDifference || answerDifference;
}

export function trueFalseUndoSufficientDifference(last: Q.TrueFalse, current: Q.TrueFalse): boolean {
  const questionDifference = questionSufficientDifference(last, current);
  const answerDifference = last.validity !== current.validity;
  return questionDifference || answerDifference;
}

export function multipleChoiceUndoSufficientDifference(last: Q.MultipleChoice, current: Q.MultipleChoice): boolean {
  const questionDifference = questionSufficientDifference(last, current);
  const correctDifference = SA.selectableAnswerSufficientDifference(last.correctAnswer, current.correctAnswer);
  const incorrectDifference = SA.selectableAnswersSufficientDifference(last.otherAnswers, current.otherAnswers);
  return questionDifference || correctDifference || incorrectDifference;
}

export function multiSelectUndoSufficientDifference(last: Q.MultiSelect, current: Q.MultiSelect): boolean {
  const questionDifference = questionSufficientDifference(last, current);
  const correctDifference = SA.selectableAnswersSufficientDifference(last.correctAnswers, current.correctAnswers);
  const incorrectDifference = SA.selectableAnswersSufficientDifference(last.otherAnswers, current.otherAnswers);
  return questionDifference || correctDifference || incorrectDifference;
}

export function fillInTheBlankUndoSufficientDifference(last: Q.FillInTheBlank, current: Q.FillInTheBlank): boolean {
  return listUndoSufficientDifference(last.elements, current.elements, (a, b) => {
    return fillInTheBlankElementUndoSufficientDifference(a, b);
  });
}

function fillInTheBlankElementUndoSufficientDifference(last: Q.FillInTheBlankElement, current: Q.FillInTheBlankElement): boolean {
  if (Q.isTextElement(last) && Q.isTextElement(current)) {
    return E.undoSufficientDifference(last.text, current.text);
  } else if (Q.isBlankElement(last) && Q.isBlankElement(current)) {
    return E.undoSufficientDifference(last.answer, current.answer);
  } else if (Q.isSuppliedElement(last) && Q.isSuppliedElement(current)) {
    return E.undoSufficientDifference(last.suppliedAnswer, current.suppliedAnswer);
  }
  return true;
}

export function orderingUndoSufficientDifference(last: Q.Ordering, current: Q.Ordering): boolean {
  const questionDifference = questionSufficientDifference(last, current);
  const answerDifference = SA.selectableAnswersSufficientDifference(last.order, current.order);
  const lastInactivePartitions = new Set(last.inactivePartitions);
  const inactivePartitionDifference = last.inactivePartitions.length !== current.inactivePartitions.length ||
                                      current.inactivePartitions.some(ip => !lastInactivePartitions.has(ip));
  return questionDifference || answerDifference || inactivePartitionDifference;
}

export function blankCount(q: Q.FillInTheBlank): number {
  return q.elements.filter(e => Q.isBlankElement(e)).length;
}

export function partialCreditPossibleByQuestionType(questionType: Q.QuestionType): boolean {
  return questionType !== 'true-false' && questionType !== 'multiple-choice';
}

export function partialCreditPossible(body: Q.QuestionBody): boolean {
  if (C.isWriteIn(body)) {
    return (body as Q.WriteIn).answers.length > 1;
  }
  if (C.isMultiSelect(body)) {
    const ms = body as Q.MultiSelect;
    return ms.correctAnswers.length + ms.otherAnswers.length > 1;
  }
  if (C.isFillInTheBlank(body)) {
    return blankCount(body as Q.FillInTheBlank) > 1;
  }
  return partialCreditPossibleByQuestionType(F.functionalityByBody(body).questionType());
}
