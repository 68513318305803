import { ReactNode } from 'react';
import styles from "./SummarizedTable.module.scss";

type SummarizedRowProps = {
  open: boolean;
  aboveOpen: boolean;
  expandContract: () => void;
  additionalStyle?: Record<string, string>;
  additionalClasses?: string;
  summary: ReactNode;
  detail: ReactNode;
};

export function SummarizedRow(props: SummarizedRowProps) {
  const expandedClass = props.open ? styles['expanded-row'] : "";
  const aboveExpandedClass = props.aboveOpen ? styles['above-expanded-row'] : "";
  const rowClass = `${styles['summary-row']} ${expandedClass} ${aboveExpandedClass} ${props.additionalClasses || ""}`;
  const questionDetailClass = styles[props.open ? 'question-detail' : 'invisible-question-detail'];  
  return (
    <>
      <div className={rowClass} style={props.additionalStyle} onClick={props.expandContract}>
        {props.summary}
      </div>
      <div className={questionDetailClass}>
        {props.detail}
      </div>
    </>
  );
}

type SummarizedHeaderRowProps = {
  children: ReactNode;
  additionalClasses?: string;
}

export function SummarizedHeaderRow(props: SummarizedHeaderRowProps) {
  const className = `${styles['summary-header-row']} ${props.additionalClasses || ""}`;
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}