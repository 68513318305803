import { GlossaryKey, g, gpc } from "./language";

export const MONTHS: GlossaryKey[] = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

export const DAYS_PER_WEEK = 7;

export const MONTHS_PER_YEAR = 12;

export const DAYS_PER_MONTH: readonly number[] = [
  31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
] as const;

const WEEKDAY_ABBREVIATION_KEYS: readonly GlossaryKey[] = [
  'sundayAbbreviated', 'mondayAbbreviated', 'tuesdayAbbreviated', 'wednesdayAbbreviated', 'thursdayAbbreviated', 'fridayAbbreviated', 'saturdayAbbreviated'
] as const;

export function useNiceDateFromString(date: string): string {
  return useNiceDate(new Date(date));
}

export function useNiceDate(d: Date): string {
  const minutes = d.getMinutes();
  const minuteString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const hours = d.getHours();
  const hoursAdjusted = hours <= 12 ? hours : hours - 12;
  const am = g('anteMeridian');
  const pm = g('postMeridian');
  const meridian = hours <= 12 ? am : pm;
  const timeString = `${hoursAdjusted}:${minuteString} ${meridian}`;
  const month = g(MONTHS[d.getMonth()]);
  const day = d.getDate();
  if (sameDay(d)) {
    return `${g("today")} ${timeString}`;
  } else if (yesterday(d)) {
    return `${g("yesterday")} ${timeString}`;
  } else if (sameYear(d)) {
    return `${month} ${day}`
  } else {
    return `${month} ${day} ${d.getFullYear()}`;
  }
}

function sameDay(d1: Date): boolean {
  const d2 = new Date();
  return d1.getDate() === d2.getDate() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getFullYear() === d2.getFullYear();
}

function yesterday(d: Date): boolean {
  const d1 = new Date(d.getTime());
  d1.setDate(d.getDate() + 1);
  return sameDay(d1);
}

function sameYear(d: Date): boolean {
  return d.getFullYear() === new Date().getFullYear();
}

export function useTimeSinceDisplay(d: Date): string {
  const now = new Date();
  const yearDiff = now.getFullYear() - d.getFullYear();
  const monthDiff = now.getMonth() - d.getMonth();
  const dayDiff = now.getDate() - d.getDate();
  const yearString = yearDiff > 0 ? gpc('year', yearDiff) : "";
  const monthString = monthDiff > 0 ? gpc('month', monthDiff) : "";
  const onlyDayDifference = yearDiff > 0 || monthDiff > 0;
  let dayString = "";
  let agoString = g('ago');
  if (onlyDayDifference && dayDiff === 1) {
    dayString = g('yesterday');
    agoString = "";
  } else if (onlyDayDifference && dayDiff === 0) {
    dayString = g('today');
    agoString = "";
  } else {
    dayString = dayDiff > 0 ? gpc('day', dayDiff) : "";
  }
  return `${yearString} ${monthString} ${dayString} ${agoString}`;
}

export function useNiceDateAndTimeSince(d: Date): string {
  const dateDisplay = useNiceDate(d);
  const sinceDisplay = useTimeSinceDisplay(d);
  return `${dateDisplay}, ${sinceDisplay}`
}

export function useNiceDateAndTimeSinceFromString(s: string) {
  return useNiceDateAndTimeSince(new Date(s));
}

export function toStringNullable(date: Date | null): string | null {
  return date ? date.toISOString() : null;
}

export function fromStringNullable(raw: string | null | undefined): Date | null {
  return raw ? new Date(raw) : null;
}

export function numericDateByFormat(format: string, d: Date): string {
  return format.replace("DD", d.getDate().toString())
               .replace("MM", (d.getMonth() + 1).toString())
               .replace("YYYY", d.getFullYear().toString());
}

export function isLeapYear(y: number): boolean {
  return (y % 400 === 0) || (y % 4 === 0 && y % 100 !== 0);
}

export function daysPerMonthByYear(year: number | null) {
  if (year === null || isLeapYear(year)) {
    return DAYS_PER_MONTH;
  }
  var daysPerMonthNonLeapYear = Array.from(DAYS_PER_MONTH);
  daysPerMonthNonLeapYear[1] = 28;
  return daysPerMonthNonLeapYear;
}

export function useWeekdayAbbreviations(): string[] {
  return WEEKDAY_ABBREVIATION_KEYS.map(key => g(key));
}

export function dateEquals(a: Date, b: Date): boolean {
  return a.getTime() === b.getTime();
}

export function prepareDateForBackend(s: string | null): string | null {
  if (s === null) {
    return null;
  }
  return s.substring(0, s.length - 1);
}

export function clampDateStart(d: Date): Date {
  d.setHours(0, 0, 0);
  return d;
}

export function clampDateEnd(d: Date): Date {
  d.setHours(23, 59, 59);
  return d;
}
