import React from 'react';
import { useQuizContext } from "../quizContext";
import styles from "./ActiveQuizMessage.module.scss";
import { useGlossary as g } from "../language";
import { Link } from "react-router-dom";

export default function ActiveQuizMessage() {
  const quiz = useQuizContext();
  return quiz?.quizId ? (
    <>
      <div className={styles['active-quiz-warning']}>{g('activeQuizWarning')}</div>
      <Link to={`/quiz/${quiz.quizId}`}>{g('activeQuizLink')}</Link>
    </>
  ) : null;
}
