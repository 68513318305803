import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../user';
import styles from "./UserHomePage.module.scss";
import * as I from "../Icons";
import { HomePageContext } from "./homePageContext";
import { getHomePageContext, success, getToken } from "network";
import { g, gt, GlossaryKey } from "language";
import FormSection from "FormSection";

export default function UserHomePage() {
  console.log("get token in user home page", getToken());
  const userContext = useUser();
  const [homePageContext, setHomePageContext] = useState<HomePageContext | null>(null);
  useEffect(() => {
    (async () => {
      if (userContext.user) {
        const context = await getHomePageContext();
        if (success(context)) {
          setHomePageContext(context);
        }
      }
    })();
  }, []);
  return (
    <div className={styles['user-home']}>
      <h1>{gt('userHomePageGreeting', [userContext.user.userDisplayName() || ""])}</h1>
      <PointDisplay
        points={homePageContext?.totalPoints || 0.0}
        level={homePageContext?.level || 1}
        pointsToNext={homePageContext?.pointsToNextLevel || 0.0}
      />
      <CoreButtons/>
    </div>
  );
}

type PointDisplayProps = {
  points: number;
  level: number;
  pointsToNext: number;
}

function PointDisplay(props: PointDisplayProps) {
  return (
    <div className={styles['point-display-wrapper']}>
      <FormSection title={g('levelAndPoints')}>
        <span className={styles['level-label']}>{g('level')}</span>
        <span className={styles['level']}>{props.level}</span>
        <div className={styles['point-display']}>
          <PointLabel textKey={'pointsEarned'}/>
          <PointStat stat={props.points.toFixed(2)}/>
          <PointLabel textKey={'pointsToNextLevel'}/>
          <PointStat stat={props.pointsToNext.toFixed(2)}/>
        </div>
      </FormSection>
    </div>
  );
}

type PointLabelProps = {
  textKey: GlossaryKey;
};

function PointLabel(props: PointLabelProps) {
  return <span className={styles['points-earned-label']}>{g(props.textKey)}</span>;
}

type PointStatProps = {
  stat: string | number;
};

function PointStat(props: PointStatProps) {
  return <span className={styles['points-earned']}>{props.stat}</span>
}

export function CoreButtons() {
  const buttonStyle = styles['core-button'];
  const iconStyle = styles['core-button-icon'];
  return (
    <FormSection title={g('coreButtonsHeader')}>
      <div className={styles['core-button-wrapper']}>
        <Link className={buttonStyle} to="/new-question">
          <I.NewQuestionIcon className={iconStyle}/>
          <span>{g('newQuestionLink')}</span>
        </Link>
        <Link className={buttonStyle} to="/new-quiz">
          <I.NewQuizIcon className={iconStyle}/>
          <span>{g('takeQuizLink')}</span>
        </Link>
        <Link className={buttonStyle} to="/search-questions">
          <I.QuestionSearchIcon className={iconStyle}/>
          <span>{g('searchQuestionsLink')}</span>
        </Link>
      </div>
    </FormSection>
  );
}
