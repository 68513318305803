import styles from "./CheckBox.module.scss";
import { FiCheck } from "react-icons/fi";
import { defaultBoolean } from "logicUtils";

type CheckBoxProps = {
  selected: boolean;
  additionalClasses?: string;
  disabled?: boolean;
  onClick?: () => void;
  enhancedVisibility?: boolean;
}

export default function CheckBox(props: CheckBoxProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const baseClass = styles[props.selected ? 'check-box-selected' : 'check-box'];
  const disabledClass = disabled ? styles['disabled'] : '';
  const enhancedVisibility = defaultBoolean(props.enhancedVisibility, false) && !disabled ? styles['enhanced-visibility'] : '';
  const className = `${baseClass} ${props.additionalClasses || ""} ${disabledClass} ${enhancedVisibility}`;
  const onClick = () => {
    if (!disabled && props.onClick) {
      props.onClick();
    }
  }
  return (
    <span className={className} onClick={() => onClick()}>
      {props.selected && <FiCheck/>}
    </span>
  );
}
