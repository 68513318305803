import { ReactNode, Ref, useEffect } from 'react';

export default function useScrollable(callback: () => void, predicate: () => boolean, dependencies?: any[]) {
  useEffect(() => {
    function onScroll(event: Event) {
      if (predicate()) {
        callback();
      }
    }
    document.addEventListener("scroll", onScroll);
  }, dependencies);
}

export function useScrollableBottom(callback: () => void, dependencies?: any[]) {
  function predicate(): boolean {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  }
  useScrollable(callback, predicate, dependencies);
}
