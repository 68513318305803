import React, { useRef, useState } from 'react';
import * as T from "./questionTypes";
import styles from "./questionForms.module.scss";
import useFocus from "../focus";
import Toggle from "commonComponents/Toggle";
import { QuestionType } from "./questionTypes";
import { VariadicQuestionState } from "./variadicQuestionState";
import * as E from "../TextEditor";
import { useGlossary as g } from "../language";
import { AnswerLabel, OptionalAnswerLabelDisplay } from "labels";
import { AddButton, MinusButton } from "commonComponents/buttons";
import { PictureUploader, LabeledFile, extractHandles } from "../pictures";
import * as SA from "./selectableAnswer";
import OrderedList, { ReorderingOperation, ReorderingOperations, arrowAction } from "commonComponents/OrderedList";
import { duplicateAnswers, reorderOrdering } from "./questionOperations";
import { functionalityByBody } from "./questionTypeFunctionality";
import { OrderingAnswer, constructOrderingAnswer, reposition } from "./orderingAnswer";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import DOMPurify from 'dompurify';

export type QuestionFormProps = LabelProps & AnswerFileProps & {
  type: QuestionType;
  state: VariadicQuestionState;
}

export type LabelProps = {
  labelType: AnswerLabel | null;
}

type AnswerFileProps = {
  answerFiles: LabeledFile[];
  setAnswerFiles: (_: LabeledFile[], __: () => void) => void;
}

export type WriteInQuestionFormProps = LabelProps & {
  question: T.WriteIn;
  setQuestion: (_: T.WriteIn) => void;
}

export function WriteInQuestionForm(props: WriteInQuestionFormProps) {
  const changePosition = (index: number, operation: ReorderingOperation) => {
    arrowAction(index, operation, props.question.answers, answers => {
      props.setQuestion({
        ...props.question,
        answers
      });
    });
  };
  return (
    <>
      <div className={styles['vertical-layout']}>
        <QuestionLabel/>
        <E.Editor
          value={props.question.question}
          onChange={content => props.setQuestion({
            ...props.question,
            question: content
          })}/>
        <label>{g('answers')}</label>
        <OrderedList
          variant={'click'}
          operations={{ moveUp: true, moveDown: true }}
          onShift={changePosition}
          edgeAware={true}
          entries={props.question.answers.map((answer, index) => (
            <WriteInAnswerEditor
              question={props.question}
              setQuestion={props.setQuestion}
              labelType={props.labelType}
              index={index}
              answer={answer}
              key={index}
            />
          ))}
        />
      </div>
      <AddButton onClick={() => {
        props.setQuestion({
          ...props.question,
          answers: props.question.answers.concat({
            content: E.constructContent(),
            position: props.question.answers.length
          })
        });
      }}/>
    </>
  );
}

type WriteInAnswerEditorProps = WriteInQuestionFormProps & {
  index: number;
  answer: T.WriteInAnswer;
};

function WriteInAnswerEditor(props: WriteInAnswerEditorProps) {
  return (
    <ListAnswerEditor
      content={props.answer.content}
      index={props.index}
      labelType={props.labelType}
      onChange={content => {
        props.question.answers.splice(props.index, 1, {
          ...props.answer,
          content
        });
        props.setQuestion({ ...props.question });
      }}
      onDelete={() => {
        props.question.answers.splice(props.index, 1);
        props.setQuestion({ ...props.question });
      }}
      minusPresent={props.question.answers.length > 1}
    />
  )
}

type ListAnswerEditorProps = LabelProps & {
  content: E.Content;
  index: number;
  onChange: (_: E.Content) => void;
  onDelete: () => void;
  minusPresent: boolean;
}

function ListAnswerEditor(props: ListAnswerEditorProps) {
  return (
    <div className={styles['write-in-answer']}>
      <OptionalAnswerLabelDisplay position={props.index} labelType={props.labelType}/>
      <div className={styles['other-answer-wrapper']}>
        <E.Editor
          value={props.content}
          additionalClasses={styles['write-in-answer']}
          onChange={props.onChange}
        />
        {props.minusPresent ? <MinusButton onClick={props.onDelete}/> : null}
      </div>
    </div>
  );
}

type MultipleChoiceQuestionFormProps = AnswerFileProps & {
  question: T.MultipleChoice;
  setQuestion: (_: T.MultipleChoice) => void;
}

export function MultipleChoiceQuestionForm(props: MultipleChoiceQuestionFormProps) {
  const duplicates = duplicateAnswers(functionalityByBody(props.question).allSelectableAnswers(props.question));
  return (
    <>
      <div className={styles['vertical-layout']}>
        <QuestionLabel/>
        <E.Editor
          value={props.question.question}
          onChange={content => props.setQuestion({
            ...props.question,
            question: content
          })}
        />
        <label>{g('correctAnswer')}</label>
        <MultipleAnswer
          answer={props.question.correctAnswer}
          onChange={answer => props.setQuestion({
            ...props.question,
            correctAnswer: answer
          })}
          onDelete={() => {}}
          files={answerOwnedImages(props.question.correctAnswer, props.answerFiles)}
          setFiles={props.setAnswerFiles}
          duplicate={duplicates.has(SA.stringRepresentation(props.question.correctAnswer))}
        />
        <label>{g('incorrectAnswers')}</label>
        <MultipleAnswerList
          answers={props.question.otherAnswers}
          updateState={() => props.setQuestion({ ...props.question })}
          answerFiles={props.answerFiles}
          setAnswerFiles={props.setAnswerFiles}
          operations={{ moveUp: true, moveDown: false }}
          duplicates={duplicates}
          onArrow={index => {
            const answers = props.question.otherAnswers;
            const answer = answers[index];
            answers.splice(index, 1);
            props.question.otherAnswers = [props.question.correctAnswer].concat(answers);
            props.question.correctAnswer = answer;
            props.setQuestion({ ...props.question });
          }}
        />
      </div>
      <AddButton onClick={() => {
        props.setQuestion({
          ...props.question,
          otherAnswers: props.question.otherAnswers.concat([SA.constructSelectableAnswer()])
        });
      }}/>
    </>
  );
}

type MultipleAnswerProps = {
  answer: SA.SelectableAnswer;
  onChange: (_: SA.SelectableAnswer) => void;
  onDelete: () => void;
  files: LabeledFile[];
  setFiles: (_: LabeledFile[], __: () => void) => void;
  duplicate: boolean;
}

function MultipleAnswer(props: MultipleAnswerProps) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const duplicateText = g('duplicateAnswer');
  useFocus(ref);
  const outerClass = styles[props.duplicate ? 'duplicate-answer' : 'selectable-answer'];
  return (
    <div className={outerClass}>
      {props.duplicate ? <div className={styles['duplicate-text']}>{duplicateText}</div> : null}
      <div className={styles['other-answer-wrapper']}>
        <E.Editor
          reference={ref}
          additionalClasses={styles['other-answer']}
          value={props.answer.content}
          onChange={content => {
            props.onChange({
              ...props.answer,
              content: content
            })
          }}
        />
        <MinusButton onClick={props.onDelete}/>
      </div>
      <div className={styles['picture-uploader-wrapper']}>
        <PictureUploader
          files={props.files}
          setFiles={newFiles => {
            props.setFiles(newFiles, () => {
              props.onChange({
                ...props.answer,
                images: extractHandles(newFiles)
              });
            });
          }}
        />
      </div>
    </div>
  )
}

type MultipleAnswerListProps = AnswerFileProps & {
  answers: SA.SelectableAnswer[];
  updateState: () => void;
  operations: ReorderingOperations;
  onArrow: (_: number) => void;
  duplicates: Set<string>;
}

function MultipleAnswerList(props: MultipleAnswerListProps) {
  return (
    <OrderedList
      operations={props.operations}
      variant="click"
      onShift={(index, _) => props.onArrow(index)}
      edgeAware={false}
      entries={props.answers.map((answer, index) => (
        <MultipleAnswer
          key={index}
          answer={answer}
          onChange={value => {
            props.answers.splice(index, 1, value);
            props.updateState();
          }}
          onDelete={() => {
            props.answers.splice(index, 1);
            props.updateState();
          }}
          files={answerOwnedImages(answer, props.answerFiles)}
          setFiles={props.setAnswerFiles}
          duplicate={props.duplicates.has(SA.stringRepresentation(answer))}
        />
      ))}
    />
  )
}

function answerOwnedImages(answer: SA.SelectableAnswer, files: LabeledFile[]): LabeledFile[] {
  const answerImageIds = new Set(answer.images.map(image => image.id));
  return files.filter(image => answerImageIds.has(image.id));
}

export type FillInTheBlankQuestionFormProps = {
  question: T.FillInTheBlank;
  setQuestion: (question: T.FillInTheBlank) => void;
}

export function FillInTheBlankQuestionForm(props: FillInTheBlankQuestionFormProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [minusUsable, setMinusUsable] = useState(false);
  return (
    <div className={styles['fill-in-the-blank-layout']}>
      <div className={styles['fill-in-the-blank-content']}>
        <span className={styles['prompt']}>{g('fillInTheBlankPrompt')}</span>
        {props.question.elements.map((element, index) => {
          return T.isTextElement(element) ? (
            <TextElementInput
              value={(element as T.TextElement).text}
              key={index}
              index={index}
              setValue={value => {
                props.question.elements.splice(index, 1, { text: value });
              }}
              setIndex={index => {
                setActiveIndex(index);
                setMinusUsable(false);
              }}
            />
          ) : (
            <BlankElementInput
              index={index}
              value={(element as T.BlankElement).answer}
              key={index}
              setValue={value => {
                props.question.elements.splice(index, 1, { answer: value });
                props.setQuestion({ ...props.question });
              }}
              setIndex={index => {
                setActiveIndex(index);
                setMinusUsable(true);
              }}
            />
          );
        })}
      </div>
      <div className={styles['button-row']}>
        <AddButton
          extraClasses={styles['fill-in-the-blank-button']}
          onClick={() => {
            props.setQuestion({
              elements: props.question.elements.concat([
                { answer: E.constructContent() },
                { text: E.constructContent() }
              ])
            });
          }}/>
        <MinusButton
          enabled={minusUsable}
          classes={styles['fill-in-the-blank-button']}
          onClick={() => {
            if (activeIndex !== null && minusUsable) {
              let changePossible = true;
              const surroundText = (marginIndex: number) => {
                const element = props.question.elements[marginIndex];
                if (T.isTextElement(element)) {
                  return E.stringRepresentation((element as T.TextElement).text);
                } else {
                  changePossible = false;
                  return "";
                }
              }
              let prior = surroundText(activeIndex - 1);
              let after = surroundText(activeIndex + 1);
              if (changePossible) {
                const replacement = {
                  text: E.fromString(prior.concat(after))
                };
                props.question.elements.splice(activeIndex - 1, 3, replacement);
                props.setQuestion({ ...props.question });
              }
            }
          }}/>
      </div>
    </div>
  );
}

type ElementInputProps = {
  index: number;
  value: E.Content;
  setValue: (_: E.Content) => void;
  setIndex: (_: number) => void;
}

function TextElementInput(props: ElementInputProps) {
  const ref = useRef<HTMLSpanElement>(null);
  useFocus(ref, props.index === 0);
  const onChange = (event: any) => {
    props.setValue(E.fromString(event.target.innerHTML.replace('&nbsp;', ' ')));
  };
  return (
    <>
      <span className={styles['text-indicator']} onClick={() => {
        if (ref && ref.current) {
          ref!.current!.focus();
        }
      }}>_</span>
      <span
        ref={ref}
        className={styles['text-element']}
        contentEditable="true"
        onInput={onChange}
        onBlur={onChange}
        onFocus={() => props.setIndex(props.index)}
        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize('<script>alert("here")</script>'/*E.stringRepresentation(props.value)*/)}}
      ></span>
    </>
  )
}

function BlankElementInput(props: ElementInputProps) {
  const ref = useRef(null);
  useFocus(ref);
  return (
    <>
      <input
        ref={ref}
        className={styles['blank-element']}
        type="text"
        value={E.stringRepresentation(props.value)}
        onChange={event => props.setValue(E.fromString(event.target.value))}
        onFocus={() => props.setIndex(props.index)}
      />
    </>
  );
}

type TrueFalseQuestionProps = {
  question: T.TrueFalse;
  setQuestion: (question: T.TrueFalse) => void;
}

export function TrueFalseQuestionForm(props: TrueFalseQuestionProps) {
  return (
    <div className={styles['true-false-layout']}>
      <QuestionLabel/>
      <E.Editor
        value={props.question.question}
        onChange={content => {
          props.setQuestion({
            ...props.question,
            question: content
          });
        }}
      />
      <label>{g('answer')}</label>
      <div className={styles['validity-row']}>
        <label>{g('false')}</label>
        <Toggle on={props.question.validity} onClick={() => {
            props.setQuestion({
              ...props.question,
              validity: !props.question.validity
            })
          }}
        />
        <label>{g('true')}</label>
      </div>
    </div>
  )
}

type MultiSelectQuestionFormProps = AnswerFileProps & {
  question: T.MultiSelect;
  setQuestion: (_: T.MultiSelect) => void;
}

export function MultiSelectQuestionForm(props: MultiSelectQuestionFormProps) {
  const correct = props.question.correctAnswers;
  const incorrect = props.question.otherAnswers;
  const duplicates = duplicateAnswers(functionalityByBody(props.question).allSelectableAnswers(props.question));
  return (
    <div className={styles['vertical-layout']}>
      <QuestionLabel/>
      <E.Editor
        value={props.question.question}
        onChange={content => {
          props.setQuestion({
            ...props.question,
            question: content
          })
        }}/>
      <label>{g('correctAnswers')}</label>
      <MultipleAnswerList
        answers={props.question.correctAnswers}
        updateState={() => props.setQuestion({ ...props.question })}
        answerFiles={props.answerFiles}
        setAnswerFiles={props.setAnswerFiles}
        operations={{ moveDown: true }}
        duplicates={duplicates}
        onArrow={index => {
          const answer = correct[index];
          correct.splice(index, 1);
          props.question.otherAnswers = [answer].concat(incorrect);
          props.setQuestion({...props.question});
        }}
      />
      <AddButton onClick={() => {
        props.setQuestion({
          ...props.question,
          correctAnswers: props.question.correctAnswers.concat([SA.constructSelectableAnswer()])
        })
      }}/>
      <label>{g('incorrectAnswers')}</label>
      <MultipleAnswerList
        answers={props.question.otherAnswers}
        updateState={() => props.setQuestion({ ...props.question })}
        answerFiles={props.answerFiles}
        setAnswerFiles={props.setAnswerFiles}
        operations={{ moveUp: true }}
        duplicates={duplicates}
        onArrow={index => {
          const answer = incorrect[index];
          incorrect.splice(index, 1);
          props.question.correctAnswers = correct.concat([answer]);
          props.setQuestion({...props.question})
        }}
      />
      <AddButton onClick={() => {
        props.setQuestion({
          ...props.question,
          otherAnswers: props.question.otherAnswers.concat([SA.constructSelectableAnswer()])
        })
      }}/>
    </div>
  )
}

type OrderingQuestionFormProps = AnswerFileProps & LabelProps & {
  question: T.Ordering;
  setQuestion: (_: T.Ordering) => void;
};

export function OrderingQuestionForm(props: OrderingQuestionFormProps) {
  const inactivePartitions = new Set(props.question.inactivePartitions);
  const questionRef = useRef<T.Ordering>(props.question);
  questionRef.current = props.question;
  const togglePartition = (partition: number) => {
    return () => {
      if (inactivePartitions.has(partition)) {
        inactivePartitions.delete(partition);
        props.question.inactivePartitions = props.question.inactivePartitions.filter(p => p !== partition);
      } else {
        inactivePartitions.add(partition);
        props.question.inactivePartitions = props.question.inactivePartitions.concat([partition]);
      }
      props.setQuestion({ ...props.question });
    };
  };
  const onShift = (index: number, operation: ReorderingOperation) => {
    arrowAction(index, operation, questionRef.current.order, newOrder => {
      reorderOrdering(questionRef.current, newOrder, props.setQuestion);
    });
  }
  return (
    <div className={styles['vertical-layout']}>
      <QuestionLabel/>
      <E.Editor
        value={props.question.question}
        onChange={content => {
          props.setQuestion({
            ...props.question,
            question: content
          })
        }}
      />
      <label>{g('answers')}</label>
      <OrderedList
        operations={{moveUp: true, moveDown: true}}
        edgeAware={true}
        variant={'responsive'}
        onShift={onShift}
        entries={props.question.order.map((answer, index) => (
          <OrderingAnswerEditor
            answer={answer}
            labelType={props.labelType}
            index={index}
            question={props.question}
            setQuestion={props.setQuestion}
            files={answerOwnedImages(answer, props.answerFiles)}
            partitionActive={!inactivePartitions.has(answer.position)}
            togglePartition={togglePartition(answer.position)}
            lastAnswer={index === props.question.order.length - 1}
            setFiles={files => {
              props.setAnswerFiles(files, () => {
                props.question.order.splice(index, 1, {
                  ...answer,
                  images: files
                });
                props.setQuestion({ ...props.question });
              })
            }}
          />
        ))}
      />
      <AddButton
        onClick={() => {
          props.setQuestion({
            ...props.question,
            order: props.question.order.concat([constructOrderingAnswer(props.question.order.length)])
          })
        }}
      />
    </div>
  )
}

type OrderingAnswerEditorProps = {
  answer: OrderingAnswer;
  labelType: AnswerLabel | null;
  index: number;
  question: T.Ordering;
  setQuestion: (_: T.Ordering) => void;
  files: LabeledFile[];
  setFiles: (_: LabeledFile[]) => void;
  partitionActive: boolean;
  togglePartition: () => void;
  lastAnswer: boolean;
}

function OrderingAnswerEditor(props: OrderingAnswerEditorProps) {
  return (
    <div className={styles['ordering-answer']}>
      <ListAnswerEditor
        labelType={props.labelType}
        index={props.index}
        content={props.answer.content}
        onChange={content => {
          props.question.order.splice(props.index, 1, {
            ...props.answer,
            content
          })
          props.setQuestion({ ...props.question })
        }}
        onDelete={() => {
          props.question.order.splice(props.index, 1);
          props.setQuestion({ ...props.question });
        }}
        minusPresent={props.question.order.length > 2}
      />
      <div className={styles['ordering-pictures']}>
        <PictureUploader
          files={props.files}
          setFiles={props.setFiles}
        />
      </div>
      {props.lastAnswer ? null : (
        <OrderingPartition
          active={props.partitionActive}
          toggle={props.togglePartition}
        />
      )}
    </div>
  );
}

type OrderingPartitionProps = {
  toggle: () => void;
  active: boolean;
};

function OrderingPartition(props: OrderingPartitionProps) {
  const iconClass = styles[props.active ? 'partition-icon' : 'inactive-partition-icon'];
  const icon = props.active ? <AiOutlineMinus className={iconClass}/> : <AiOutlinePlus className={iconClass}/>;
  const lineColor = props.active ? "black" : "gray";
  const strokeWidth = props.active ? "3px" : "1px";
  return (
    <div className={styles['ordering-partition']} onClick={props.toggle}>
      <svg width="100%" height="100%" className={styles['partition-line']}>
        <line stroke={lineColor} x1="0%" x2="100%" y1="50%" y2="50%" stroke-width={strokeWidth}/>
        <circle stroke={lineColor} cx="50%" cy="50%" r="15" fill="white" stroke-width={strokeWidth}/>
      </svg>
      <div className={styles['partition-icon-wrapper']}>
        {icon}
      </div>
    </div>
  );
}

function QuestionLabel() {
  return <label>{g('question')}</label>;
}
