import { IndexLimits } from "./computation";

export type ItemId<R> = (_: R) => string;
export type QueryKey<Q> = (_: Q) => string;
type IdentifiedRows = Map<number, string>;
type QueryRowResolver = Map<string, IdentifiedRows>;
type ReferenceCache<R> = Map<string, R>;
type ReferencingQueries<Q> = Map<string, Q[]>;
type QueryMetadata<M> = Map<string, M>;
type RowCount = Map<string, number>;
const DOES_NOT_EXIST = 'does-not-exist';

export class TableCache<R, Q, M> {
  itemId: ItemId<R>;
  queryKey: QueryKey<Q>;
  queryResults: QueryRowResolver;
  references: ReferenceCache<R>;
  referencing: ReferencingQueries<Q>;
  queryMetadata: QueryMetadata<M>;
  totalRowCounts: RowCount;

  constructor(
    id: ItemId<R>,
    key: QueryKey<Q>,
    initialQueryResults?: QueryRowResolver,
    initialReferences?: ReferenceCache<R>,
    initialReferencing?: ReferencingQueries<Q>,
    initialMetadata?: QueryMetadata<M>,
    initialRowCounts?: RowCount
  ) {
    this.itemId = id;
    this.queryKey = key;
    this.queryResults = initialQueryResults || new Map();
    this.references = initialReferences || new Map();
    this.referencing = initialReferencing || new Map();
    this.queryMetadata = initialMetadata || new Map();
    this.totalRowCounts = initialRowCounts || new Map();
  }

  copySelf(): TableCache<R, Q, M> {
    return new TableCache(
      this.itemId,
      this.queryKey,
      this.queryResults,
      this.references,
      this.referencing,
      this.queryMetadata,
      this.totalRowCounts
    );
  }

  addRange(query: Q, range: IndexLimits, rows: R[], metadata: M, total: number): TableCache<R, Q, M> {
    const queryCache = this.queryRowsOrInitialize(query);
    const key = this.queryKey(query);
    this.queryMetadata.set(key, metadata);
    this.totalRowCounts.set(key, total);
    rows.forEach((row, index) => {
      const rowId = this.itemId(row);
      queryCache.set(range.startIndex + index, rowId);
      this.references.set(rowId, row);
    });
    for (let i = range.startIndex + rows.length; i < range.endIndex; i++) {
      queryCache.set(i, DOES_NOT_EXIST);
    }
    return this.copySelf();
  }

  queryRowsOrInitialize(query: Q): IdentifiedRows {
    const key = this.queryKey(query);
    if (!this.queryResults.get(key)) {
      this.queryResults.set(key, new Map());
    }
    return this.queryResults.get(key)!;
  }

  queryRows(query: Q): IdentifiedRows | undefined {
    return this.queryResults.get(this.queryKey(query));
  }

  metadata(query: Q): M | undefined {
    return this.queryMetadata.get(this.queryKey(query)) || undefined;
  }

  rows(query: Q, limits: IndexLimits): R[] | null {
    if (this.loadRequired(query, limits)) {
      return null;
    }
    let rows: R[] = [];
    const queryCache = this.queryRows(query);
    if (!queryCache) {
      return null;
    }
    for (let i = limits.startIndex; i < limits.endIndex; i++) {
      if (queryCache.has(i)) {
        const identifier = queryCache.get(i);
        const row = identifier && this.references.get(identifier);
        if (row) {
          rows.push(row);
        }
      }
    }
    return rows;
  }

  queriesReferencingRow(row: R): Q[] {
    return this.referencing.get(this.itemId(row)) || [];
  }

  updateRow(row: R): TableCache<R, Q, M> {
    const id = this.itemId(row);
    this.references.set(id, row);
    this.queriesReferencingRow(row).forEach(query => {
      this.queryResults.delete(this.queryKey(query));
    });
    this.referencing.delete(id);
    return this.copySelf();
  }

  size(query: Q): number {
    return this.queryRowsOrInitialize(query).size;
  }

  loadRequired(query: Q, limits: IndexLimits) {
    const queryCache = this.queryRows(query);
    if (!queryCache) {
      return true;
    }
    for (let i = limits.startIndex; i < limits.endIndex; i++) {
      if (!queryCache.has(i)) {
        return true;
      }
    }
  }

  totalRows(query: Q): number {
    return this.totalRowCounts.get(this.queryKey(query)) || 0;
  }
}
