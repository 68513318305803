import { Question, QuestionType, QUESTION_TYPE_COUNT } from "questionTypes";
import { Tag } from "tags";
import { LogicalPattern } from "commonComponents/LogicalPatternSelector";
import { Content } from "TextEditor";
import { IndexLimits } from "PartialLoadTable";
import { QuestionEditContext, QuestionContext } from "questionContext";
import { GlossaryKey } from 'language';
import { prepareDateForBackend } from "timeUtils";
import { typedKeys } from "logicUtils";
import { SettingsSearchModel, Setting } from "QuestionSettings";

export type QuestionSearchPayload = {
  parameters: QuestionSearchParameters;
  indexLimits: IndexLimits | null;
};

export type QuestionSearchParameters = {
  dateSearch: DateSearchParameters;
  questionTypes: QuestionType[] | null;
  tagSearch: TagSearchParameters | null;
  componentPresence: ComponentPresence;
  settingsSearch: SettingsSearch;
  textSearch: TextSearch;
};

export type LocalCacheSearch = Omit<QuestionSearchParameters, 'settingsSearch'> & {
  settingsSearch: SettingsSearchModel;
  includedSettings: Setting[]
};

export function serializeQuestionSearchParameters(q: QuestionSearchParameters) {
  const tagString = q.tagSearch?.tagIds.sort().join("+") || "no tags";
  const excludedTagString = q.tagSearch?.excludedTagIds.sort().join("+") || "no excluded tags";
  const questionTypeString = q.questionTypes?.sort().join("+") || "no question types";
  const componentPresenceString = serializeObject(q.componentPresence);
  const settingsString = serializeObject(q.settingsSearch);
  const ts = q.textSearch;
  const textString = `${ts.texts.sort().join("+")}#${ts.inclusionPattern}#${ts.ignoreCase}#${ts.allVersions}`;
  return [
    q.dateSearch.earliestDateString,
    q.dateSearch.latestDateString,
    q.dateSearch.dateVariant,
    tagString,
    excludedTagString,
    q.tagSearch?.tagPattern || "no tag pattern",
    questionTypeString,
    componentPresenceString,
    settingsString,
    textString
  ].join("|");
}

function serializeObject<T extends object>(t: T): string {
  return typedKeys(t).map(v => `${v as string}=${JSON.stringify(t[v])}`).join("+");
}

export function searchParametersEqual(
  a: QuestionSearchParameters,
  b: QuestionSearchParameters
): boolean {
  return serializeQuestionSearchParameters(a) === serializeQuestionSearchParameters(b);
}

export function prepareForBackend(qsp: QuestionSearchParameters): QuestionSearchParameters {
  return {
    ...qsp,
    dateSearch: prepareDateSearchForBackend(qsp.dateSearch)
  };
}

export type DateSearchParameters = {
  earliestDateString: string | null;
  latestDateString: string | null;
  dateVariant: QuestionDateVariant;
}

function prepareDateSearchForBackend(dsp: DateSearchParameters): DateSearchParameters {
  return {
    ...dsp,
    earliestDateString: prepareDateForBackend(dsp.earliestDateString),
    latestDateString: prepareDateForBackend(dsp.latestDateString)
  };
}

export type TagSearchParameters = {
  tagPattern: LogicalPattern;
  tagIds: string[];
  excludedTagIds: string[];
};

export type QuestionPartialLoad = {
  questions: QuestionContext[];
  totalResultCount: number;
  textMatchContext: TextMatchContext;
};

type TextMatchContext = {
  matches: SingleTextMatch[];
};

type SingleTextMatch = {
  questionId: string;
  questionVersion: number;
  searchText: string;
  noteMatch: ContentMatch | null;
};

type ContentMatch = {
  locations: TextLocalizer[];
};

type TextLocalizer = {
  start: number;
  end: number;
};

export type SearchMetadata = {
  totalRows: number;
};

export function effectiveQuestionTypeSearch(qts: Set<QuestionType>): QuestionType[] | null {
  if (qts.size === QUESTION_TYPE_COUNT) {
    return null;
  } else {
    return Array.from(qts.values());
  }
}

export const QUESTION_DATE_VARIANTS: readonly GlossaryKey[] = [
  'questionCreatedDateVariant',
  'questionEditedDateVariant',
  'questionLastEditedDateVariant',
  'questionFirstTakenInQuizDateVariant',
  'questionTakenInQuizDateVariant',
  'questionLastTakenInQuizDateVariant',
  'questionEditedOrTakenInQuizDateVariant'
] as const;

export type QuestionDateVariant = typeof QUESTION_DATE_VARIANTS[number];

export type ComponentPresence = {
  hasHints: boolean | null;
  hasImages: boolean | null;
  hasNotes: boolean | null;
};

export type ComponentPresenceKey = keyof ComponentPresence;

export function defaultComponentPresence(): ComponentPresence {
  return {
    hasHints: null,
    hasImages: null,
    hasNotes: null
  };
}

export type SettingsSearch = Partial<SettingsSearchModel>;

export function partializeSettingsSearch(m: SettingsSearchModel, includedSettings: Set<Setting>): SettingsSearch {
  const copy = { ...m } as SettingsSearch;
  typedKeys(m).forEach(k => {
    if (!includedSettings.has(k)) {
      copy[k] = undefined;
    }
  });
  return copy;
}

export type TextSearch = {
  texts: string[];
  inclusionPattern: LogicalPattern;
  ignoreCase: boolean;
  allVersions: boolean;
};

export function defaultTextSearch(): TextSearch {
  return {
    texts: [''],
    inclusionPattern: 'any',
    ignoreCase: true,
    allVersions: false
  };
}
