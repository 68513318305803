export type ResultOrStatus<T> = T | StatusIndicator;

export type StatusIndicator = {
  status: number;
};

export function success<T>(r: ResultOrStatus<T>): r is T {
  return !!r && (r as StatusIndicator).status === undefined;
}

export function error<T>(r: ResultOrStatus<T>): r is StatusIndicator {
  return (r as StatusIndicator).status >= 0;
}
