import React, { ReactNode, useRef } from 'react';
import QuizQuestionBox from "./QuizQuestionBox";
import * as E from "../TextEditor";
import * as Z from "../quiz";
import * as QS from "../QuestionSettings";
import { OptionalAnswerLabelDisplay, AnswerLabel } from "labels";
import styles from "./quizQuestionForms.module.scss";
import * as QP from "../QuizPage";
import * as Q from "../questionTypes";
import Toggle from "commonComponents/Toggle";
import { LabeledFileHandle, ImageHandleDisplay } from "../pictures";
import { useGlossary as g } from "../language";
import * as B from "questionBodyDisplay";
import OrderedList, { ReorderingOperation, arrowAction } from "commonComponents/OrderedList";
import CheckBox from "commonComponents/CheckBox";

export type QuestionLocation = 'modal' | 'page';

export type QuizQuestionFormProps = QP.ResponseStateProps & {
  question: Z.QuizQuestion;
  flagged: boolean;
  toggleFlagged: () => void;
  hintsRevealed: number;
  revealNextHint: () => void;
  highlight: boolean;
  location: QuestionLocation;
}

export function WriteInQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.MultipleInputPrompt;
  const questionIndex = props.question.index;
  const settings = props.question.settings as QS.WriteInModel;
  const labelType = settings.labelType;
  const inputCount: number = Z.writeInInputs(body, settings);
  const response = props.getResponse(questionIndex) as Z.WriteInResponse;
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <>
          {response.answers.map((answer, index) => (
            <WriteInAnswer
              key={index}
              answer={answer.content}
              onChange={content => {
                response.answers.splice(index, 1, {
                  content,
                  position: index
                });
                props.updateResponse(questionIndex, response);
              }}
              index={index}
              labelType={labelType}
            />
          ))}
        </>
      }
    />
  );
}

type WriteInAnswerProps = {
  answer: E.Content;
  onChange: (_: E.Content) => void;
  index: number;
  labelType: AnswerLabel | null;
};

function WriteInAnswer(props: WriteInAnswerProps) {
  return (
    <div className={styles['write-in-answer-wrapper']}>
      <OptionalAnswerLabelDisplay position={props.index} labelType={props.labelType}/>
      <E.Editor
        value={props.answer}
        onChange={props.onChange}
      />
    </div>
  );
}

export function MultipleChoiceQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.SelectionPrompt;
  const settings = props.question.settings as QS.MultipleChoiceModel;
  const labelType = settings.labelType;
  const questionId = props.question.questionId;
  const questionIndex = props.question.index;
  const selection = props.getResponse(questionIndex) as Z.MultipleChoiceResponse;
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      revealNextHint={props.revealNextHint}
      hintsRevealed={props.hintsRevealed}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <div>
          {body.answers.map((answer, index) => (
            <MultipleChoiceOption
              key={index}
              answer={answer}
              index={index}
              labelType={labelType}
              onSelect={() => {
                const mcValue: Z.MultipleChoiceResponse = {
                  selection: Q.stringRepresentation(answer)
                };
                props.updateResponse(questionIndex, mcValue);
              }}
              selected={selection.selection !== null && Q.stringRepresentation(answer) === selection.selection}
            />
          ))}
        </div>
      }
    />
  );
}

function clampLabel(labelType: AnswerLabel | null): AnswerLabel {
  return labelType === null ? 'uppercaseLabel' : labelType;
}

type OptionProps = {
  answer: Q.SelectableAnswer;
  index: number;
  labelType: AnswerLabel | null;
  onSelect: () => void;
  selected: boolean;
};

function MultipleChoiceOption(props: OptionProps) {
  return (
    <MultiOption selected={props.selected} onSelect={props.onSelect} images={props.answer.images}>
      <RadioButton selected={props.selected}/>
      <QuizFormLabel position={props.index} labelType={props.labelType}/>
      <E.TextDisplay value={props.answer.content}/>
    </MultiOption>
  );
}

type MultiOptionProps = {
  selected: boolean;
  onSelect: () => void;
  children: ReactNode;
  images: LabeledFileHandle[];
};

function MultiOption(props: MultiOptionProps) {
  const className = styles[props.selected ? 'option-selected' : 'option'];
  return (
    <div className={className} onClick={props.onSelect}>
      <div className={styles['option-answer-wrapper']}>
        {props.children}
      </div>
      <ImageHandleDisplay handles={props.images} below={true}/>
    </div>
  )
}

type RadioButtonProps = {
  selected: boolean;
};

function RadioButton(props: RadioButtonProps) {
  const outerClass = styles[props.selected ? 'radio-button-selected' : 'radio-button'];
  const innerClass = styles[props.selected ? 'radio-button-inner-selected' : 'radio-button-inner'];
  return (
    <span className={outerClass}>
      <span className={innerClass}></span>
    </span>
  );
}

export function MultiSelectQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.SelectionPrompt;
  const settings = props.question.settings as QS.MultiSelectModel;
  const labelType = settings.labelType;
  const questionIndex = props.question.index;
  const selections = props.getResponse(questionIndex) as Z.MultiSelectResponse;
  const selected = (answer: Q.SelectableAnswer) => {
    return selections.selections.has(Q.stringRepresentation(answer));
  }
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <>
          {body.answers.map((answer, index) => (
            <MultiSelectOption
              key={index}
              index={index}
              labelType={labelType}
              answer={answer}
              selected={selected(answer)}
              onSelect={() => {
                const raw = Q.stringRepresentation(answer);
                if (selections.selections.has(raw)) {
                  selections.selections.delete(raw);
                } else {
                  selections.selections.add(raw);
                }
                props.updateResponse(questionIndex, selections);
              }}
            />
          ))}
      </>}
    />
  );
}

type QuizFormLabelProps = {
  labelType: AnswerLabel | null;
  position: number;
};

function QuizFormLabel(props: QuizFormLabelProps) {
  return (
    <div className={styles['label-wrapper']}>
      <OptionalAnswerLabelDisplay labelType={props.labelType} position={props.position}/>
    </div>
  )
}

function MultiSelectOption(props: OptionProps) {
  return (
    <MultiOption selected={props.selected} onSelect={props.onSelect} images={props.answer.images}>
      <CheckBox selected={props.selected}/>
      <QuizFormLabel labelType={props.labelType} position={props.index}/>
      <E.TextDisplay value={props.answer.content}/>
    </MultiOption>
  );
}

export function FillInTheBlankQuizQuestion(props: QuizQuestionFormProps) {
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.FillInTheBlankResponse;
  const body = response.structure;
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={null}
      revealNextHint={props.revealNextHint}
      hintsRevealed={props.hintsRevealed}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <B.FillInTheBlankOutlineDisplay
          structure={body}
          singleLine={false}
          blankResolver={spec => {
            const onChange = (content: E.Content) => {
              body.elements.splice(spec.index, 1, {
                answer: content
              } as Q.BlankElement);
              props.updateResponse(questionIndex, {
                structure: body
              } as Z.FillInTheBlankResponse);
            };
            return <BlankElementForm content={spec.blank.answer} onChange={onChange} key={spec.key} singleLine={false}/>
          }
        }/>}
    />
  );
}

type BlankElementProps = B.ElementProps & {
  onChange: (_: E.Content) => void;
};

function BlankElementForm(props: BlankElementProps) {
  return (
    <input
      type="text"
      value={E.stringRepresentation(props.content)}
      className={styles['blank-element']}
      onChange={event => props.onChange(E.fromString(event.target.value))}
    />
  );
}

export function TrueFalseQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.InputPrompt;
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.TrueFalseResponse;
  const falseSelected = response.option === false;
  const trueSelected = response.option === true;
  const onClick = (b: boolean) => props.updateResponse(questionIndex, { option: b })
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      questionSlot={<E.TextDisplay value={body.question}/>}
      answerSlot={
        <B.TrueFalseRow
          onClick={onClick}
          variant={'indefinite'}
          falseSelected={falseSelected}
          trueSelected={trueSelected}
        />
      }
    />
  );
}

export function OrderingQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.OrderingPrompt;
  const settings = props.question.settings as QS.OrderingModel;
  const labelType = settings.labelType;
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.OrderingResponse;
  const responseRef = useRef<Z.OrderingResponse>(response);
  responseRef.current = response;
  const onShift = (index: number, operation: ReorderingOperation) => {
    arrowAction(index, operation, responseRef.current.order, newOrder => {
      const positioned = Q.reposition(newOrder);
      props.updateResponse(questionIndex, {
        order: positioned
      });
    });
  };
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      questionSlot={<E.TextDisplay value={body.question}/>}
      answerSlot={
        <OrderedList
          operations={{moveUp: true, moveDown: true}}
          edgeAware={true}
          variant={'responsive'}
          onShift={onShift}
          entries={responseRef.current.order.map((answer, index) => (
            <OrderingAnswer labelType={labelType} position={index} content={answer.content}/>
          ))}
          extraEntryClasses={styles['ordering-answer-wrapper']}
          extraDropZoneClasses={styles['ordering-answer-drop-zone']}
        />
      }
    />
  );
}

type OrderingAnswerProps = {
  labelType: AnswerLabel | null;
  position: number;
  content: E.Content;
};

function OrderingAnswer(props: OrderingAnswerProps) {
  return (
    <div className={styles['quiz-ordering-answer']}>
      <QuizFormLabel labelType={props.labelType} position={props.position}/>
      <E.TextDisplay value={props.content}/>
    </div>
  );
}
