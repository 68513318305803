import { useState, useMemo, useEffect } from 'react';
import styles from "./DateSelector.module.scss";
import { GlossaryKey, g, gt } from "language";
import Toggle from "commonComponents/Toggle";
import * as T from "timeUtils";
import * as D from "../dateParsing";
import { IoCaretBackOutline, IoPlayBack, IoCaretForward, IoPlayForward } from "react-icons/io5";
import { range, mod } from "utils";
import * as U from "./utils";
import { useRangeComplement } from "../DateRangeSelector";

export type DateStateProps = {
  date: Date | null;
  setDate: (_: Date | null) => void;
};

type DateSelectorProps = {
  labelKey: GlossaryKey;
  defaultDescriptorKey: GlossaryKey;
  minDate: Date;
  maxDate: Date;
  fallbackDate: Date;
  errorSignifier?: GlossaryKey;
  additionalClasses?: string;
} & DateStateProps;

export default function DateSelector(props: DateSelectorProps) {
  const errorText = g(props.errorSignifier || 'blank');
  const className = `${styles['date-selector']} ${props.additionalClasses || ''}`;
  return (
    <div className={className}>
      <div className={styles['header-row']}>
        <label className={styles['date-selector-label']}>{g(props.labelKey)}</label>
        {props.errorSignifier ? <span className={styles['error-text']}>{errorText}</span> : null}
      </div>
      <ResetToDefault
        defaultDescriptorKey={props.defaultDescriptorKey}
        date={props.date}
        setDate={props.setDate}
        fallbackDate={props.fallbackDate}
      />
      <TextualDate
        date={props.date}
        setDate={props.setDate}
        defaultDescriptorKey={props.defaultDescriptorKey}
        minDate={props.minDate}
        maxDate={props.maxDate}
        fallbackDate={props.fallbackDate}
      />
      <CalendarDateSelector
        date={props.date}
        setDate={props.setDate}
        fallbackDate={props.fallbackDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </div>
  );
}

type ResetToDefaultProps = {
  defaultDescriptorKey: GlossaryKey;
  date: Date | null;
  setDate: (_: Date | null) => void;
  fallbackDate: Date;
};

function ResetToDefault(props: ResetToDefaultProps) {
  const format = g('dateFormat');
  return (
    <div className={styles['default-wrapper']}>
      <button
        className={styles['action-button']}
        onClick={() => props.setDate(null)}
      >{g('resetButton')}</button>
      <label className={styles['reset-label']}>
        {`${g('resetDefault')} (${g(props.defaultDescriptorKey)}, ${T.numericDateByFormat(format, props.fallbackDate)})`}
      </label>
    </div>
  )
}

type TextualDateProps = {
  date: Date | null;
  setDate: (_: Date | null) => void;
  defaultDescriptorKey: GlossaryKey;
  minDate: Date;
  maxDate: Date;
  fallbackDate: Date;
};

function TextualDate(props: TextualDateProps) {
  const defaultDateText = g(props.defaultDescriptorKey);
  const [month, setMonth] = useState<number | null>((props.date?.getMonth() || props.fallbackDate.getMonth()) + 1);
  const [day, setDay] = useState<number | null>(props.date?.getDate() || props.fallbackDate.getDate());
  const [year, setYear] = useState<number | null>(props.date?.getFullYear() || props.fallbackDate.getFullYear());
  useEffect(() => {
    const effectiveDate = props.date || props.fallbackDate;
    setMonth(effectiveDate.getMonth() + 1);
    setDay(effectiveDate.getDate());
    setYear(effectiveDate.getFullYear());
  }, [props.date]);
  const partialDate = { month, day, year };
  const maxYear = props.maxDate.getFullYear();
  const minYear = props.minDate.getFullYear();
  const yearValid = (y: number): boolean => {
    return y <= maxYear;
  };
  const updateDate = (year: number | null, month: number | null, day: number | null) => {
    if (D.fullySpecified({ year, month, day }) && U.dateInRange(new Date(year!, month!, day!), props.minDate, props.maxDate)) {
      props.setDate(new Date(year!, month! - 1, day!));
    }
  }
  const updateMonth = (raw: string) => {
    const newMonth = D.parseNumber(raw);
    if (newMonth === null || D.monthValid(newMonth)) {
      setMonth(newMonth);
      const salvagedDay = D.salvageDay({ day, year, month: newMonth });
      setDay(salvagedDay);
      updateDate(year, newMonth, salvagedDay);
    }
  };
  const updateYear = (raw: string) => {
    const newYear = D.parseNumber(raw);
    if (newYear === null || yearValid(newYear)) {
      setYear(newYear);
      const salvagedDay = D.salvageDay({ day, month, year: newYear})
      setDay(salvagedDay);
      updateDate(newYear, month, salvagedDay);
    }
  };
  const updateDay = (raw: string) => {
    const newDay = D.parseNumber(raw);
    if (newDay === null || D.dayValid({ month, year, day: newDay })) {
      setDay(newDay);
      updateDate(year, month, newDay);
    }
  };
  const inputClass = styles['date-text-input'];
  return (
    <div className={styles['textual-date']}>
      <label>{g('month')}</label>
      <label>{g('day')}</label>
      <label>{g('year')}</label>
      <input
        type="number"
        value={`${month}`}
        onChange={event => updateMonth(event.target.value)}
        className={inputClass}
      />
      <input
        type="number"
        value={`${day}`}
        onChange={event => updateDay(event.target.value)}
        className={inputClass}
      />
      <input
        type="number"
        value={`${year}`}
        onChange={event => updateYear(event.target.value)}
        className={inputClass}
      />
    </div>
  );
}

type CalendarDateSelectorProps = {
  date: Date | null;
  fallbackDate: Date;
  setDate: (_: Date | null) => void;
  minDate: Date;
  maxDate: Date;
}

function CalendarDateSelector(props: CalendarDateSelectorProps) {
  const initialDisplayDate = props.date ? props.date : props.fallbackDate;
  const [displayYear, setDisplayYear] = useState<number>(initialDisplayDate.getFullYear());
  const [displayMonth, setDisplayMonth] = useState<number>(initialDisplayDate.getMonth());
  useEffect(() => {
    const effectiveDate = props.date || props.fallbackDate;
    setDisplayYear(effectiveDate.getFullYear());
    setDisplayMonth(effectiveDate.getMonth());
  }, [props.date]);
  return (
    <div className={styles['calendar-wrapper']}>
      <CalendarButtonBar
        displayMonth={displayMonth}
        setDisplayMonth={setDisplayMonth}
        displayYear={displayYear}
        setDisplayYear={setDisplayYear}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
      <CalendarSingleMonthDisplay
        displayMonth={displayMonth}
        displayYear={displayYear}
        date={props.date}
        setDate={props.setDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
        fallbackDate={props.fallbackDate}
      />
    </div>
  );
}

type CalendarButtonBarProps = {
  displayMonth: number;
  setDisplayMonth: (_: number) => void;
  displayYear: number;
  setDisplayYear: (_: number) => void;
  minDate: Date;
  maxDate: Date;
}

function CalendarButtonBar(props: CalendarButtonBarProps) {
  const displayMonthText = g(T.MONTHS[props.displayMonth]);
  const barText = `${displayMonthText} ${props.displayYear}`;
  const minYear = props.minDate.getFullYear();
  const maxYear = props.maxDate.getFullYear();
  const minMonth = props.minDate.getMonth();
  const maxMonth = props.maxDate.getMonth();
  const targetValid = ({ month, year }: MonthYearPair): boolean => {
    return U.inDateRange(year, month, 0, minYear, minMonth, 0, maxYear, maxMonth, 0);
  };
  const updateDisplay = ({ month, year }: MonthYearPair) => {
    const validatedMonth = mod(month, T.MONTHS_PER_YEAR);
    const validatedYear = validatedMonth > month ? year - 1 : validatedMonth < month ? year + 1 : year;
    let finalMonth = validatedMonth;
    let finalYear = validatedYear;
    if (!targetValid({ month, year })) {
      if (year < props.displayYear || month < props.displayMonth) {
        finalMonth = minMonth;
        finalYear = minYear;
      } else {
        finalMonth = maxMonth;
        finalYear = maxYear;
      }
    }
    props.setDisplayMonth(finalMonth);
    props.setDisplayYear(finalYear);
  };
  const upInactive = props.displayYear > maxYear || (props.displayYear === maxYear && props.displayMonth >= maxMonth);
  const downInactive = props.displayYear < minYear || (props.displayYear === minYear && props.displayMonth <= minMonth);
  const doubleDownTarget = { month: props.displayMonth, year: props.displayYear - 1 };
  const singleDownTarget = { month: props.displayMonth - 1, year: props.displayYear };
  const singleUpTarget = { month: props.displayMonth + 1, year: props.displayYear };
  const doubleUpTarget = { month: props.displayMonth, year: props.displayYear + 1 };
  return (
    <div className={styles['calendar-button-bar']}>
      <button className={styles['calendar-shift-button']} onClick={() => updateDisplay(doubleDownTarget)} disabled={downInactive}>
        <IoPlayBack/>
      </button>
      <button className={styles['calendar-shift-button']} onClick={() => updateDisplay(singleDownTarget)} disabled={downInactive}>
        <IoCaretBackOutline/>
      </button>
      <div className={styles['button-bar-text']}>{barText}</div>
      <button className={styles['calendar-shift-button']} onClick={() => updateDisplay(singleUpTarget)} disabled={upInactive}>
        <IoCaretForward/>
      </button>
      <button className={styles['calendar-shift-button']} onClick={() => updateDisplay(doubleUpTarget)} disabled={upInactive}>
        <IoPlayForward/>
      </button>
    </div>
  );
}

type MonthYearPair = {
  month: number;
  year: number;
};

type CalendarSingleMonthDisplayProps = {
  displayYear: number;
  displayMonth: number;
  date: Date | null;
  setDate: (_: Date | null) => void;
  minDate: Date;
  maxDate: Date;
  fallbackDate: Date;
};

function CalendarSingleMonthDisplay(props: CalendarSingleMonthDisplayProps) {
  const daysPerMonth = useMemo(() => T.daysPerMonthByYear(props.displayYear), [props.displayYear]);
  const daysThisMonth = daysPerMonth[props.displayMonth];
  const previousMonth = mod(props.displayMonth - 1, T.MONTHS_PER_YEAR);
  const nextMonth = mod(props.displayMonth + 1, T.MONTHS_PER_YEAR);
  const daysPreviousMonth = daysPerMonth[previousMonth];
  const daysNextMonth = daysPerMonth[nextMonth];
  const previousMonthYear = previousMonth > props.displayMonth ? props.displayYear - 1 : props.displayYear;
  const nextMonthYear = nextMonth < props.displayMonth ? props.displayYear + 1 : props.displayYear;
  const firstDayOfWeekIndex = new Date(props.displayYear, props.displayMonth, 1).getDay();
  const lastDayOfWeekIndex = new Date(props.displayYear, props.displayMonth, daysThisMonth).getDay();
  const priorMonthShownDays = range(0, firstDayOfWeekIndex).map(i => daysPreviousMonth - firstDayOfWeekIndex + i);
  const currentMonthShownDays = range(0, daysThisMonth);
  const nextMonthShownDays= range(lastDayOfWeekIndex + 1, T.DAYS_PER_WEEK).map(i => i - lastDayOfWeekIndex - 1);
  const setDate = (dayIndex: number) => {
    props.setDate(new Date(props.displayYear, props.displayMonth, dayIndex + 1))
  }
  return (
    <div className={styles['single-month']}>
      {T.useWeekdayAbbreviations().map(weekday => <div className={styles['day-name-header']}>{weekday}</div>)}
      {priorMonthShownDays.map(dayIndex => {
        const date = new Date(previousMonthYear, previousMonth, dayIndex + 1)
        return (
          <DateBox
            active={false}
            numeral={dayIndex}
            effectiveDate={date}
            selectedDate={props.date}
            minDate={props.minDate}
            maxDate={props.maxDate}
            fallbackDate={props.fallbackDate}
            key={date.toString()}
          />
        );
      })}
      {currentMonthShownDays.map(dayIndex => {
        const date = new Date(props.displayYear, props.displayMonth, dayIndex + 1);
        return (
          <DateBox
            active={true}
            numeral={dayIndex}
            onClick={() => setDate(dayIndex)}
            effectiveDate={date}
            selectedDate={props.date}
            minDate={props.minDate}
            maxDate={props.maxDate}
            fallbackDate={props.fallbackDate}
            key={date.toString()}
          />
        );
      })}
      {nextMonthShownDays.map(dayIndex => {
        const date = new Date(nextMonthYear, nextMonth, dayIndex + 1);
        return (
          <DateBox
            active={false}
            numeral={dayIndex}
            effectiveDate={date}
            selectedDate={props.date}
            minDate={props.minDate}
            maxDate={props.maxDate}
            fallbackDate={props.fallbackDate}
            key={date.toString()}
          />
        );
      })}
    </div>
  );
}

type DateBoxProps = {
  active: boolean;
  numeral: number;
  onClick?: () => void;
  effectiveDate: Date;
  selectedDate: Date | null;
  minDate: Date;
  maxDate: Date;
  fallbackDate: Date;
};

function DateBox(props: DateBoxProps) {
  const complement = useRangeComplement()?.complement;
  const inRange = props.effectiveDate >= props.minDate && props.effectiveDate <= props.maxDate;
  const isSelected = (props.selectedDate !== null && T.dateEquals(props.selectedDate, props.effectiveDate))
                     || (props.selectedDate === null && T.dateEquals(props.effectiveDate, props.fallbackDate));
  const isComplement = complement && !isSelected && T.dateEquals(props.effectiveDate, complement);
  const proximalSelectedDate = props.selectedDate || props.fallbackDate
  const inSelectedRange = complement && !isSelected && !isComplement
                          && U.betweenDates(props.effectiveDate, proximalSelectedDate, complement);
  const activeClassName = styles[props.active ? 'active-date-box' : 'inactive-date-box'];
  const rangeClassName = inRange ? '' : styles['out-of-range-date-box'];
  const selectedClassName = isSelected ? styles['selected-date-circle'] : '';
  const complementClassName = isComplement ? styles['complement-date-circle'] : '';
  const selectedRangeClassName = inSelectedRange ? styles['selected-range-date-rectangle'] : '';
  const className = `${activeClassName} ${rangeClassName}`;
  const innerClassName = `${selectedClassName} ${complementClassName} ${selectedRangeClassName}`;
  const onClick = inRange ? props.onClick || (() => {}) : (() => {});
  const leftSelectionEnd = complement && (isComplement !== isSelected)
                           && (
                             isSelected && proximalSelectedDate.getTime() > complement.getTime()
                             || isComplement && proximalSelectedDate.getTime() < complement.getTime()
                           );
  const rightSelectionEnd = complement && (isComplement !== isSelected)
                            && (
                              isSelected && proximalSelectedDate.getTime() < complement.getTime()
                              || isComplement && proximalSelectedDate.getTime() > complement.getTime()
                            );
  return (
    <div className={className} onClick={onClick}>
      {leftSelectionEnd && (
        <div className={styles['left-selection-end-date-box']}>
          <div className={styles['interior-selection-end']}></div>
        </div>
      )}
      {rightSelectionEnd && (
        <div className={styles['right-selection-end-date-box']}>
          <div className={styles['interior-selection-end']}></div>
        </div>
      )}
      <div className={innerClassName}>{props.numeral + 1}</div>
    </div>
  );
};
