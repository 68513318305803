// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageDisplay_image-display-list__azgIl {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .imageDisplay_image-display-list__azgIl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: flex-start;
  }
}

.imageDisplay_image-display-layout__3Sj8N {
  margin-bottom: 1rem;
}

.imageDisplay_image-display__Sxb3H {
  max-width: 10rem;
  cursor: pointer;
}

.imageDisplay_image-wrapper__zxqxn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageDisplay_file-label-editor__f0qek {
  margin-top: 1rem;
  padding: 5px;
}

.imageDisplay_picture-minus-button__X5-z8 {
  margin-left: 0.5rem;
}

.imageDisplay_modal-image-display__QwCf0 {
  max-width: 100%;
}

.imageDisplay_modal-image-content__0y9CR {
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pictures/imageDisplay.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAEE;EAFF;IAGI,aAAA;IACA,mBAAA;IACA,eAAA;IACA,SAAA;IACA,2BAAA;EACF;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF","sourcesContent":["@import \"../styles/constants.module.scss\";\n\n.image-display-list {\n  margin-bottom: 1rem;\n  @media(min-width: $mobile) {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 3rem;\n    justify-content: flex-start;\n  }\n}\n\n.image-display-layout {\n  margin-bottom: 1rem;\n}\n\n.image-display {\n  max-width: 10rem;\n  cursor: pointer;\n}\n\n.image-wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.file-label-editor {\n  margin-top: 1rem;\n  padding: 5px;\n}\n\n.picture-minus-button {\n  margin-left: 0.5rem;\n}\n\n.modal-image-display {\n  max-width: 100%;\n}\n\n.modal-image-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-display-list": `imageDisplay_image-display-list__azgIl`,
	"image-display-layout": `imageDisplay_image-display-layout__3Sj8N`,
	"image-display": `imageDisplay_image-display__Sxb3H`,
	"image-wrapper": `imageDisplay_image-wrapper__zxqxn`,
	"file-label-editor": `imageDisplay_file-label-editor__f0qek`,
	"picture-minus-button": `imageDisplay_picture-minus-button__X5-z8`,
	"modal-image-display": `imageDisplay_modal-image-display__QwCf0`,
	"modal-image-content": `imageDisplay_modal-image-content__0y9CR`
};
export default ___CSS_LOADER_EXPORT___;
