import React from 'react';
import { useGlossary as g } from "../../language";

type TagInfoProps = {
  text: string;
}

export function TagInfo(props: TagInfoProps) {
  return (
    <>
      {props.text}
    </>
  )
}
