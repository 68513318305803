import { SignUpResponse } from 'authentication';
import { User } from "user";
import { LatestQuestionDefaults, NewQuestionIdEnvelope } from "NewQuestionPage";
import { Glossary } from "language";
import { LabeledFile } from "pictures";
import { QuestionSubmission } from "questionTypes";
import { QuestionEditSubmission } from "QuestionEditor";
import { QuestionEditContext, QuestionContext } from "questionContext";
import { ImageType } from "pictures";
import { NewQuizContext, QuizSettings, Quiz, QuizSubmission, QuizIdentifier, QuizProgressEnvelope, QuizFeasibility } from "quiz";
import { ActiveQuizPayload } from "quizContext";
import { QuizResultEnvelope, RetakeVersion } from "QuizResultsPage";
import { ResultOrStatus } from "./resultOrStatus";
import { QuestionSearchPayload, QuestionPartialLoad } from "QuestionsPage";
import { HomePageContext } from "UserHomePage";
import { QuestionVersionHistory } from "QuestionPage";

export const port = 8063;
const TOKEN_KEY = 'token';
const api = process.env.REACT_APP_BACKEND_LOCATION;// `https://localhost:${port}`;
const MULTIPART_FORM_DATA = 'multipart/form-data';
const APPLICATION_JSON = 'application/json';
const PNG = 'image/png';
type MimeType = (typeof MULTIPART_FORM_DATA) | (typeof APPLICATION_JSON) | (typeof PNG);

function fetchConfiguration(method: string, mimeType?: string): RequestInit {
  return {
    method: method,
    mode: "cors",
    credentials: "include",
    headers: {
      ...contentTypeByMethod(method, mimeType),
      'Authorization': `Bearer ${getToken()}`
    }
  };
}

function contentTypeByMethod(method: string, mimeType?: string): Record<string, string> {
  if (['GET', 'OPTIONS'].includes(method)) {
    return {
      "Content-Length": "0"
    };
  } else if (['POST', 'PUT'].includes(method)) {
    return {
      "Content-Type": mimeType || "application/json"
    };
  } else {
    throw Error(`unaccepted method ${method}`);
  }
}

function get(url: string): Promise<any> {
  return getRaw(url).then(response => response.json());
}

function post(url: string, body: any): Promise<any> {
  return postRaw(url, body)
  .then(response => response.json());
}

function put(url: string, body: any): Promise<any> {
  return putRaw(url, body)
  .then(response => response.json());
}

function getRaw(url: string): Promise<any> {
  console.log("getting ", `${api}${url}`);
  return fetch(`${api}${url}`, fetchConfiguration("GET"));
}

function postRaw(url: string, body: any, mimeType?: MimeType): Promise<any> {
  // console.log("post body: ", JSON.stringify)
  const finalBody = (mimeType === APPLICATION_JSON || mimeType === undefined) ? JSON.stringify(body) : body;
  console.log("mimeType", mimeType);
  console.log("mimetype is multipart", mimeType === MULTIPART_FORM_DATA);
  console.log("body", body);
  console.log("final body", finalBody)
  return fetch(`${api}${url}`, {
    ...fetchConfiguration("POST", mimeType),
    body: finalBody,
  });
}

function putRaw(url: string, body: any): Promise<any> {
  return fetch(`${api}${url}`, {
    ...fetchConfiguration("PUT"),
    body: JSON.stringify(body)
  });
}

function normalizeResultOrStatus<T>(response: Response): Promise<ResultOrStatus<T>> {
  if (response.status >= 300) {
    return Promise.resolve({
      status: response.status
    });
  } else {
    return response.json();
  }
}

function getResultOrStatus<T>(url: string): Promise<ResultOrStatus<T>> {
  return getRaw(url).then(response => normalizeResultOrStatus(response));
}

function postResultOrStatus<T>(url: string, body: any, mimeType?: MimeType): Promise<ResultOrStatus<T>> {
  return postRaw(url, body, mimeType).then(response => normalizeResultOrStatus(response));
}

function putResultOrStatus<T>(url: string, body: any): Promise<ResultOrStatus<T>> {
  return putRaw(url, body).then(response => normalizeResultOrStatus(response));
}

export function signUp(email: string, loginName: string, password: string): Promise<SignUpResponse> {
  const body = {
    email: email,
    password: password,
    loginName: loginName === "" ? null : loginName
  }
  return postRaw("/sign-up", body)
  .then(result => {
    console.log("sign up response", result);
    return storeTokenOnResponse(result);
  });
}

export function ongoingSession(): Promise<User | null> {
  return getRaw("/ongoing-session")
  .then(async response => {
    if (response.status === 404 || response.status === 401) {
      return null;
    } else {
      return response.json();
    }
  })
  .catch(error => {
    console.error(error);
    return null;
  })
}

export function signOut(): Promise<void> {
  return post("/sign-out", {}).finally(() => removeToken());
}

export function signIn(identifier: string, password: string): Promise<User | null> {
  return postRaw("/sign-in", {
    identifier: identifier,
    password: password
  })
  .then(async result => {
    // console.log("result: ", await result.text());
    // console.log("full body: ", await result.text());
    return storeTokenOnResponse(result);
  })
}

function storeTokenOnResponse(result: Response) {
  if (result.status === 403) {
    console.log("not storing token");
    return null;
  } else {
    console.log("storing token");
    storeToken(result);
    return result.json();
  }
}

function storeToken(response: Response) {
   console.log('authorization token', authorizationToken(response));
   setToken(authorizationToken(response));
}

function removeToken() {
  setToken("");
}

function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token.replace(" ", ""));
}

export function getToken(): string {
  return localStorage.getItem(TOKEN_KEY) || "";
}

function authorizationToken(response: Response): string {
  console.log("headers", response.headers);
  console.log("authorization", response.headers.get('Authorization'));
  return response.headers.get('Authorization')?.substring(8) || "";
}

export function retrieveLatestQuestionSettings(userId: string): Promise<ResultOrStatus<LatestQuestionDefaults>> {
  return getResultOrStatus(`/latest-question-settings`);
}

export function retrieveGlossary(language: string): Promise<Glossary> {
  return get(`/glossary/${language}`)
  .then(glossary => JSON.parse(glossary));
}

export function uploadPictures(files: LabeledFile[]): Promise<ResultOrStatus<any>> {
  console.log("files: ", files);
  let data = new FormData();
  files.filter(file => file.file !== null).forEach(file => {
    console.log("in foreach");
    data.append(file.id, file.file!);
  });
  // return fetch(`${api}/upload-pictures/`, {
  //   method: "POST",
  //   body: data
  // })
  console.log("images to upload", Array.from(data.entries()).length);
  // IMAGES DONT WORK RIGHT NOW NEED TO COME BACK TO IT
  return Promise.resolve({});
  // return postResultOrStatus(`/upload-pictures`, data, PNG);
}

export function persistNewQuestion(model: QuestionSubmission): Promise<ResultOrStatus<NewQuestionIdEnvelope>> {
  return postResultOrStatus(`/new-question`, model);
}

export function retrieveQuestionContext(questionId: string): Promise<QuestionContext> {
  return get(`/question-context/${questionId}`);
}

export function imageUrl(imageId: string, imageType: ImageType): string {
  return `${api}/retrieve-picture/${imageId}/${imageType}`;
}

export function deleteQuestion(questionId: string): Promise<any> {
  return post(`/delete-question/${questionId}`, {});
}

export function updateQuestion(question: QuestionEditSubmission): Promise<any> {
  return postRaw(`/update-question`, question);
}

export function retrieveLatestQuizSettings(): Promise<NewQuizContext> {
  return get(`/latest-quiz-settings`);
}

export function newQuizRequest(payload: QuizSettings): Promise<QuizIdentifier> {
  return post(`/new-quiz`, payload);
}

export function retrieveQuiz(quizId: string): Promise<Quiz> {
  return get(`/quiz/${quizId}`);
}

export function cancelQuiz(quizId: string) {
  return post(`/cancel-quiz/${quizId}`, {});
}

export function activeQuiz(): Promise<ActiveQuizPayload> {
  return get(`/active-quiz`);
}

export function submitQuiz(quizId: string, submission: QuizSubmission): Promise<any> {
  return postRaw(`/submit-quiz/${quizId}`, submission);
}

export function retrieveQuizResults(quizId: string): Promise<ResultOrStatus<QuizResultEnvelope>> {
  return getResultOrStatus(`/quiz-results/${quizId}`);
}

export function retakeQuiz(quizId: string, retakeVersion: RetakeVersion): Promise<ResultOrStatus<QuizIdentifier>> {
  return postResultOrStatus(`/retake-quiz/${quizId}/${retakeVersion}`, {});
}

export function getHomePageContext(): Promise<ResultOrStatus<HomePageContext>> {
  return getResultOrStatus(`/home-page-context`);
}

export function loadQuestions(parameters: QuestionSearchPayload): Promise<ResultOrStatus<QuestionPartialLoad>> {
  return postResultOrStatus(`/questions-by-criteria`, parameters);
}

export function loadAllQuestionVersions(questionId: string): Promise<ResultOrStatus<QuestionVersionHistory>> {
  return getResultOrStatus(`/question-all-versions/${questionId}`);
}

export function saveQuizProgress(progress: QuizProgressEnvelope): Promise<ResultOrStatus<any>> {
  return post("/save-quiz-progress", progress);
}

export function quizSettings(settings: QuizSettings): Promise<ResultOrStatus<QuizFeasibility>> {
  return post("/quiz-settings", settings);
}