import React, { useRef, useEffect, Ref, useState } from 'react';
import styles from "./imageDisplay.module.scss";
import { LabeledFile, LabeledFileHandle } from "./labeledFile";
import { MinusButton } from "commonComponents/buttons";
import { useUser } from "../user";
import { imageUrl } from "../network";
import Modal from "../Modal";
import { handlesToLabeledFiles } from "./pictureOperations";
import { g } from "language";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";

type ImageDisplayListProps = {
  files: LabeledFile[];
  setFiles: (_: LabeledFile[]) => void;
  editable: boolean;
}

export function ImageDisplayList(props: ImageDisplayListProps) {
  return props.files.length ? (
    <div className={styles['image-display-list']}>
      {props.files.map((file, index) => {
        const deleteImage = () => {
          props.files.splice(index, 1);
          props.setFiles([...props.files]);
        };
        const labelChange = (label: string) => {
          props.files.splice(index, 1, {
            ...file,
            label: label
          });
          props.setFiles([...props.files]);
        }
        return (
          <ImageDisplay
            file={file}
            deleteImage={deleteImage}
            labelChange={labelChange}
            key={file.id}
            editable={props.editable}
          />
        );
      })}
    </div>
  ) : <AbsenceIndicator glossaryKey={'imagesAbsent'}/>
}

type ImageDisplayProps = {
  file: LabeledFile;
  labelChange: (_: string) => void;
  deleteImage: () => void;
  editable: boolean;
}

export function ImageDisplay(props: ImageDisplayProps) {
  const ref = useRef<HTMLImageElement>(null);
  const modalRef = useRef<HTMLImageElement>(null);
  const userContext = useUser();
  const label = props.file.label;
  useEffect(() => {
    if (props.file.file) {
      const reader = new FileReader();
      reader.readAsDataURL(props.file.file);
      reader.onload = () => {
        if (ref && ref.current && typeof reader.result === 'string') {
          ref!.current!.src = reader.result;
          modalRef!.current!.src = reader.result;
        }
      }
    } else if (userContext.user) {
      if (ref && ref.current) {
        const url = imageUrl(props.file.id, props.file.imageType);
        ref!.current!.src = url;
        modalRef!.current!.src = url;
      }
    }
  }, [props.file, userContext]);
  if (props.editable) {
    return (
      <EditablePicture
        reference={ref}
        modalReference={modalRef}
        label={label}
        labelChange={props.labelChange}
        deleteImage={props.deleteImage}
      />
    );
  } else {
    return (
      <DisplayPicture reference={ref} modalReference={modalRef} label={label}/>
    );
  }
}

type PictureProps = {
  reference: Ref<HTMLImageElement>;
  modalReference: Ref<HTMLImageElement>;
  label: string;
}

function Picture(props: PictureProps) {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div>
      <Modal visible={modalVisible} setVisible={setModalVisible} wideMode={true}>
        <div className={styles['modal-image-content']}>
          <h3>{props.label}</h3>
          <img ref={props.modalReference} className={styles['modal-image-display']} alt="Not here"/>
        </div>
      </Modal>
      <img
        ref={props.reference}
        className={styles['image-display']}
        alt="Can't load"
        onClick={() => setModalVisible(true)}
      />
    </div>
  );
}

function DisplayPicture(props: PictureProps) {
  return (
    <div className={styles['image-display-layout']}>
      <Picture reference={props.reference} modalReference={props.modalReference} label={props.label}/>
      <div>{props.label}</div>
    </div>
  )
}

type EditablePictureProps = {
  reference: Ref<HTMLImageElement>;
  modalReference: Ref<HTMLImageElement>;
  label: string;
  labelChange: (_: string) => void;
  deleteImage: () => void;
}

function EditablePicture(props: EditablePictureProps) {
  return (
    <div className={styles['image-display-layout']}>
      <div className={styles['image-wrapper']}>
        <Picture reference={props.reference} modalReference={props.modalReference} label={props.label}/>
        <MinusButton onClick={() => props.deleteImage()} classes={styles['picture-minus-button']}/>
      </div>
      <input
        className={styles['file-label-editor']}
        type="text"
        value={props.label}
        onChange={event => props.labelChange(event.target.value)}
      />
    </div>
  )
}

type ImageHandleDisplayProps = {
  handles: LabeledFileHandle[];
  below: boolean;
}

export function ImageHandleDisplay(props: ImageHandleDisplayProps) {
  const wrapperClass = styles[props.below ? 'image-wrapper-below' : 'image-wrapper-above'];
  return props.handles.length > 0 ? (
    <div className={wrapperClass}>
      <ImageDisplayList
        files={handlesToLabeledFiles(props.handles)}
        setFiles={() => {}}
        editable={false}
      />
    </div>
  ) : null;
}
