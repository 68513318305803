export { default as Editor } from "./SimpleEditor";
export type { SimpleEditorState as Content } from "./SimpleEditor";
export {
  constructSimpleEditorState as constructContent,
  textLength,
  stringRepresentation,
  fromString,
  nonEmpty,
  SimpleTextDisplay as TextDisplay,
  equals,
  undoSufficientDifference
} from "./SimpleEditor";
