import { defaultBoolean } from "logicUtils";
import { COMPARATORS, INEXACT_COMPARATORS, Comparator, NumberPredicate } from "./state";
import styles from "./NumberPredicateSelector.module.scss";
import NumberInput from "commonComponents/NumberInput";

export type NumberPredicateSelectorProps = {
  value: NumberPredicate;
  setValue: (_: NumberPredicate) => void;
  disabled?: boolean;
  additionalClasses?: string;
  exact: boolean;
};

export default function NumberPredicateSelector(props: NumberPredicateSelectorProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const setNumber = (base: number) => {
    props.setValue({
      ...props.value,
      base
    });
  }
  const setPredicate = (comparator: Comparator) => {
    props.setValue({
      ...props.value,
      comparator
    });
  };
  return (
    <div className={styles['number-predicate']}>
      <ComparatorOptions
        exact={props.exact}
        disabled={disabled}
        predicate={props.value.comparator}
        setPredicate={setPredicate}
      />
      <NumberInput value={props.value.base} onChange={setNumber} disabled={props.disabled}/>
    </div>
  );
}

type ComparatorOptionsProps = {
  exact: boolean;
  disabled: boolean;
  predicate: Comparator;
  setPredicate: (_: Comparator) => void;
};

function ComparatorOptions(props: ComparatorOptionsProps) {
  const options: readonly Comparator[] = props.exact ? COMPARATORS : INEXACT_COMPARATORS;
  const onClick = (comparator: Comparator) => {
    if (!props.disabled) {
      props.setPredicate(comparator);
    }
  };
  const disabledClass = props.disabled ? styles['disabled'] : '';
  const className = (c: Comparator) => {
    const selectedClass = c === props.predicate ? styles['selected'] : '';
    return `${styles['predicate']} ${selectedClass} ${disabledClass}`;
  };
  return (
    <>
      {options.map(comparator => (
        <div className={className(comparator)} onClick={() => onClick(comparator)} key={comparator}>{comparator}</div>
      ))}
    </>
  );
}
