import { useContext, createContext } from 'react';
import { Glossary, GlossaryKey } from "./glossary";

const DEFAULT = "--";

export const LanguageContext = createContext<Glossary | null>(null);

export function useLanguage(): Glossary | null {
  const context = useContext(LanguageContext);
  if (!context) {
    console.error("Can't use language context");
    return null;
  }
  return context;
}

export function useGlossary(key: keyof Glossary): string {
  const glossary = useLanguage();
  if (glossary) {
    return glossary[key];
  } else {
    return DEFAULT;
  }
}

export function useGlossaryDynamic(key: string): string {
  const glossary = useLanguage();
  if (glossary && isGlossaryKey(key, glossary)) {
    return glossary[key as GlossaryKey];
  } else {
    return DEFAULT;
  }
}

function indexKey(index: number): string {
  return `<${index}>`;
}

export function useGlossaryTemplate(key: keyof Glossary, values: any[]): string {
  const inplace = useGlossary(key);
  return resolveTemplate(inplace, values.map(v => v.toString()));
}

function resolveTemplate(template: string, values: string[]): string {
  return values.reduce((template, replacement, index) => {
    return template.replace(indexKey(index), replacement);
  }, template);
}

function isGlossaryKey(key: string, glossary: Glossary | null): boolean {
  const forced = key as GlossaryKey;
  return !!(glossary && glossary[forced]);
}

export function useGlossaryTemplateDynamic(key: string, values: string[]): string {
  const glossary = useLanguage();
  if (glossary && isGlossaryKey(key, glossary)) {
    return resolveTemplate(glossary[key as GlossaryKey], values);
  } else {
    console.error(`${key} is not a key of Glossary`);
    return DEFAULT;
  }
}

export function useGlossaryPlural(key: GlossaryKey, count: number): string {
  const glossary = useContext(LanguageContext);
  if (glossary) {
    if (count === 1) {
      return glossary[key];
    } else {
      const pluralKey = `${key}Plural`;
      return glossary[pluralKey as GlossaryKey] || DEFAULT;
    }
  } else {
    return DEFAULT;
  }
}

export function useGlossaryPluralCount(key: GlossaryKey, count: number): string {
  const wording = useGlossaryPlural(key, count);
  return `${count} ${wording}`;
}
