import React from 'react';
import styles from "./SubmissionResult.module.scss";
import { useGlossary as g, GlossaryKey } from "language";
import { Link } from "react-router-dom";

export type SubmissionResult = 'none' | 'success' | 'failure';

type FocusedSubmissionIndicatorProps = {


  close: () => void;
}

type SubmissionIndicatorProps = {
  result: SubmissionResult;
  successKey?: GlossaryKey;
  failureKey: GlossaryKey;
  successChildren?: JSX.Element;
  close: () => void;
}

export function SubmissionIndicator(props: SubmissionIndicatorProps) {
  const successMessage = props.successKey ? g(props.successKey) : '';
  const failMessage = g(props.failureKey);
  switch (props.result) {
    case 'none': return null;
    case 'success': return (
      <div className={styles['submission-success']}>
        <div className={styles['submission-success-text']}>
          <div>{successMessage}</div>
          {props.successChildren}
        </div>
        <IndicatorX close={props.close}/>
      </div>
    );
    case 'failure': return (
      <div className={styles['submission-failure']}>
        <div>
          {failMessage}
        </div>
        <IndicatorX close={props.close}/>
      </div>
    );
  }
}

type IndicatorXProps = {
  close: () => void;
};

function IndicatorX(props: IndicatorXProps) {
  return (
    <div className={styles['x-wrapper']}>
      <span className={styles['indicator-x']} onClick={props.close}>&times;</span>
    </div>
  );
}

type QuestionSubmissionIndicatorProps = {
  result: SubmissionResult;
  submittedQuestion: string | null;
  close: () => void;
};

export function QuestionSubmissionIndicator(props: QuestionSubmissionIndicatorProps) {
  const linkText = g('editLastQuestion');
  return (
    <SubmissionIndicator
      result={props.result}
      successKey={'questionSubmissionSuccessful'}
      failureKey={'questionSubmissionFailed'}
      close={props.close}
      successChildren={
        <>
          {props.submittedQuestion ? (
            <Link
              className={styles['submission-link']}
              to={`/question/${props.submittedQuestion}`}
            >{linkText}</Link>
           ) : null}
        </>
      }
    />
  )
}
