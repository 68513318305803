import React, { useEffect, useState, useRef } from 'react';
import styles from "./PictureUploader.module.scss";
import { BsPlusSquare } from "react-icons/bs";
import { LabeledFile } from "../labeledFile";
import { v4 } from "uuid";
import { imageType } from "../pictureOperations";
import { ImageDisplayList } from "../imageDisplay";
import { g } from "language";

const acceptableFileTypes = [".jpg", ".jpeg", ".png"];

type PictureUploaderProps = {
  files: LabeledFile[];
  setFiles: (_: LabeledFile[]) => void;
}

export default function PictureUploader(props: PictureUploaderProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    window.addEventListener('drop', event => event.preventDefault())
  }, []);
  const [draggedOver, setDraggedOver] = useState(false);
  const dragClass = draggedOver ? styles['dragged-over'] : '';
  const addFiles = (files: File[]) => {
    const accepted = files.map(file => {
      return {
        file: file,
        label: file.name,
        id: v4(),
        imageType: imageType(file),
        preexisting: false
      }
    });
    if (accepted.length > 0) {
      props.setFiles(props.files.concat(accepted));
    }
  };
  const componentId = v4();
  const inputId = `real-file-input-${componentId}`;
  return (
    <div className={styles['picture-uploader']} id={componentId}>
      <ImageDisplayList files={props.files} setFiles={props.setFiles} editable={true}/>
      <label
        htmlFor={inputId}
        onDrop={event => {
          setDraggedOver(false);
          event.preventDefault();
          const files = Array.from(event.dataTransfer.items || [])
            .map(handle => handle.getAsFile())
            .filter(file => {
              return file !== null && acceptableFileTypes.reduce(
                (accumulator, filetype) => accumulator || file.name.endsWith(filetype), false as boolean
              );
            });
          addFiles(files as File[]);
        }}
        onDragOver={event => {
          event.preventDefault();
          setDraggedOver(true);
        }}
        onDragLeave={event => {
          event.preventDefault();
          setDraggedOver(false);
        }}
      >
        <BsPlusSquare className={`${styles['add-file-button']} ${dragClass}`}/>
      </label>
      <span className={styles['input-wrapper']}>
        <input
          ref={inputRef}
          type="file"
          id={inputId}
          multiple
          accept="image/png, image/jpeg"
          onChange={() => {
            addFiles(Array.from(inputRef!.current!.files || []));
          }}
        />
      </span>
      <div className={styles['prompt']}>{g('addPictureLabel')}</div>
    </div>
  )
}
