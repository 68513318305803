import { useState, useEffect } from 'react';
import { TableCache, ItemId, QueryKey } from "./tableCache";
import { IndexLimits, calculateLoadRangeByVariant, displayRangeByVariant } from "./computation";
import PartialLoadTable, { PartialLoadTableProps, PartialLoadTableVariant } from "./PartialLoadTable";
import { INFINITE_SCROLL_LOAD_COUNT } from "./constants";

export type IndexedQuery<Q> = {
  parameters: Q;
  indexLimits: IndexLimits;
}

export type ParsedLoad<R, M> = {
  rows: R[];
  metadata: M;
  totalRows: number;
}

type CachedPartialLoadTableProps<R, Q, M, L> = {
  rowId: ItemId<R>;
  queryKey: QueryKey<Q>;
  constructQuery: () => Q;
  currentQuery: Q;
  setCurrentQuery: (_: Q) => void;
  load: (_: IndexedQuery<Q>) => Promise<L | null>;
  extractFromLoad: (_: L) => ParsedLoad<R, M>;
  row: (_1: R, _2: number, _3: number, _4: M | undefined, _5: R[]) => JSX.Element;
  header: (_: M | undefined) => JSX.Element;
  loadCounter: number;
  variant: PartialLoadTableVariant;
  scrollLoadCount?: number;
  headerClasses?: string;
  pageSizeOptions?: number[];
  resetCounter: number;
  onFullScroll: () => void;
};

export default function CachedPartialLoadTable<R, Q, M, L>(props: CachedPartialLoadTableProps<R, Q, M, L>) {
  const [cache, setCache] = useState<TableCache<R, Q, M>>(new TableCache(props.rowId, props.queryKey));
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [targetScrollLoads, setTargetScrollLoads] = useState(1);
  const scrollLoadCount = props.scrollLoadCount || INFINITE_SCROLL_LOAD_COUNT;
  const load = async () => {
    let effectiveCurrentPage = currentPage;
    const nextQuery = props.constructQuery();
    if (!queriesEqual(props.currentQuery, nextQuery, props.queryKey)) {
      setCurrentPage(0);
      effectiveCurrentPage = 0;
      setTargetScrollLoads(1);
    }
    const indexLimits = calculateLoadRangeByVariant(
      props.variant, pageSize, effectiveCurrentPage, cache.size(nextQuery), scrollLoadCount
    );
    if (cache.loadRequired(nextQuery, indexLimits)) {
      const indexedQuery = {
        indexLimits,
        parameters: nextQuery
      };
      const nextLoad = await props.load(indexedQuery);
      if (nextLoad !== null) {
        const { rows, metadata, totalRows } = props.extractFromLoad(nextLoad);
        setCache(cache.addRange(nextQuery, indexLimits, rows, metadata, totalRows));
      }
    }
    props.setCurrentQuery(nextQuery);
  }
  useEffect(() => {
    load();
  }, [currentPage, pageSize, props.loadCounter, targetScrollLoads]);
  useEffect(() => {
    setCache(new TableCache(props.rowId, props.queryKey));
  }, [props.resetCounter]);
  const scrollLoadedSoFar = cache.size(props.currentQuery)
  const indexLimits = displayRangeByVariant(
    props.variant, pageSize, currentPage, scrollLoadedSoFar
  );
  const metadata = cache.metadata(props.currentQuery);
  const rows = cache.rows(props.currentQuery, indexLimits);
  const onFullScroll = () => setTargetScrollLoads(targetScrollLoads + 1);
  return (
    <PartialLoadTable
      variant={props.variant}
      pageSize={pageSize}
      pageSizeOptions={props.pageSizeOptions}
      setPageSize={setPageSize}
      scrollLoadCount={scrollLoadCount}
      headerRow={props.header(metadata)}
      headerClasses={props.headerClasses}
      totalRowCount={cache.totalRows(props.currentQuery)}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      rows={rows?.map((r, index) => props.row(r, index, indexLimits.startIndex + index, metadata, rows)) || null}
      onFullScroll={onFullScroll}
      scrollLoadedSoFar={scrollLoadedSoFar}
      targetScrollLoads={targetScrollLoads}
    />
  );
}

function queriesEqual<Q>(a: Q, b: Q, queryKey: QueryKey<Q>): boolean {
  return queryKey(a) === queryKey(b);
}
