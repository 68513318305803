// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Info_info-icon__OvuiA {
  font-size: 1.1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.Info_close-button-wrapper__G9fS2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.Info_close-button__hIIEi {
  border: none;
  background: #00aaff;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.Info_header__b-wbR {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_header__b-wbR > :first-child {
  margin-right: 0.5rem;
}

.Info_content-wrapper__sxGhl {
  margin-bottom: 0.5rem;
  max-height: 20rem;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Info/Info.module.scss","webpack://./src/styles/constants.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;AADF;;AAIA;EACE,YAAA;EACA,mBChBK;EDiBL,YAAA;EACA,kBAAA;EACA,YAAA;AADF;;AAIA;EACE,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADF;AAEE;EACE,oBAAA;AAAJ;;AAIA;EACE,qBAAA;EACA,iBAAA;EACA,kBAAA;AADF","sourcesContent":["@import \"../styles/constants.module.scss\";\n\n.info-icon {\n  font-size: 1.1rem;\n  margin-left: 1rem;\n  cursor: pointer;\n}\n\n.close-button-wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-bottom: 0.5rem;\n}\n\n.close-button {\n  border: none;\n  background: $blue;\n  color: white;\n  border-radius: 5px;\n  padding: 5px;\n}\n\n.header {\n  font-weight: bold;\n  font-size: 1.1rem;\n  margin-bottom: 0.5rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  & > :first-child {\n    margin-right: 0.5rem;\n  }\n}\n\n.content-wrapper {\n  margin-bottom: 0.5rem;\n  max-height: 20rem;\n  overflow-y: scroll;\n}\n","$blue-green: #00ccaa;\n$blue: #00aaff;\n$dark-blue: #0066ff;\n$green: #00bb00;\n$dark-green: #008800;\n$light-gray: #dddddd;\n$ultra-light-gray: #dfdfdf;\n$mid-light-gray: #bbbbbb;\n$gray: gray;\n$dark-gray: #282828;\n$red: salmon;\n$pale-blue: #87cefa;\n$off-white: #f8f8ff;\n$purple: #800080;\n$dark-red: #dc143c;\n$light-green: #00ffef;\n\n$navbar-height: 3rem;\n$mobile: 600px;\n$desktop-left-margin: 1rem;\n$mobile-margin: 0.5rem;\n$desktop-margin: 20rem;\n$desktop-wide-view-margin: 3rem;\n$mobile-default-font-size: 0.7rem;\n$mobile-label-size: 0.8rem;\n\n$external-chiclet-padding: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-icon": `Info_info-icon__OvuiA`,
	"close-button-wrapper": `Info_close-button-wrapper__G9fS2`,
	"close-button": `Info_close-button__hIIEi`,
	"header": `Info_header__b-wbR`,
	"content-wrapper": `Info_content-wrapper__sxGhl`
};
export default ___CSS_LOADER_EXPORT___;
