import React, { useState, useRef } from 'react';
import styles from "./QuestionTypeSelector.module.scss";
import { QuestionType, questionTypes } from "./questionTypes";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import useClosable from "closable";
import { g, gt, gd } from "language";
import Info from "Info";
import SingleSelect from "commonComponents/SingleSelect";
import AllOrSomeSelect from "commonComponents/AllOrSomeSelect";

type QuestionTypeSelectorVariant = 'single' | 'multiple';

type QuestionTypeSelectorProps = {
  questionType: QuestionType;
  onSelect: (_: QuestionType) => void
  variant?: QuestionTypeSelectorVariant;
}

export default function QuestionTypeSelector(props: QuestionTypeSelectorProps) {
  const variant = props.variant || 'single';
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const typeText = g(props.questionType);
  const headerText = gt('questionTypeIndicator', [typeText]);
  useClosable(ref, () => setOpen(false));
  return (
    <div className={styles['wrapper']}>
      <SingleSelect
        header={{
          display: (
            <div className={styles['question-type-button']}>
              <span>
                {headerText}
              </span>
              {open ? <FaChevronDown/> : <FaChevronRight/>}
            </div>
          )
        }}
        entries={
          questionTypes.map(questionType => ({
            id: questionType,
            element: (
              <QuestionTypeEntry
                questionType={questionType}
              />
            )
          }))
        }
        onSelect={props.onSelect}
        additionalClasses={styles['select']}
      />
      <QuestionTypeInfo/>
    </div>
  )
}

type QuestionTypeEntryProps = {
  questionType: QuestionType;
}

function QuestionTypeEntry(props: QuestionTypeEntryProps) {
  const text = g(props.questionType)
  return (
    <div
      className={styles['question-type-row']}
      key={props.questionType}
    >
      {text}
    </div>
  )
}

function QuestionTypeInfo() {
  return (
    <Info title={g('questionTypeInfoHeader')}>
      <p>{g('questionTypeGeneral')}</p>
      {questionTypes.map(questionType => (
        <QuestionTypeExplanation questionType={questionType} key={questionType}/>
      ))}
    </Info>
  )
}

type QuestionTypeExplanationProps = {
  questionType: QuestionType;
}

function QuestionTypeExplanation(props: QuestionTypeExplanationProps) {
  const header = g(props.questionType);
  const explanation = gd(questionTypeExplanationGlossaryKey(props.questionType));
  return (
    <>
      <p><label>{header}</label></p>
      <p>{explanation}</p>
    </>
  );
}

type QuestionTypeViewProps = {
  questionType: QuestionType;
};

export function QuestionTypeView(props: QuestionTypeViewProps) {
  return (
    <div className={styles['question-type-box']}>
      <div className={styles['question-type-label']}>
        {g(props.questionType)}
      </div>
      <div>
        {gd(questionTypeExplanationGlossaryKey(props.questionType))}
      </div>
    </div>
  )
}

function questionTypeExplanationGlossaryKey(questionType: QuestionType): string {
  return `${questionType}-info-explanation`;
}

type MultipleQuestionTypeSelectorProps = {
  selected: Set<QuestionType>;
  setSelected: (_: Set<QuestionType>) => void;
}

export function MultipleQuestionTypeSelector(props: MultipleQuestionTypeSelectorProps) {
  const initialAll = props.selected.size === questionTypes.length;
  return (
    <div className={styles['multiple-question-type-selector']}>
      <AllOrSomeSelect<QuestionType>
        options={questionTypes}
        selectedOptions={props.selected}
        setSelectedOptions={props.setSelected}
        localGlossary={qt => qt}
        initialAll={initialAll}
      />
    </div>
  )
}
