import React from 'react';
import * as L from "./labels";

type AnswerLabelDisplayProps = {
  labelType: L.AnswerLabel;
  position: number;
}

export function AnswerLabelDisplay(props: AnswerLabelDisplayProps) {
  if (props.labelType === 'noLabel') {
    return null;
  } else {
    let labelText = "";
    switch (props.labelType) {
      case 'numberLabel':
        labelText = `${props.position + 1}`;
        break;
      case 'lowercaseLabel':
        labelText = L.lowercaseCharacter(props.position);
        break;
      case 'uppercaseLabel':
        labelText = L.uppercaseCharacter(props.position);
        break;
      case 'uppercaseGreekLabel':
        labelText = L.uppercaseGreek(props.position);
        break;
      case 'lowercaseGreekLabel':
        labelText = L.lowercaseGreek(props.position);
        break;
      case 'uppercaseRomanLabel':
        labelText = L.uppercaseRoman(props.position);
        break;
      case 'lowercaseRomanLabel':
        labelText = L.lowercaseRoman(props.position);
        break;
      case 'runeLabel':
        labelText = L.rune(props.position);
        break;
    }
    return <span>{labelText}.</span>;
  }
}

type OptionalAnswerLabelDisplayProps = {
  labelType: L.AnswerLabel | null;
  position: number;
}

export function OptionalAnswerLabelDisplay(props: OptionalAnswerLabelDisplayProps) {
  return <AnswerLabelDisplay labelType={props.labelType || 'noLabel'} position={props.position}/>
}
