import { zip } from "utils";

export function listUndoSufficientDifference<T>(
  last: T[], current: T[], undoSufficientDifference: (_: T, __: T) => boolean
): boolean {
  return last.length !== current.length || zip(last, current).some(([l, c]) => undoSufficientDifference(l, c));
}

const UNDO_SUFFICIENT_DIFFERENCE_CHARACTERS = 10;

export function stringUndoSufficientDifference(last: string, current: string): boolean {
  const lengthDifference = Math.abs(last.length - current.length);
  if (lengthDifference >= UNDO_SUFFICIENT_DIFFERENCE_CHARACTERS) {
    return true;
  } else {
    let inplaceDifference = 0;
    const minLength = Math.min(last.length, current.length);
    for (let i = 0; i < minLength; i++) {
      if (last[i] !== current[i]) {
        inplaceDifference++;
      }
    }
    return lengthDifference + inplaceDifference >= UNDO_SUFFICIENT_DIFFERENCE_CHARACTERS;
  }
}