import React from 'react';
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

type ExpansionIndicatorProps = {
  open: boolean;
  additionalClasses?: string;
  onClick?: () => void;
}

export default function ExpansionIndicator(props: ExpansionIndicatorProps) {
  const onClick = props.onClick || (() => {});
  return (
    <div className={props.additionalClasses || ""} onClick={event => onClick()}>
      {props.open ? <FaChevronDown/> : <FaChevronRight/>}
    </div>
  );
}
