import { SelectableAnswer, selectableAnswerEquals } from "./selectableAnswer";
import * as E from "TextEditor";

export type OrderingAnswer = SelectableAnswer & {
  position: number;
};

export function constructOrderingAnswer(position: number): OrderingAnswer {
  return {
    content: E.constructContent(),
    position: position,
    images: []
  };
}

export function reposition(answers: OrderingAnswer[]): OrderingAnswer[] {
  return answers.map((answer, index) => ({
    ...answer,
    position: index
  }));
}

export function orderingAnswerEquals(a: OrderingAnswer, b: OrderingAnswer): boolean {
  return selectableAnswerEquals(a, b) && a.position === b.position;
}