import React, { ReactNode } from 'react';
import { QuestionSettingsModel, Setting } from "./questionSettingsModel";
import { AnswerLabel } from "labels";
import { castMaximal, applicableSettingsFields } from "./settingsOperations";
import { QuestionType } from "questionTypes";
import { ToggleDisplay } from "commonComponents/Toggle";
import { GlossaryKey, g } from "language";
import styles from "./QuestionSettings.module.scss";

type SettingsDisplayProps = {
  questionType: QuestionType;
  settings: QuestionSettingsModel;
  relevant?: Setting[];
}

export default function SettingsDisplay(props: SettingsDisplayProps) {
  const settings = castMaximal(props.settings);
  const viableSettings = applicableSettingsFields(props.questionType);
  const relevantSet = new Set(props.relevant || []);
  const settingRelevant = (s: Setting) => {
    if (props.relevant !== undefined) {
      return relevantSet.has(s);
    } else {
      return true;
    }
  };
  const applicable = (s: Setting) => {
    return settingRelevant(s) && viableSettings.has(s);
  };
  return (
    <div className={styles['settings-toggles']}>
      {applicable('ignoreWhitespace') && (
        <ToggleSettingsRow labelKey={'ignoreWhitespaceHeader'} on={settings.ignoreWhitespace}/>
      )}
      {applicable('ignoreCapitalization') && (
        <ToggleSettingsRow labelKey={'ignoreCapitalizationHeader'} on={settings.ignoreCapitalization}/>
      )}
      {applicable('ignorePunctuation') && (
        <ToggleSettingsRow labelKey={'ignorePunctuationHeader'} on={settings.ignorePunctuation}/>
      )}
      {applicable('labelType') && (
        <SettingsRow labelKey={'labelTypeHeader'}>
          {settings.labelType ? <LabelTypeDisplay answerLabel={settings.labelType}/> : <span/>}
        </SettingsRow>
      )}
      {applicable('ordered') && (
        <ToggleSettingsRow labelKey={'orderedHeader'} on={settings.ordered}/>
      )}
      {applicable('errorMargin') && (
        <SettingsRow labelKey={'errorMarginHeader'}>
          <>{settings.errorMargin || 0}%</>
        </SettingsRow>
      )}
      {applicable('requiredCount') && (
        <SettingsRow labelKey={'requiredCountHeader'}>
          <RequiredCountDisplay requiredCount={settings.requiredCount}/>
        </SettingsRow>
      )}
      {applicable('randomize') && (
        <ToggleSettingsRow labelKey={'randomizeHeader'} on={settings.randomize}/>
      )}
      {applicable('imageAfter') && (
        <SettingsRow labelKey={'pictureLocationHeader'}>
          <ImagePositionDisplay imageAfter={settings.imageAfter}/>
        </SettingsRow>
      )}
    </div>
  )
}

type SettingsRowProps = {
  labelKey: GlossaryKey;
  children: ReactNode;
};

function SettingsRow(props: SettingsRowProps) {
  const title = g(props.labelKey);
  return (
    <>
      <span>{title}</span>
      {props.children}
    </>
  )
}

type ToggleSettingsRowProps = {
  labelKey: GlossaryKey;
  on: boolean;
};

function ToggleSettingsRow(props: ToggleSettingsRowProps) {
  return (
    <SettingsRow labelKey={props.labelKey}>
      <ToggleDisplay on={props.on}/>
    </SettingsRow>
  );
}

type LabelTypeDisplayProps = {
  answerLabel: AnswerLabel;
};

function LabelTypeDisplay(props: LabelTypeDisplayProps) {
  return <>{g(props.answerLabel)}</>;
}

type RequiredCountDisplayProps = {
  requiredCount: number | null;
};

function RequiredCountDisplay(props: RequiredCountDisplayProps) {
  const allText = g('all');
  const rc = props.requiredCount;
  return <>{rc === null ? allText : rc}</>;
}

type ImagePositionDisplayProps = {
  imageAfter: boolean;
}

function ImagePositionDisplay(props: ImagePositionDisplayProps) {
  return (
    <span className={styles['image-position-row']}>
      <span>{g('imageTop')}</span>
      <ToggleDisplay on={props.imageAfter}/>
      <span>{g('imageBottom')}</span>
    </span>
  )
}
