import React, { ReactNode, useRef } from 'react';
import useClosable from "../closable";
import { HiOutlineX } from "react-icons/hi";
import styles from "./Modal.module.scss";

type ModalProps = {
  visible: boolean;
  setVisible: (_: boolean) => void;
  children: ReactNode;
  wideMode?: boolean;
}

export default function Modal(props: ModalProps) {
  const wideMode = props.wideMode === true ? true : false;
  const ref = useRef(null);
  useClosable(ref, () => props.setVisible(false));
  const modalClass = props.visible ? styles['modal-background'] : styles['modal-hidden'];
  const modalBoxClass = styles[wideMode ? 'modal-box-wide-mode' : 'modal-box'];
  return (
    <div className={modalClass}>
      <div className={modalBoxClass} ref={ref}>
        <span className={styles['exit-button']} onClick={() => props.setVisible(false)}>
          <HiOutlineX/>
        </span>
        {props.children}
      </div>
    </div>
  );
}
