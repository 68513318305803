import React, { ReactNode } from 'react';
import styles from "./LowerBar.module.scss";

type LowerBarProps = {
  children: ReactNode;
  fullPage: boolean;
};

export default function LowerBar(props: LowerBarProps) {
  const className = styles[props.fullPage ? 'bar' : 'inline-bar'];
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}
