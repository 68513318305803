import { useEffect } from 'react';
import { UndoHistory } from './undoHistory';

export function undoEvent(event: KeyboardEvent, undo: () => void, redo: () => void) {
  if (undoKeysPressed(event)) {
    event.preventDefault();
    undo();
  }
  if (redoKeysPressed(event)) {
    event.preventDefault();
    redo();
  }
}

function undoKeysPressed(event: KeyboardEvent): boolean {
  return rightKeysPressed(event, "KeyZ");
}

function redoKeysPressed(event: KeyboardEvent): boolean {
  return rightKeysPressed(event, "KeyY");
}

function rightKeysPressed(event: KeyboardEvent, keyCode: string): boolean {
  return (event.metaKey || event.ctrlKey) && event.code === keyCode;
}

export function useUndo(undo: () => void, redo: () => void) {
  useEffect(() => {
    window.addEventListener('keydown', event => {
      undoEvent(event, undo, redo);
    });
  }, []);
}

export function useUndoHistory<T extends object>(history: UndoHistory<T>) {
  useUndo(() => history.undo(), () => history.redo());
}