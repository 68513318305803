import * as Q from "questionTypes";
import { Tag } from "tags";
import { defaultMaximalSettingsState, MaximalQuestionSettingsModel } from "QuestionSettings";
import { Content, constructContent } from "TextEditor";
import { Hints, initialHints } from "QuestionHints";
import { LabeledFile } from "pictures";

export function defaultQuestionType(): Q.QuestionType {
  return 'write-in';
}

export function defaultTags(): Tag[] {
  return [];
}

export function defaultTagIds(): string[] {
  return [];
}

export function defaultSettings(): MaximalQuestionSettingsModel {
  return defaultMaximalSettingsState();
}

export function defaultNotes(): Content {
  return constructContent();
}

export function defaultHints(): Hints {
  return initialHints();
}

export function defaultFiles(): LabeledFile[] {
  return [];
}
