import React from 'react';
import { Link } from 'react-router-dom';
import { g } from 'language';

export default function SignInMessage() {
  return (
    <div>
      <h2>{g('signedOutWarning')}</h2>
      <Link to="/sign-up">{g('signInButtonText')}</Link>
    </div>
  );
}
