import React, { ReactNode } from 'react';
import styles from "./FormSection.module.scss";

type FormSectionProps = {
  title: string;
  children: ReactNode;
  additionalClasses?: string;
};

export default function FormSection(props: FormSectionProps) {
  const outerClass = `${styles['form-section']} ${props.additionalClasses || ""}`;
  return (
    <div className={outerClass}>
      <div className={styles['title']}>
        {props.title}
      </div>
      {props.children}
    </div>
  )
}
