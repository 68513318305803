import { useState } from 'react';
import { GlossaryKey, g } from 'language';
import { LayoutDirection } from 'commonComponents/settings';
import styles from "./TabNavigator.module.scss";

type TabNavigatorProps = {
  tabs: TabDescriptor[];
  initialTitleKey?: GlossaryKey;
  additionalClasses?: string;
  direction: LayoutDirection;
}

export default function TabNavigator(props: TabNavigatorProps) {
  const classes = `${props.additionalClasses || ""}`;
  const [openTabKey, setOpenTabKey] = useState<GlossaryKey>(props.initialTitleKey || props.tabs[0].titleKey);
  const openTabIndex = props.tabs.findIndex(tab => tab.titleKey === openTabKey);
  return (
    <div className={classes}>
      <TabSelectorDelineation
        tabKeys={props.tabs.map(tab => tab.titleKey)}
        setOpenTabKey={setOpenTabKey}
        direction={props.direction}
        openTabIndex={openTabIndex}
      />
      <div className={styles[`tab-display-${props.direction}`]}>
        {props.tabs[openTabIndex]?.display || null}
      </div>
    </div>
  );
}

type TabDescriptor = {
  titleKey: GlossaryKey;
  display: JSX.Element;
};

type TabSelectorDelineationProps = {
  tabKeys: GlossaryKey[];
  setOpenTabKey: (_: GlossaryKey) => void;
  direction: LayoutDirection;
  openTabIndex: number;
}

function TabSelectorDelineation(props: TabSelectorDelineationProps) {
  const className = styles[`tab-selector-delineation-${props.direction}`];
  return (
    <div className={className}>
      {props.tabKeys.map((key, index) => (
        <Tab
          key={key}
          titleKey={key}
          onClick={() => props.setOpenTabKey(key)}
          index={index}
          openTabIndex={props.openTabIndex}
          tabNumber={props.tabKeys.length}
        />
      ))}
    </div>
  );
}

type TabProps = {
  titleKey: GlossaryKey;
  onClick: () => void;
  index: number;
  openTabIndex: number;
  tabNumber: number;
}

function Tab(props: TabProps) {
  const index = props.index;
  const openIndex = props.openTabIndex;
  const open = index === openIndex;
  const first = index === 0;
  const last = index === props.tabNumber - 1;
  const firstClass = (open && first) ? styles['open-first-tab'] : first ? styles['first-tab'] : '';
  const lastClass = (open && last) ? styles['open-last-tab'] : last ? styles['last-tab'] : '';
  const openClass = styles[open ? 'open-tab' : 'closed-tab'];
  const rightOfOpenClass = index + 1 === openIndex ? styles['right-of-open-tab'] : '';
  const leftOfOpenClass = index - 1 === openIndex ? styles['left-of-open-tab'] : '';
  const className = `${firstClass} ${lastClass} ${openClass} ${rightOfOpenClass} ${leftOfOpenClass}`;
  return (
    <div className={className} onClick={props.onClick}>
      {g(props.titleKey)}
    </div>
  );
}
