import { Content } from "../TextEditor";

export type Hint = {
  text: Content;
  position: number;
};

export type Hints = {
  hints: Hint[];
};

export function initialHints(): Hints {
  return {
    hints: []
  };
}
