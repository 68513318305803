// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContent_main-content__C7r3Q {
  margin-top: 3rem;
}
@media (min-width: 600px) {
  .MainContent_main-content__C7r3Q {
    margin-left: 1rem;
  }
}
@media (max-width: 600px) {
  .MainContent_main-content__C7r3Q {
    margin-left: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/MainContent/MainContent.module.scss","webpack://./src/styles/constants.module.scss"],"names":[],"mappings":"AAEA;EACE,gBCcc;ADfhB;AAEE;EAFF;IAGI,iBCckB;EDbpB;AACF;AAAE;EALF;IAMI,mBCYY;EDTd;AACF","sourcesContent":["@import \"../styles/constants.module.scss\";\n\n.main-content {\n  margin-top: $navbar-height;\n  @media(min-width: $mobile) {\n    margin-left: $desktop-left-margin;\n  }\n  @media(max-width: $mobile) {\n    margin-left: $mobile-margin;\n  }\n}\n","$blue-green: #00ccaa;\n$blue: #00aaff;\n$dark-blue: #0066ff;\n$green: #00bb00;\n$dark-green: #008800;\n$light-gray: #dddddd;\n$ultra-light-gray: #dfdfdf;\n$mid-light-gray: #bbbbbb;\n$gray: gray;\n$dark-gray: #282828;\n$red: salmon;\n$pale-blue: #87cefa;\n$off-white: #f8f8ff;\n$purple: #800080;\n$dark-red: #dc143c;\n$light-green: #00ffef;\n\n$navbar-height: 3rem;\n$mobile: 600px;\n$desktop-left-margin: 1rem;\n$mobile-margin: 0.5rem;\n$desktop-margin: 20rem;\n$desktop-wide-view-margin: 3rem;\n$mobile-default-font-size: 0.7rem;\n$mobile-label-size: 0.8rem;\n\n$external-chiclet-padding: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-content": `MainContent_main-content__C7r3Q`
};
export default ___CSS_LOADER_EXPORT___;
