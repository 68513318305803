const glossaryKeys = [
  'questionSettingsHeader',
  'ignoreWhitespaceHeader',
  'ignoreWhitespaceExplanation',
  'ignoreCapitalizationHeader',
  'ignoreCapitalizationExplanation',
  'ignorePunctuationHeader',
  'ignorePunctuationExplanation',
  'errorMarginHeader',
  'errorMarginExplanation',
  'randomizeHeader',
  'randomizeExplanation',
  'pictureLocationHeader',
  'pictureLocationExplanation',
  'notesExplanation',
  'versionLabel', 'versionIndicator',
  'editedLabel',
  'createdLabel',
  'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december',
  'yesterday', 'today',
  'disableHeader', 'disableExplanation',
  'questionLabel', 'answerLabel', 'notesLabel', 'imageLabelLabel', 'tagLabel', 'hintsLabel',
  'viewFullQuestion',
  'any', 'all', 'except',
  'tagExplanation', 'tagExclusionExplanation',
  'tagPatterns', 'tagPatternExplanation', 'patternAllExplanation', 'patternAnyExplanation', 'patternExceptExplanation',
  'allowRepeats',
  'quizFeasibilityWarningRepeatsNotAllowed', 'quizFeasibilityWarningRepeatsAllowed', 'quizFeasibilityWarningRepeatsNotAllowedSingular',
  'bold', 'italic', 'underline', 'strikethrough',
  'linkEditPrompt', 'testLink',
  'mathEditorPrompt', 'latex', 'ascii',
  'latin', 'greek', 'cyrillic', 'hebrew', 'arabic', 'symbols', 'runic',
  'write-in', 'multiple-choice', 'fill-in-the-blank', 'true-false', 'multi-select', 'ordering',
  'question', 'answer', 'answers', 'correctAnswer', 'otherAnswers', 'correctAnswers', 'incorrectAnswers',
  'imageTop', 'imageBottom',
  'noLabel', 'numberLabel', 'uppercaseLabel', 'lowercaseLabel', 'uppercaseGreekLabel', 'lowercaseGreekLabel', 'uppercaseRomanLabel', 'lowercaseRomanLabel', 'runeLabel',
  'labelTypeHeader', 'orderedHeader', 'requiredCountHeader',
  'labelTypeExplanation', 'orderedExplanation', 'requiredCountExplanation',
  'allRequired',
  'questionNumber',
  'signInButtonText', 'signUpButtonText', 'signInHeaderText', 'signUpHeaderText',
  'settingsReminder',
  'flaggedText',
  'ignoreWhitespacePassive', 'ignoreWhitespaceActive',
  'ignoreCapitalizationPassive', 'ignoreCapitalizationActive',
  'ignorePunctuationPassive', 'ignorePunctuationActive',
  'errorMarginPassive', 'errorMarginActive',
  'orderedPassive', 'orderedActive',
  'questionBodySectionHeader', 'pictureSectionHeader', 'notesSectionHeader', 'settingsSectionHeader', 'tagSectionHeader', 'hintsSectionHeader',
  'showHint', 'showNextHint',
  'hintDisplayLabel', 'hintDisplayLabelSingle',
  'cancelQuizButton', 'saveQuizButton', 'submitQuizButton',
  'cancel',
  'quizHeader',
  'questionsMinor', 'questionMinor', 'questionMinorPlural',
  'matchingAny', 'matchingAll', 'matchingExcept',
  'quizSaved',
  'cancelQuizHeader', 'cancelQuizText', 'avoidCancelButton', 'quizCanceled',
  'makeNewQuiz', 'backToHome',
  'activeQuizWarning', 'activeQuizLink',
  'submitQuizHeader',
  'true', 'false',
  'unattemptedQuestions', 'submitNowButton', 'avoidSubmitButton',
  'questionSubmissionSuccessful', 'questionSubmissionFailed',
  'loadingError',  'stillLoading',
  'quizResultLoadFailed', 'quizResultLoadingMessage', 'quizResults',
  'retakeButtonText', 'retakeMissedButtonText',
  'questionNumberHeader', 'questionTagsHeader', 'questionPointsHeader', 'questionFlaggedHeader',
  'point', 'pointPlural',
  'summary',
  'singleSelectionIndicator', 'multiSelectionIndicator',
  'percent',
  'update', 'disable',
  'showEditExplanation', 'noMatchIndicator',
  'substitutionExplanation', 'insertionExplanation', 'deletionExplanation',
  'correctIndicator', 'incorrectIndicator', 'distanceSummary', 'editDistanceMessage', 'ignoredCharactersText', 'punctuation', 'whitespace', 'capital', 'caseInterchangeabilityText',
  'and',
  'notes', 'notesAbsent',
  'versionScoringExplanation', 'quizQuestionEditCaveat', 'attentionRequest',
  'editQuestionHeader', 'questionAbsentMessage',
  'latestVersionUpdates', 'questionVersionUpdate', 'questionTypeUpdate', 'questionBodyUpdate', 'questionImagesUpdate', 'questionHintsUpdate', 'questionTagsUpdate', 'questionSettingsUpdate', 'questionNotesUpdate',
  'noTags', 'addTag', 'tagHeader', 'excludedTagHeader', 'excludeTag',
  'quizResultsEditingMessage',
  'inOrderIncorrectIndicator', 'outOfOrderIndicator', 'noMatchIndicator',
  'duplicateAnswer',
  'questionBlankMessage', 'answerBlankMessage', 'duplicateAnswerMessage', 'noIncorrectAnswersMessage', 'noBlanksMessage', 'noAnswersMessage', 'validMessage', 'lessThanTwoAnswersMessage', 'noActivePartitionsMessage',
  'newQuestionButtonText', 'newQuestionHeader',
  'questionTypeIndicator', 'questionTypeLabel',
  'fillInTheBlankPrompt',
  'answerInOrder', 'answerOutOfOrder',
  'responseOrderHeader', 'questionOrderHeader',
  'notesInfoHeader',
  'hintInfoHeader', 'hintExplanation',
  'questionTypeInfoHeader',
  'write-in-info-explanation', 'multiple-choice-info-explanation', 'multi-select-info-explanation', 'ordering-info-explanation', 'fill-in-the-blank-info-explanation', 'true-false-info-explanation',
  'questionTypeGeneral',
  'newQuizHeader',
  'editLastQuestion',
  'authenticateInMessage', 'signOutMessage',
  'passwordLengthRequirement', 'passwordLowerCaseRequirement', 'passwordUpperCaseRequirement', 'passwordNumberRequirement',
  'editCaveat',
  'questionSettingsHeader',
  'loadingMessage',
  'allMatches', 'newQuizQuestionsLabel', 'noQuestionsCreated', 'createNewQuestion', 'newQuizQuestionSectionLabel', 'newQuizTagSectionLabel',
  'clearButton',
  'questionsPageHeader',
  'newQuestionLink', 'takeQuizLink', 'searchQuestionsLink', 'userHomePageGreeting',
  'tagLabel', 'addTagButtonText', 'excludedTagLabel',
  'pointsEarned', 'level', 'pointsToNextLevel', 'levelAndPoints', 'coreButtonsHeader',
  'homePageNavigationLink', 'newQuestionNavigationLink', 'newQuizNavigationLink', 'questionsNavigationLink',
  'emailLabel', 'invalidSignInWarning', 'duplicateEmailWarning', 'duplicateLoginNameWarning', 'invalidEmailFormatWarning', 'loginNameLabel', 'loginIdentifierLabel',
  'signSubmission', 'passwordLabel',
  'closeButton',
  'signedOutWarning',
  'exampleLetter', 'mathBlockLabel',
  'addPictureLabel',
  'matchedSearchTags', 'questionSearchResults', 'questionSearchHeader', 'questionSearchLink',
  'startQuizButtonText',
  'addHintsLabel',
  'homePageLink',
  'questionFilterHeader',
  'searchButton',
  'questionOrdinalHeader', 'promptHeader', 'questionTypeHeader', 'editedDateHeader', 'tagsHeader', 'versionHeader',
  'questionWithOrdinal',
  'versionTimelineLabel', 'questionEditingIndicator', 'questionVersion', 'latestVersionLabel', 'viewVersionLabel', 'editingVersionLabel',
  'imageLabel',
  'questionNotFound',
  'hintsAbsent', 'imagesAbsent',
  'patternTextBefore', 'patternTextAfter',
  'sortByLabel', 'dateLabel',
  'excludingTagsQualifier', 'excludingTagsStandalone',
  'pageSize', 'totalRowCount', 'tableRowCountIndicator',
  'questionsLoadFailed',
  'questionResultsDisplay', 'questionResultsSummary', 'quizNumberLabel', 'quizStartedLabel', 'historyQuizDetail', 'historyQuestionDetail',
  'hintRevealedLabel', 'hintsRevealedNumeric',
  'firstQuizAppearanceLabel', 'latestQuizAppearanceLabel', 'totalPointsLabel', 'totalQuizAppearancesLabel', 'totalFullyCorrectLabel', 'totalFullyIncorrectLabel', 'totalPartiallyCorrectLabel', 'aggregateQuizStatisticsLabel', 'averagePointsEarnedLabel',
  'anteMeridian', 'postMeridian',
  'year', 'yearPlural', 'month', 'monthPlural', 'day', 'dayPlural', 'ago',
  'sortByDate', 'dateSortDecreasing', 'dateSortIncreasing',
  'sortByPoints', 'pointSortDecreasing', 'pointSortIncreasing',
  'noResults', 'noQuizzesForQuestion',
  'selectAll',
  'endDateLabel', 'startDateLabel', 'accountCreationDateLabel', 'currentDateSelectedLabel', 'dateFormat', 'displayDateFormat',
  'none',
  'sundayFull', 'mondayFull', 'tuesdayFull', 'wednesdayFull', 'thursdayFull', 'fridayFull', 'saturdayFull',
  'sundayAbbreviated', 'mondayAbbreviated', 'tuesdayAbbreviated', 'wednesdayAbbreviated', 'thursdayAbbreviated', 'fridayAbbreviated', 'saturdayAbbreviated',
  'defaultIndicator', 'resetDefault', 'resetButton',
  'largerThanEndDate', 'smallerThanStartDate',
  'blank',
  'questionCreatedDateVariant', 'questionEditedDateVariant', 'questionLastEditedDateVariant', 'questionFirstTakenInQuizDateVariant', 'questionTakenInQuizDateVariant', 'questionLastTakenInQuizDateVariant', 'questionEditedOrTakenInQuizDateVariant',
  'questionDateMeaning',
  'questionTypeAndSettingsLabel',
  'settingsSearchDisclaimer',
  'answersRequiredPartial', 'answersRequiredAll',
  'componentPresenceHeader', 'hasHintsLabel', 'hasImagesLabel', 'hasNotesLabel', 'notPresentLabel', 'presentLabel',
  'includeSettingHeader', 'settingNameHeader', 'settingValueHeader',
  'textSearchLabel', 'textSearchIgnoreCase', 'textSearchVersions', 'textSearchAllVersions', 'textSearchLatestVersionOnly', 'beforeTextPattern', 'afterTextPattern', 'textSearchLabel',
  'questionId'
] as const;

export type GlossaryKey = typeof glossaryKeys[number];

export type Glossary = {
  [k in GlossaryKey]: string;
};
