import React from 'react';
import { useGlossary as g } from "../../language";

export default function TagPatternInfo() {
  return (
    <>
      <p>{g('tagPatternExplanation')}</p>
      <p>{g('patternAnyExplanation')}</p>
      <p>{g('patternAllExplanation')}</p>
    </>
  );
}
