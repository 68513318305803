import * as Z from "./quiz";
import * as Q from "../questionTypes";
import * as R from "./response";

export type QuizProgressEnvelope = {
  quizId: string;
  effectiveTime: string;
  progress: QuizProgress;
};

export type QuizProgress = {
  flagged: string[];
  hintsRevealed: HintProgress[];
  responses: QuestionResponse[];
};

type HintProgress = {
  questionIndex: number;
  revealed: number;
};

export function quizProgress(
  quizId: string,
  responses: Map<number, R.Response>,
  flagged: Set<string>,
  hints: Map<number, number>,
  questions: Z.QuizQuestion[]
): QuizProgressEnvelope {
  return {
    quizId,
    effectiveTime: new Date().toISOString(),
    progress: rawProgress(flagged, hints, responses, questions)
  };
}

export function rawProgress(
  flagged: Set<string>,
  hints: Map<number, number>,
  responses: Map<number, R.Response>,
  questions: Z.QuizQuestion[]
): QuizProgress {
  return {
    flagged: flattenFlaggedQuestions(flagged),
    hintsRevealed: flattenHints(hints),
    responses: serializableResponses(responses, questions)
  }
}

function flattenFlaggedQuestions(flagged: Set<string>): string[] {
  return Array.from(flagged.values());
}

function flattenHints(hints: Map<number, number>): HintProgress[] {
  return Array.from(hints.entries()).map(pair => {
    return {
      questionIndex: pair[0],
      revealed: pair[1]
    }
  });
}

type QuestionResponse = {
  index: number;
  response: FinalResponse;
};

export type FinalResponse = R.WriteInResponse |
                            R.TrueFalseResponse |
                            R.FillInTheBlankResponse |
                            R.MultipleChoiceResponse |
                            MultiSelectResponse |
                            R.OrderingResponse;

type MultiSelectResponse = {
  responses: string[];
};

function multiSelectResponse(response: FinalResponse): response is MultiSelectResponse {
  return (response as MultiSelectResponse).responses !== undefined;
}

export function serializableMultiSelectResponse(response: R.Response): MultiSelectResponse {
  const ms = response as R.MultiSelectResponse;
  return {
    responses: Array.from(ms.selections.values())
  }
}

function serializableResponses(responses: Map<number, R.Response>, questions: Z.QuizQuestion[]): QuestionResponse[] {
  const indexToType = questions.reduce((accumulator, question) => {
    accumulator.set(question.index, question.questionType);
    return accumulator;
  }, new Map<number, Q.QuestionType>());
  return Array.from(responses.entries()).map(responsePair => {
    const index = responsePair[0];
    const response = responsePair[1];
    const questionType = indexToType.get(index)!;
    return {
      index,
      response: Q.functionalityByType(questionType).serializableResponse(response)
    };
  });
}

function extractMultiSelect(response: MultiSelectResponse): R.MultiSelectResponse {
  return {
    selections: new Set(response.responses)
  };
}

function actionableResponses(responses: QuestionResponse[]): Map<number, R.Response> {
  return responses.reduce((accumulator, response) => {
    if (multiSelectResponse(response.response)) {
      accumulator.set(response.index, extractMultiSelect(response.response));
    } else {
      accumulator.set(response.index, response.response);
    }
    return accumulator;
  }, new Map<number, R.Response>());
}

type ProgressExtract = {
  responses: Map<number, R.Response>,
  flagged: Set<string>,
  hints: Map<number, number>
};

export function actionableProgress(progress: QuizProgress): ProgressExtract {
  return {
    responses: actionableResponses(progress.responses),
    flagged: new Set(progress.flagged),
    hints: extractHintsRevealed(progress.hintsRevealed)
  }
}

function extractHintsRevealed(hints: HintProgress[]): Map<number, number> {
  return hints.reduce((accumulator, hint) => {
    accumulator.set(hint.questionIndex, hint.revealed);
    return accumulator;
  }, new Map<number, number>());
}
