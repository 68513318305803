import React, { useState, ReactNode } from 'react';
import styles from "./QuizResultsPage.module.scss";
import { CompletedQuizQuestion } from "./quizResults";
import {
  isTextualSettings, errorMargin, QuestionSettingsModel, ignoreCapitalization
} from "QuestionSettings";
import { g, gt } from "language";
import { ignoredCharactersIndicator } from "./quizResultOperations";
import { NotesDisplay } from "QuestionNotes";
import { Question } from "questionTypes";
import { QuestionEditContext } from "questionContext";
import { QuestionEditorManager } from "QuestionPage";
import { useUser } from "user";
import LatestVersionBox from "./LatestVersionBox";
import { Tag } from "tags";
import EditIcon from "commonComponents/EditIcon";

type QuestionResultBoxProps = {
  question: CompletedQuizQuestion;
  open: boolean;
  questionSlot: ReactNode;
  answerSlot: ReactNode;
  latest: Question | null;
  addLatest: (_: Question) => void;
  allTags: Tag[];
  editing: boolean;
  toggleEditing: (_: boolean) => void;
};

export default function QuestionResultBox(props: QuestionResultBoxProps) {
  const className = styles[props.open ? 'question-result-box' : 'invisible-question-result-box'];
  const [questionContext, setQuestionContext] = useState<QuestionEditContext>(props.question.question);
  const settings = questionContext.question.settings;
  const version = questionContext.question.version;
  const questionId = questionContext.question.questionId;
  const [baseVersion, setBaseVersion] = useState(questionContext.question.version);
  const onCompletion = (q: Question) => {
    props.toggleEditing(false);
    props.addLatest(q);
  };
  const iconPositionClass = styles['edit-icon-position'];
  const editContext = props.latest ? {
    question: props.latest,
    allTags: props.allTags
  } : undefined;
  if (!props.open) {
    return null;
  } else {
    return (
      <div className={className}>
        <EditIcon editing={props.editing} setEditing={props.toggleEditing} additionalClasses={iconPositionClass}/>
        <EditSection
          editing={props.editing}
          onCompletion={onCompletion}
          defaultContext={props.question.question}
          setQuestionContext={setQuestionContext}
          baseVersion={baseVersion}
          setBaseVersion={setBaseVersion}
        />
        <LatestVersionBox original={questionContext.question} latest={props.latest}/>
        <label>{g('question')}</label>
        {props.questionSlot}
        <ScoringGuidelines settings={settings}/>
        {props.answerSlot}
        <NotesDisplay text={questionContext.question.notes} includeHeader={true}/>
        <div className={styles['scoring-explanation']}>
          {gt('versionScoringExplanation', [`${version}`])}
        </div>
      </div>
    );
  }
}

type EditSectionProps = {
  editing: boolean;
  onCompletion: (_: Question) => void;
  defaultContext: QuestionEditContext;
  setQuestionContext: (_: QuestionEditContext) => void;
  baseVersion: number;
  setBaseVersion: (_: number) => void;
}

function EditSection(props: EditSectionProps) {
  const userContext = useUser();
  const resetVersion = (version: number) => {};
  return (props.editing && userContext.user) ? (
    <>
      <EditCaveat/>
      <QuestionEditorManager
        displayContext={'inline'}
        onCompletion={props.onCompletion}
        questionContext={props.defaultContext}
        setQuestionContext={props.setQuestionContext}
        baseVersion={props.baseVersion}
        setBaseVersion={props.setBaseVersion}
        onSelectVersion={resetVersion}
      />
    </>
  ) : null;
}

function EditCaveat() {
  return (
    <div className={styles['caveat-wrapper']}>
      <span className={styles['caveat-attention']}>{g('attentionRequest')}</span>
      <span className={styles['caveat-text']}>{g('quizQuestionEditCaveat')}</span>
    </div>
  )
}

type ScoringGuidelinesProps = {
  settings: QuestionSettingsModel;
}

function ScoringGuidelines(props: ScoringGuidelinesProps) {
  const textual = isTextualSettings(props.settings);
  const editDistance = errorMargin(props.settings);
  const editDistanceText = editDistance !== null ? gt('editDistanceMessage', [`${editDistance}`]) : null;
  const characterIgnoredText = textual ? ignoredCharactersIndicator(props.settings) : null;
  const capitalizationText = g('caseInterchangeabilityText');
  const capitalizationDisplay = ignoreCapitalization(props.settings) ? capitalizationText : null;
  return (
    <div className={styles['scoring-guidelines']}>
      <div className={styles['scoring-guideline']}>{editDistanceText}</div>
      <div className={styles['scoring-guideline']}>{characterIgnoredText}</div>
      <div className={styles['scoring-guideline']}>{capitalizationDisplay}</div>
    </div>
  );
}
