export type ImageType = "png" | "jpeg";

export type LabeledFileHandle = {
  label: string;
  id: string;
  imageType: ImageType;
};

export type LabeledFile = LabeledFileHandle & {
  file: File | null;
};

export function labeledFileHandleEquals(a: LabeledFileHandle, b: LabeledFileHandle): boolean {
  return a.label === b.label && a.id === b.id && a.imageType === b.imageType;
}