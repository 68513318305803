import { port, getToken } from "./network";

const api = `wss://localhost:${port}`;

type WebsocketHeader = 'new-quiz-settings' | 'quiz-progress';

export function constructSocket(onMessage: (event: MessageEvent) => void): WebSocket {
  const socket = new WebSocket(api, ["realProtocol", getToken()]);
  socket.onmessage = onMessage;
  socket.onerror = error => {
    console.error(error);
  };
  return socket;
}

export function send<T extends object>(socket: WebSocket, header: WebsocketHeader, userId: string, body: T) {
  const payload = JSON.stringify({
    header,
    userId,
    body
  });
  switch (socket.readyState) {
    case WebSocket.CONNECTING:
      const oldOpen = socket.onopen;
      if (!oldOpen) {
        socket.onopen = () => {
          socket.send(payload);
        }
      } else {
        socket.onopen = ((event: Event) => {
          // @ts-ignore
          oldOpen(event);
          socket.send(payload);
        }).bind(socket);
      }
      break;
    case WebSocket.OPEN:
      socket.send(payload);
      break;
    default:
      throw new Error(`message ${header} send to socket in state ${socket.readyState}`)
  }
}

export type WebsocketMessage = {
  header: WebsocketHeader;
  body: object;
}
