import { LabeledFile, ImageType, LabeledFileHandle } from "./labeledFile";
import * as Q from "../questionTypes";
import { stringUndoSufficientDifference, listUndoSufficientDifference } from "undo";

export function imageType(file: File): ImageType {
  return file.name.endsWith("png") ? "png" : "jpeg"
}

export function extractHandles(pictures: LabeledFile[]): LabeledFileHandle[] {
  return pictures.map(picture => {
    return {
      id: picture.id,
      label: picture.label,
      imageType: picture.imageType
    };
  })
}

export function handlesToLabeledFiles(pictures: LabeledFileHandle[]): LabeledFile[] {
  return pictures.map(picture => {
    return {
      id: picture.id,
      file: null,
      label: picture.label,
      imageType: picture.imageType
    };
  })
}

export function answerImages(questionType: Q.QuestionType, body: Q.QuestionBody): LabeledFile[] {
  return handlesToLabeledFiles(Q.functionalityByType(questionType).answerImageHandles(body));
}

export function multipleChoiceAnswerImageHandles(body: Q.QuestionBody): LabeledFileHandle[] {
  const mc = body as Q.MultipleChoice;
  return [mc.correctAnswer].concat(mc.otherAnswers).flatMap(a => a.images);
}

export function multiSelectAnswerImageHandles(body: Q.QuestionBody): LabeledFileHandle[] {
  const ms = body as Q.MultiSelect;
  return ms.correctAnswers.concat(ms.otherAnswers).flatMap(a => a.images);
}

export function orderingAnswerImageHandles(body: Q.QuestionBody): LabeledFileHandle[] {
  const o = body as Q.Ordering;
  return o.order.flatMap(a => a.images);
}

type UploadablePictures = {
  files: LabeledFile[],
  handles: LabeledFileHandle[]
};

export function picturesForUpload(
  questionType: Q.QuestionType,
  body: Q.QuestionBody,
  pictures: LabeledFile[],
  answerPictures: LabeledFile[]
): UploadablePictures {
  const answerPictureIds = new Set(Q.functionalityByType(questionType).answerImageHandles(body).map(file => file.id));
  const acceptedAnswerPictures = answerPictures.filter(file => answerPictureIds.has(file.id));
  const allPictures = pictures.concat(acceptedAnswerPictures);
  const handles = extractHandles(pictures);
  return {
    files: allPictures,
    handles: handles
  };
}

export function handlesEqual(a: LabeledFileHandle, b: LabeledFileHandle): boolean {
  return a.id === b.id && a.label === b.label;
}

export function imageListsEqual(a: LabeledFileHandle[], b: LabeledFileHandle[]): boolean {
  return a.length === b.length && a.reduce((equal, a_image, index) => {
    const b_image = b[index];
    return equal && handlesEqual(a_image, b_image);
  }, true as boolean);
}

export function labeledFileHandleSufficientDifference(last: LabeledFileHandle, current: LabeledFileHandle): boolean {
  const imageTypeDifference = last.imageType !== current.imageType;
  const labelDifference = stringUndoSufficientDifference(last.label, current.label);
  const idDifference = last.id !== current.id;
  return imageTypeDifference || labelDifference || idDifference;
}

export function labeledFileUndoSufficientDifference(last: LabeledFile, current: LabeledFile): boolean {
  const fileDifference = last.file !== current.file;
  const labeledFileDifference = labeledFileHandleSufficientDifference(last, current);
  return fileDifference || labeledFileDifference;
}

export function labeledFileListUndoSufficientDifference(last: LabeledFile[], current: LabeledFile[]): boolean {
  return listUndoSufficientDifference(last, current, (l, c) => labeledFileUndoSufficientDifference(l ,c));
}