import React from 'react';
import styles from "./Toggle.module.scss";
import { defaultBoolean } from "logicUtils";
import { g, GlossaryKey } from "language";
import disabledStyles from "../../styles/disabled.module.scss";

type ColorVariant = 'green' | 'blue' | 'black' | 'red';

type ToggleProps = {
  on: boolean;
  onClick: () => void;
  neutral?: boolean;
  color?: ColorVariant;
  disabled?: boolean;
}

export default function Toggle(props: ToggleProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const neutral = defaultBoolean(props.neutral, false);
  const colorVariant = props.color || 'green';
  const colorClass = styles[`${colorVariant}-variant`];
  const selectedClass = ((props.on || neutral) && !disabled) ? styles['selected'] : "";
  const disabledClass = disabled ? styles['disabled'] : '';
  const innerSelectedClass = props.on ? styles['inner-selected'] : "";
  const outerClass = `${styles['outer-toggle']} ${selectedClass} ${colorClass} ${disabledClass}`;
  const innerClass = `${styles['inner-toggle']} ${innerSelectedClass}`;
  const onClick = () => {
    if (!disabled) {
      props.onClick();
    }
  };
  return (
    <span className={outerClass} onClick={() => onClick()}>
      <span className={innerClass}></span>
    </span>
  )
}

type ToggleDisplayProps = {
  on: boolean;
}

export function ToggleDisplay(props: ToggleDisplayProps) {
  return <Toggle on={props.on} onClick={() => {}}/>
}

type SymmetricToggleProps = {
  leftKey: GlossaryKey;
  rightKey: GlossaryKey;
  onClick: () => void;
  rightClick: () => void;
  leftClick: () => void;
  disabled?: boolean;
  on: boolean;
}

export function SymmetricToggle(props: SymmetricToggleProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const labelClass = `${styles['symmetric-label']} ${disabled ? disabledStyles['disabled-text'] : ''}`;
  return (
    <span className={styles['symmetric-toggle']}>
      <label className={labelClass} onClick={props.leftClick}>{g(props.leftKey)}</label>
      <Toggle
        on={props.on}
        onClick={props.onClick}
        disabled={disabled}
        neutral={true}
      />
      <label className={labelClass} onClick={props.rightClick}>{g(props.rightKey)}</label>
    </span>
  );
}
