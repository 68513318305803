import { RiPencilFill, RiPencilLine } from "react-icons/ri";
import styles from "./EditIcon.module.scss";

type EditIconProps = {
  editing: boolean;
  setEditing: (_: boolean) => void;
  additionalClasses?: string;
}

export default function EditIcon(props: EditIconProps) {
  const className = styles[props.editing ? 'edit-icon-active' : 'edit-icon'];
  const allClasses = `${className} ${props.additionalClasses || ""}`;
  return (
    <span className={allClasses} onClick={() => props.setEditing(!props.editing)}>
      {props.editing ? <RiPencilFill/> : <RiPencilLine/>}
    </span>
  );
}
