import React, { ReactNode } from 'react';
import * as Q from "questionTypes";
import { g, gt } from "language";
import styles from "./QuizResultsPage.module.scss";
import { LabeledFileHandle, imageListsEqual, ImageHandleDisplay } from "pictures";
import { Hints, HintsDisplay, hintsEqual } from "QuestionHints";
import { tagListEqual, TagListDisplay } from "tags";
import {
  QuestionSettingsModel, SettingsDisplay, settingsEqual, unequalSettings, labelType
} from "QuestionSettings";
import * as E from "TextEditor";
import { NotesDisplay } from "QuestionNotes";

type LatestVersionBoxProps = {
  original: Q.Question;
  latest: Q.Question | null;
}

export default function LatestVersionBox(props: LatestVersionBoxProps) {
  const header = gt('latestVersionUpdates', [`${props.original.version}`]);
  return props.latest !== null && props.original.version !== props.latest.version ? (
    <div className={styles['latest-version-box']}>
      <div className={styles['latest-version-header']}>{header}</div>
      <VersionDifference
        originalVersion={props.original.version}
        latestVersion={props.latest.version}
      />
      <QuestionTypeDifference
        originalType={props.original.questionType}
        latestType={props.latest.questionType}
      />
      <BodyDifference
        originalType={props.original.questionType}
        latestType={props.latest.questionType}
        originalBody={props.original.body}
        latestBody={props.latest.body}
        settings={props.latest.settings}
      />
      <ImageDifference
        originalImages={props.original.images}
        latestImages={props.latest.images}
      />
      <HintsDifference
        originalHints={props.original.hints}
        latestHints={props.latest.hints}
      />
      <TagDifference
        originalTagIds={props.original.tagIds}
        latestTagIds={props.latest.tagIds}
      />
      <SettingsDifference
        questionType={props.latest.questionType}
        originalSettings={props.original.settings}
        latestSettings={props.latest.settings}
      />
      <NotesDifference
        originalNotes={props.original.notes}
        latestNotes={props.latest.notes}
      />
    </div>
  ) : null;
}

type DifferenceStructureProps = {
  display: boolean;
  title: string;
  children: ReactNode;
}

function DifferenceStructure(props: DifferenceStructureProps) {
  return props.display ? (
    <div className={styles['difference']}>
      <div className={styles['difference-label']}>{props.title}</div>
      <div>{props.children}</div>
    </div>
  ) : null;
}

type VersionDifferenceProps = {
  originalVersion: number;
  latestVersion: number;
}

function VersionDifference(props: VersionDifferenceProps) {
  const header = g('questionVersionUpdate');
  const difference = props.originalVersion !== props.latestVersion;
  return (
    <DifferenceStructure title={header} display={difference}>
      {gt('versionIndicator', [`${props.latestVersion}`])}
    </DifferenceStructure>
  );
}

type QuestionTypeDifferenceProps = {
  originalType: Q.QuestionType;
  latestType: Q.QuestionType;
}

function QuestionTypeDifference(props: QuestionTypeDifferenceProps) {
  const header = g('questionTypeUpdate');
  const difference = props.originalType !== props.latestType;
  const displayLatestType = g(props.latestType);
  return (
    <DifferenceStructure title={header} display={difference}>
      {displayLatestType}
    </DifferenceStructure>
  )
}

type BodyDifferenceProps = {
  originalType: Q.QuestionType;
  latestType: Q.QuestionType;
  originalBody: Q.QuestionBody;
  latestBody: Q.QuestionBody;
  settings: QuestionSettingsModel;
}

function BodyDifference(props: BodyDifferenceProps) {
  const header = g('questionBodyUpdate');
  const difference = !Q.bodiesEqual(props.originalBody, props.latestBody);
  const label = labelType(props.settings);
  return (
    <DifferenceStructure title={header} display={difference}>
      {Q.functionalityByType(props.latestType).bodyDisplay({
        question: props.latestBody,
        labelType: label
      })}
    </DifferenceStructure>
  );
}

type ImageDifferenceProps = {
  originalImages: LabeledFileHandle[];
  latestImages: LabeledFileHandle[];
}

function ImageDifference(props: ImageDifferenceProps) {
  const header = g('questionImagesUpdate');
  const difference = !imageListsEqual(props.originalImages, props.latestImages);
  return (
    <DifferenceStructure title={header} display={difference}>
      <ImageHandleDisplay handles={props.latestImages} below={true}/>
    </DifferenceStructure>
  );
}

type HintsDifferenceProps = {
  originalHints: Hints;
  latestHints: Hints;
}

function HintsDifference(props: HintsDifferenceProps) {
  const header = g('questionHintsUpdate');
  const difference = !hintsEqual(props.originalHints, props.latestHints);
  return (
    <DifferenceStructure title={header} display={difference}>
      <HintsDisplay hints={props.latestHints}/>
    </DifferenceStructure>
  );
}

type TagDifferenceProps = {
  originalTagIds: string[];
  latestTagIds: string[];
}

function TagDifference(props: TagDifferenceProps) {
  const header = g('questionTagsUpdate');
  const difference = !tagListEqual(props.originalTagIds, props.latestTagIds);
  return (
    <DifferenceStructure title={header} display={difference}>
      <TagListDisplay tagIds={props.latestTagIds} indicateNoTags={true}/>
    </DifferenceStructure>
  );
}

type SettingsDifferenceProps = {
  questionType: Q.QuestionType;
  originalSettings: QuestionSettingsModel;
  latestSettings: QuestionSettingsModel;
}

function SettingsDifference(props: SettingsDifferenceProps) {
  const header = g('questionSettingsUpdate');
  const difference = !settingsEqual(props.originalSettings, props.latestSettings);
  const relevant = unequalSettings(props.originalSettings, props.latestSettings);
  return (
    <DifferenceStructure title={header} display={difference}>
      <SettingsDisplay
        questionType={props.questionType}
        settings={props.latestSettings}
        relevant={relevant}
      />
    </DifferenceStructure>
  );
}

type NotesDifferenceProps = {
  originalNotes: E.Content;
  latestNotes: E.Content;
}

function NotesDifference(props: NotesDifferenceProps) {
  const header = g('questionNotesUpdate');
  const difference = !E.equals(props.originalNotes, props.latestNotes);
  return (
    <DifferenceStructure title={header} display={difference}>
      <NotesDisplay text={props.latestNotes} includeHeader={false}/>
    </DifferenceStructure>
  );
}
