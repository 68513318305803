import { useState, useEffect } from 'react';
import styles from "./NumberInput.module.scss";
import { defaultBoolean } from "logicUtils";

type NumberInputProps = {
  value: number;
  onChange: (_: number) => void;
  disabled?: boolean;
};

export default function NumberInput(props: NumberInputProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const [displayValue, setDisplayValue] = useState<number | ''>(props.value);
  useEffect(() => {
    if (props.value !== 0 || displayValue !== '') {
      setDisplayValue(props.value);
    }
  }, [props.value]);
  const className = `${styles['input']} ${disabled ? styles['disabled'] : ''}`;
  return (
    <input
      type="number"
      step="1"
      value={displayValue}
      className={className}
      disabled={disabled}
      onChange={event => {
        if (event.target.value === '') {
          props.onChange(0);
          setDisplayValue('');
        } else {
          const value = parseInt(event.target.value, 10);
          props.onChange(value);
        }
      }}
    />
  );
}
