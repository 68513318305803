import React from 'react';
import TagChiclet from "./TagSelector/TagChiclet";
import styles from "./TagListDisplay.module.scss";
import { g } from "language";
import { useUser } from "user";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";

type TagListDisplayProps = {
  tagIds: string[];
  indicateNoTags: boolean;
  additionalClasses?: string;
}

export function TagListDisplay(props: TagListDisplayProps) {
  const userContext = useUser();
  const classes = `${styles['tag-list-display']} ${props.additionalClasses || ""}`;
  if (userContext.user && (props.tagIds.length > 0 || !props.indicateNoTags)) {
    return (
      <div className={classes}>
        {props.tagIds.map((tagId, index) => {
          const tag = userContext.user?.getTag(tagId);
          return tag ? (
            <TagChiclet
              tag={tag}
              external={true}
              onClick={() => {}}
              index={index}
              key={tagId}
            />
          ) : null;
        })}
      </div>
    );
  } else {
    return <div><AbsenceIndicator glossaryKey={'noTags'}/></div>;
  }
}
