import React, { useState, ReactNode, useRef } from 'react';
import styles from "./SignUpPage.module.scss";
import { FaCheck } from 'react-icons/fa';
import { HiOutlineX } from 'react-icons/hi';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { signUp, signIn, getToken } from "network";
import { SignUpFailure, SignUpSuccess } from "../responses";
import { useNavigate, Link } from 'react-router-dom';
import { useUserOptional, UserContextState } from '../../user';
import { g, gt } from "../../language";
import useFocus from "../../focus";

const PASSWORD_MINIMUM_LENGTH = 8;

type SignVersion = 'sign-in' | 'sign-up';

export default function SignUpPage() {
  const [signVersion, setSignVersion] = useState<SignVersion>('sign-in');
  const isSignIn = signVersion === 'sign-in';
  const buttonText = g(isSignIn ? 'signUpButtonText' : 'signInButtonText');
  const headerText = g(isSignIn ? 'signInHeaderText' : 'signUpHeaderText')
  const other = isSignIn ? 'sign-up' : 'sign-in';
  const buttonClass = styles[isSignIn ? 'sign-up-button' : 'sign-in-button'];
  const userContext = useUserOptional();
  const history = useNavigate();
  if (userContext.user) {
    history('/user-home-page');
  }
  return (
    <div className={styles['page-wrapper']}>
      <div className={styles['headers']}>
        <span className={buttonClass} onClick={() => setSignVersion(other)}>{buttonText}</span>
      </div>
      <h3>{headerText}</h3>
      {signVersion === 'sign-up' ? <SignUpForm/> : <SignInForm/>}
    </div>
  )
}

function SignUpForm() {
  const [emailText, setEmailText] = useState('');
  const [loginNameText, setLoginNameText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [emailRejectionUnique, setEmailRejectionUnique] = useState(false);
  const [loginRejectionUnique, setLoginRejectionUnique] = useState(false);
  const duplicateEmailText = g('duplicateEmailWarning');
  const duplicateLoginText = g('duplicateLoginNameWarning');
  const invalidEmailText = g('invalidEmailFormatWarning');
  const submissionPossible = passwordValid(passwordText) && emailValid(emailText);
  const inputClass = styles['sign-input'];
  const rejectedClass = styles['input-rejected'];
  const emailClass = `${inputClass} ${emailRejectionUnique ? rejectedClass : ""}`;
  const loginClass = `${inputClass} ${loginRejectionUnique ? rejectedClass : ""}`;
  const history = useNavigate();
  const userContext = useUserOptional();
  const ref = useRef(null);

  useFocus(ref);
  const submit = () => {
    signUp(emailText, loginNameText, passwordText)
    .then(result => {
      if (result.tag === "SignUpFailure") {
        const errors = result as SignUpFailure;
        if (!errors.loginUnique) {
          setLoginRejectionUnique(true);
        }
        if (!errors.emailUnique) {
          setEmailRejectionUnique(true);
        }
      } else {
        const success = result as SignUpSuccess;
        userContext.setUser(new UserContextState({
          user: {
            identifiers: {
              userId: success.userId,
              email: emailText,
              loginName: loginNameText.length > 0 ? loginNameText : null,
              displayName: null
            },
            applicationData: {
              tags: { tags: [] }
            },
            account: {
              createdDate: new Date().toString()
            }
          }
        }));
        history("/user-home-page");
      }
    })
  }

  return (
    <div>
      <div className={styles['sign-form']}>
        <label>{g('emailLabel')}</label>
        <input
          ref={ref}
          className={emailClass}
          type="text"
          value={emailText}
          onChange={event => {
            setEmailText(event.target.value);
            setEmailRejectionUnique(false);
          }}
        />
        {emailRejectionUnique ?
          <span className={styles['duplicate-input']}>{duplicateEmailText}</span> : null}
        {emailValid(emailText) || emailText === "" ? null : <span className={styles['invalid-email']}>{invalidEmailText}</span>}
        <label>{g('loginNameLabel')}</label>
        <input
          className={loginClass}
          type="text"
          value={loginNameText}
          onChange={event => {
            setLoginNameText(event.target.value);
            setLoginRejectionUnique(false);
          }}
        />
        {loginRejectionUnique ?
          <span className={styles['duplicate-input']}>{duplicateLoginText}</span> : null}
        <PasswordInput text={passwordText} setText={setPasswordText} onEnter={submit}/>
        {passwordText.length > 0 && <PasswordGuide text={passwordText}/>}
      </div>
      <SignSubmissionButton
        submissionPossible={submissionPossible}
        onSubmit={submit}
      />
    </div>
  )
}

function SignInForm() {
  const [identifierText, setIdentifierText] = useState('q1@q.com');
  const [passwordText, setPasswordText] = useState('quizzerQ1');
  const [signInFailed, setSignInFailed] = useState(false);
  const submissionPossible = identifierText.length > 0 && passwordText.length > 0;
  const user = useUserOptional();
  const navigate = useNavigate();
  const ref = useRef(null);
  useFocus(ref);
  const submit = async () => {
    const userResult = await signIn(identifierText, passwordText);
    if (userResult === null) {
      setSignInFailed(true);
    } else {
      console.log("token in sign in form", getToken());
      user.setUser(new UserContextState({ user: userResult }));
      navigate("/user-home-page");
    }
  };
  return (
    <div>
      <div className={styles['sign-form']}>
        <label>{g('loginIdentifierLabel')}</label>
        <input
          ref={ref}
          className={styles['sign-input']}
          type="text"
          value={identifierText}
          onChange={event => {
            setSignInFailed(false);
            setIdentifierText(event.target.value);
          }}
        />
        <PasswordInput text={passwordText} onEnter={submit} setText={text => {
          setSignInFailed(false);
          setPasswordText(text);
        }}/>
        {signInFailed ? (
          <span className={styles['sign-in-failed']}>{g('invalidSignInWarning')}</span>
        ) : null}
      </div>
      <SignSubmissionButton submissionPossible={submissionPossible} onSubmit={submit}/>
    </div>
  )
}

type SignSubmissionButtonProps = {
  submissionPossible: boolean;
  onSubmit: () => void;
};

function SignSubmissionButton(props: SignSubmissionButtonProps) {
  return (
    <div className={styles['submit-wrapper']}>
      <button
        className={`${styles['submit-button']} ${props.submissionPossible ? "" : styles['disabled-submit-button']}`}
        disabled={!props.submissionPossible}
        onClick={() => {
          if (props.submissionPossible) {
            props.onSubmit();
          }
        }}
      >{g('signSubmission')}</button>
    </div>
  );
}

type PasswordInputProps = {
  text: string;
  setText: (text: string) => void;
  onEnter: () => void;
}

function PasswordInput(props: PasswordInputProps) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <label>{g('passwordLabel')}</label>
      <span className={styles['password-wrapper']}>
        <input
          ref={passwordRef}
          className={`${styles['sign-input']} ${styles['password-input']}`}
          type={passwordVisible ? "text" : "password"}
          value={props.text}
          onChange={event => props.setText(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              props.onEnter();
            }
          }}
        />
        <PasswordVisibleIndicator visible={passwordVisible} setVisible={visible => {
          passwordRef.current!.focus();
          setPasswordVisible(visible);
        }}/>
      </span>
    </>
  );
}

type PasswordValidity = {
  length: number;
  lowercase: boolean;
  uppercase: boolean;
  numeric: boolean;
}

function validPassword(text: string): PasswordValidity {
  return {
    length: text.length,
    lowercase: !!text.match(/[a-z]/),
    uppercase: !!text.match(/[A-Z]/),
    numeric: !!text.match(/[0-9]/)
  }
}

function passwordValid(text: string): boolean {
  const validity = validPassword(text);
  return validity.length >= PASSWORD_MINIMUM_LENGTH && validity.lowercase && validity.uppercase && validity.numeric;
}

function validityIcon(valid: boolean): ReactNode {
  if (valid) {
    return <FaCheck/>;
  } else {
    return <HiOutlineX/>;
  }
}

function validityClass(valid: boolean): ReactNode {
  if (valid) {
    return styles['valid-password-attribute']
  } else {
    return styles['invalid-password-attribute'];
  }
}

type PasswordGuideProps = {
  text: string;
}

function PasswordGuide(props: PasswordGuideProps) {
  const status = validPassword(props.text);
  const attributeClass = styles['password-attribute'];
  const passwordLength = status.length >= PASSWORD_MINIMUM_LENGTH;
  const lengthMessage = gt('passwordLengthRequirement', [`${PASSWORD_MINIMUM_LENGTH}`]);
  return (
    <div className={styles['password-guide']}>
      <div className={`${attributeClass} ${validityClass(passwordLength)}`}>
        <ValidityIcon valid={passwordLength}/>
        <div>{lengthMessage}</div>
      </div>
      <div className={`${attributeClass} ${validityClass(status.lowercase)}`}>
        <ValidityIcon valid={status.lowercase}/>
        <div>{g('passwordLowerCaseRequirement')}</div>
      </div>
      <div className={`${attributeClass} ${validityClass(status.uppercase)}`}>
        <ValidityIcon valid={status.uppercase}/>
        <div>{g('passwordUpperCaseRequirement')}</div>
      </div>
      <div className={`${attributeClass} ${validityClass(status.numeric)}`}>
        <ValidityIcon valid={status.numeric}/>
        <div>{g('passwordNumberRequirement')}</div>
      </div>
    </div>
  )
}

type ValidityIconProps = {
  valid: boolean;
};

function ValidityIcon(props: ValidityIconProps) {
  return (
    <div className={styles['validity-icon']}>{validityIcon(props.valid)}</div>
  )
}

type PasswordVisibleIndicatorProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function PasswordVisibleIndicator(props: PasswordVisibleIndicatorProps) {
  return (
    <span className={styles['password-visible-indicator']} onClick={() => props.setVisible(!props.visible)}>
      {props.visible ? <BsEyeFill/> : <BsEyeSlashFill/>}
    </span>
  )
}

function emailValid(text: string): boolean {
  return !!text.match(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/)
}
