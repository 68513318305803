import { QuizSettings } from "quiz";
import { CompletedQuiz, NumericScore } from "./quizResults";
import { Tag } from "tags";
import { QuestionSettingsModel, isTextualSettings } from "QuestionSettings";
import { g, gt } from "language";

export function extractSettings(quiz: CompletedQuiz): QuizSettings {
  return {
    questionNumber: quiz.questionNumber,
    allowRepeats: quiz.allowRepeats,
    tagIds: quiz.tagIds,
    tagPattern: quiz.tagPattern,
    excludedTagIds: quiz.excludedTagIds
  };
}

export function sortQuizFirst(tags: Tag[], quizTags: Set<Tag>): Tag[] {
  return tags.sort((a, b) => {
    const comparison = a.body.text.localeCompare(b.body.text);
    const aMember = quizTags.has(a);
    const bMember = quizTags.has(b);
    if (aMember === bMember) {
      return comparison;
    } else if (aMember) {
      return -1;
    } else {
      return 1;
    }
  })
}

export function ignoredCharactersIndicator(settings: QuestionSettingsModel): string | null {
  const whitespace = g('whitespace');
  const punctuation = g('punctuation');
  const both = gt('and', [whitespace, punctuation]);
  let characterText = "";
  if (isTextualSettings(settings)) {
    if (settings.ignoreWhitespace && settings.ignorePunctuation) {
      characterText = both;
    } else if (settings.ignoreWhitespace) {
      characterText = whitespace;
    } else if (settings.ignorePunctuation) {
      characterText = punctuation;
    }
  }
  const fullText = gt('ignoredCharactersText', [characterText]);
  if (characterText === "") {
    return null;
  } else {
    return fullText;
  }
}

export function fullCredit(score: NumericScore): boolean {
  return score.points >= score.pointsPossible;
}

export function matchPercentage(distance: number, length: number): number {
  return (length - distance) / length;
}
