export const answerLabelOptions = [
  'noLabel',
  'numberLabel',
  'uppercaseLabel',
  'lowercaseLabel',
  'uppercaseGreekLabel',
  'lowercaseGreekLabel',
  'uppercaseRomanLabel',
  'lowercaseRomanLabel',
  'runeLabel'
] as const;

export type AnswerLabel = typeof answerLabelOptions[number];

export const ANSWER_LABELS: readonly AnswerLabel[] = answerLabelOptions;

const runeOrder = [
  0x16A0, 0x16A9, 0x16B8, 0x16C9, 0x16D7, 0x16D8, 0x16BC, 0x16DD, 0x16E5, 0x16B2, 0x16E3, 0x16EA,
  0x16C8, 0x16BB, 0x16B9, 0x16CA, 0x16E4, 0x16E5, 0x16E3, 0x16B5, 0x16A9, 0x16A5, 0x16AF, 0x16C9
];

function romanLevel(ones: string, fives: string, tens: string): string[] {
  return [
    '',
    `${ones}`,
    `${ones}${ones}`,
    `${ones}${ones}${ones}`,
    `${ones}${fives}`,
    `${fives}`,
    `${fives}${ones}`,
    `${fives}${ones}${ones}`,
    `${fives}${ones}${ones}${ones}`,
    `${ones}${tens}`
  ];
}

type RomanNumerals = {
  ones: string[],
  tens: string[],
  hundreds: string[]
}

function romanNumerals(
  ones: string,
  fives: string,
  tens: string,
  fifties: string,
  hundreds: string,
  fiveHundreds: string,
  thousands: string
): RomanNumerals {
  return {
    ones: romanLevel(ones, fives, tens),
    tens: romanLevel(tens, fifties, hundreds),
    hundreds: romanLevel(hundreds, fiveHundreds, thousands)
  };
}

const lowercaseRomanNumerals = romanNumerals('i', 'v', 'x', 'l', 'c', 'd', 'm');
const uppercaseRomanNumerals = romanNumerals('I', 'V', 'X', 'L', 'C', 'D', 'M');

export function lowercaseCharacter(n: number): string {
  return String.fromCharCode(n + "a".charCodeAt(0));
}

export function uppercaseCharacter(n: number): string {
  return String.fromCharCode(n + "A".charCodeAt(0));
}

export function uppercaseGreek(n: number): string {
  return String.fromCharCode(n + 0x391);
}

export function lowercaseGreek(n: number): string {
  return String.fromCharCode(n + 0x03B1);
}

export function lowercaseRoman(n: number): string {
  return roman(n, lowercaseRomanNumerals);
}

export function uppercaseRoman(n: number): string {
  return roman(n, uppercaseRomanNumerals);
}

export function roman(n_: number, numerals: RomanNumerals): string {
  const n = n_ + 1;
  const ones = n % 10;
  const tens = Math.floor(n / 10) % 10;
  const hundreds = Math.floor(n / 100) % 10;
  return `${numerals.hundreds[hundreds]}${numerals.tens[tens]}${numerals.ones[ones]}`;
}

export function rune(n: number): string {
  return String.fromCharCode(runeOrder[n]);
}
