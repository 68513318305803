import * as E from "../TextEditor";
import { LabeledFileHandle, labeledFileHandleEquals, labeledFileHandleSufficientDifference } from "../pictures";
import { zip } from "utils";
import { listUndoSufficientDifference } from "undo";

export type SelectableAnswer = {
  content: E.Content;
  images: LabeledFileHandle[];
};

export function constructSelectableAnswer(): SelectableAnswer {
  return {
    content: E.constructContent(),
    images: []
  };
}

export function contentToSelectableAnswer(content: E.Content): SelectableAnswer {
  return {
    content: content,
    images: []
  }
};

export function selectableAnswerEquals(a: SelectableAnswer, b: SelectableAnswer): boolean {
  const pictureIds = new Set(a.images.map(image => image.id));
  let idsEqual = true;
  b.images.forEach(image => idsEqual = idsEqual && pictureIds.has(image.id));
  return idsEqual && E.stringRepresentation(a.content) === E.stringRepresentation(b.content);
}

export function stringRepresentation(answer: SelectableAnswer): string {
  const idString = answer.images.map(image => image.id).join("_");
  return `${E.stringRepresentation(answer.content)}${idString}`;
}

export function nonEmpty(answer: SelectableAnswer): boolean {
  return E.nonEmpty(answer.content) || answer.images.length > 0;
}

export function selectableAnswerSufficientDifference(last: SelectableAnswer, current: SelectableAnswer): boolean {
  const contentDifference = E.undoSufficientDifference(last.content, current.content);
  const imageDifference = listUndoSufficientDifference(last.images, current.images, (l, c) => {
    return labeledFileHandleSufficientDifference(l, c);
  });
  return contentDifference && imageDifference;
}

export function selectableAnswersSufficientDifference(last: SelectableAnswer[], current: SelectableAnswer[]): boolean {
  return listUndoSufficientDifference(last, current, (a, b) => selectableAnswerSufficientDifference(a, b));
}