type DifferenceEvaluator<T extends object> = (_: T, __: Partial<T>) => boolean;

type StateHook<T> = (_: T) => void;

type ChangeHook<T extends object> = (_: UndoHistory<T>) => void;

type InitialConstructorArguments<T extends object> = {
  baseState: T;
  sufficientDifference: DifferenceEvaluator<T>;
  stateHook: StateHook<T>;
  changeCallback: ChangeHook<T>;
};

type FromSavedConstructorArguments<T extends object> = {
  previous: UndoHistory<T>;
  savedHistory: UndoHistorySummary<T>;
};

type UndoHistoryConstructorArguments<T extends object> = InitialConstructorArguments<T> | FromSavedConstructorArguments<T>;

function isInitial<T extends object>(a: UndoHistoryConstructorArguments<T>): a is InitialConstructorArguments<T> {
  return (a as InitialConstructorArguments<T>).baseState !== undefined;
}

export class UndoHistory<T extends object> {
  baseState: T;
  changes: Partial<T>[];
  changeIndex: number;
  cache: T;
  sufficientDifference: DifferenceEvaluator<T>;
  stateHook: StateHook<T>;
  persistenceKey: string | undefined;
  changeCallback: ChangeHook<T>;

  constructor(a: UndoHistoryConstructorArguments<T>) {
    if (isInitial(a)) {
      this.baseState = a.baseState;
      this.cache = a.baseState;
      this.changes = [];
      this.changeIndex = -1;
      this.stateHook = a.stateHook;
      this.sufficientDifference = a.sufficientDifference;
      this.changeCallback = a.changeCallback;
    } else {
      this.baseState = a.savedHistory.baseState;
      this.cache = a.savedHistory.cache;
      this.changes = a.savedHistory.changes;
      this.changeIndex = a.savedHistory.changeIndex;
      this.stateHook = a.previous.stateHook;
      this.sufficientDifference = a.previous.sufficientDifference;
      this.changeCallback = a.previous.changeCallback;
    }
  }

  undoPossible(): boolean {
    return this.changeIndex >= 0;
  }

  redoPossible(): boolean {
    return this.interiorChangeReference();
  }

  interiorChangeReference(): boolean {
    return this.changeIndex < this.changes.length - 1;
  }

  registerChange(latest: Partial<T>) {
    if (this.sufficientDifference(this.cache, latest)) {
      if (this.interiorChangeReference()) {
        this.changes = this.changes.slice(0, this.changeIndex);
      }
      this.changes.push(latest);
      this.cache = {
        ...this.cache,
        ...latest
      };
      this.changeIndex = this.changes.length - 1;
    }
  }

  current(): T {
    return this.cache;
  }

  derive(): T {
    let accumulator = this.baseState;
    for (let i = 0; i < this.changeIndex; i++) {
      const change = this.changes[i];
      accumulator = {
        ...accumulator,
        ...change
      };
    }
    return accumulator;
  }

  undo() {
    if (this.undoPossible()) {
      this.changeIndex--;
      this.cache = this.derive();
      this.updateCurrent();
    }
  }

  redo() {
    if (this.redoPossible()) {
      this.changeIndex++;
      this.updateCurrent();
    }
  }

  updateCurrent() {
    this.cache = this.derive();
    this.stateHook(this.cache);
  }

  summary(): UndoHistorySummary<T> {
    return {
      baseState: this.baseState,
      changes: this.changes,
      cache: this.cache,
      changeIndex: this.changeIndex
    };
  }
}

export type UndoHistorySummary<T> = {
  baseState: T;
  changes: Partial<T>[];
  cache: T;
  changeIndex: number;
};
