import { useState, useEffect } from "react";
import { g } from "language";
import styles from "./PercentInput.module.scss";
import NumberInput from "commonComponents/NumberInput";

type PercentInputProps = {
  percent: number;
  onChange: (_: number) => void;
};

export default function PercentInput(props: PercentInputProps) {
  return (
    <span className={styles['error-margin-input']}>
      <NumberInput value={props.percent} onChange={props.onChange}/>
      <span className={styles['percent-indicator']}>{g('percent')}</span>
    </span>
  )
}
