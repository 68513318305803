import { ReorderingOperation, isSwapOperation } from "./OrderedList";

export function arrowAction<T>(index: number, operation: ReorderingOperation, items: T[], stateChange: (_: T[]) => void) {
  const upPossible = index > 0;
  const downPossible = index < items.length - 1;
  const switcher = (i: number, j: number) => {
    const temp = items[i];
    items[i] = items[j];
    items[j] = temp;
    stateChange(items);
  }
  if (operation === 'moveUp' && upPossible) {
    switcher(index - 1, index)
  }
  if (operation === 'moveDown' && downPossible) {
    switcher(index, index + 1);
  }
  if (isSwapOperation(operation)) {
    const destination = operation.destination;
    const effectiveDestination = index < destination ? destination - 1 : destination;
    const item = items[index];
    items.splice(index, 1);
    items.splice(effectiveDestination, 0, item);
    stateChange([...items]);
  }
};
