import { useContext, createContext } from 'react';
import { TagEnvelope, Tag } from "tags";

export type User = {
  identifiers: UserIdentifiers;
  applicationData: UserApplicationData;
  account: UserAccountData;
};

export type UserIdentifiers = {
  userId: string;
  email: string;
  loginName: string | null;
  displayName: string | null;
};

export type UserApplicationData = {
  tags: TagEnvelope;
};

export type UserAccountData = {
  createdDate: string;
};

type UserSetter = (user: UserContextState | null) => void;

export type UserContextValue = {
  user: UserContextState | null;
  setUser: UserSetter;
}

export type VerifiedUserContextValue = {
  user: UserContextState;
  setUser: UserSetter;
};

export function verifyOrNullifyUser(userContextValue: UserContextValue): VerifiedUserContextValue | null {
  if (userContextValue.user) {
    return { user: userContextValue.user, setUser: userContextValue.setUser }
  } else {
    return null;
  }
}

type UserContextStateConstructorOptions = {
  user?: User;
  userContextState?: UserContextState;
};

export class UserContextState {
  private identifiers: UserIdentifiers;
  private tagCache: Map<string, Tag>;
  private tagNameCache: Map<string, Tag>;
  private createdDate: Date;

  constructor({ user, userContextState }: UserContextStateConstructorOptions) {
    if (user) {
      this.identifiers = user.identifiers;
      this.tagCache = buildTagCache(user);
      this.tagNameCache = buildTagNameSet(user);
      this.createdDate = new Date(user.account.createdDate);
    } else if (userContextState) {
      this.identifiers = {...userContextState.identifiers};
      this.tagCache = new Map(userContextState.tagCache);
      this.tagNameCache = new Map(userContextState.tagNameCache);
      this.createdDate = userContextState.createdDate;
    } else {
      throw Error('no user inputs specified');
    }
  }

  userId(): string {
    return this.identifiers.userId;
  }

  userDisplayName(): string | null {
    const displayName = this.identifiers.displayName;
    const loginName = this.identifiers.loginName;
    if (displayName) {
      return displayName;
    } else if (loginName) {
      return loginName;
    } else {
      return null;
    }
  }

  allTags(): Tag[] {
    return Array.from(this.tagCache.values() || []);
  }

  allTagIds(): string[] {
    return this.allTags().map(tag => tag.tagId);
  }

  getTag(tagId: string): Tag | null {
    return this.tagCache.get(tagId) || null;
  }

  getTagsById(ids: string[]): Tag[] {
    const tags = ids.map(id => this.getTag(id));
    const filtered: Tag[] = [];
    tags.forEach(tag => {
      if (tag !== null) {
        filtered.push(tag);
      }
    });
    return filtered;
  }

  tagByName(name: string): Tag | null {
    return this.tagNameCache.get(name) || null;
  }

  addTag(tag: Tag): UserContextState {
    this.tagCache.set(tag.tagId, tag);
    this.tagNameCache.set(tag.body.text, tag);
    return new UserContextState({ userContextState: this });
  }

  hasTagName(tagName: string): boolean {
    return this.tagNameCache.has(tagName) || false;
  }

  hasTag(tagId: string): boolean {
    return this.tagCache.has(tagId);
  }

  accountCreationDate(): Date {
    return this.createdDate;
  }
};

export const UserContext = createContext<UserContextValue>({
  user: null,
  setUser: (_: UserContextState | null) => {}
});

function buildTagCache(user: User): Map<string, Tag> {
  return new Map(user.applicationData.tags.tags.map(tag => [tag.tagId, tag]));
}

function buildTagNameSet(user: User): Map<string, Tag> {
  return new Map(user.applicationData.tags.tags.map(tag => [tag.body.text, tag]));
}

export class UserAbsentError extends Error {
  constructor(message: string = "user not logged in") {
    super(message);
  }
}

export function useUserOptional(): UserContextValue {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("Can't use user context");
  } else {
    return context;
  }
}

export function useUser(): VerifiedUserContextValue {
  const context = useUserOptional();
  if (context.user === null) {
    throw new UserAbsentError();
  } else {
    return { user: context.user, setUser: context.setUser };
  }
}

export function updateUser(userContext: UserContextValue) {
  if (userContext.user !== null) {
    userContext.setUser(new UserContextState({ userContextState: userContext.user }));
  } else {
    userContext.setUser(null);
  }
};
