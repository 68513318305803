import React from 'react';
import styles from "./TagPatternSelector.module.scss";
import { g } from "language";
import Info from "Info";
import PatternInfo from "./PatternInfo";
import LogicalPatternSelector, { LogicalPattern} from "commonComponents/LogicalPatternSelector";

export type TagPatternSelectorProps = {
  pattern: LogicalPattern;
  setPattern: (_: LogicalPattern) => void;
}

export function TagPatternSelector(props: TagPatternSelectorProps) {
  return (
    <div className={styles['pattern-row']}>
      <LogicalPatternSelector
        pattern={props.pattern}
        setPattern={props.setPattern}
        leftKey={'patternTextBefore'}
        rightKey={'patternTextAfter'}
      />
      <Info title={g('tagPatterns')}><PatternInfo/></Info>
    </div>
  )
}
